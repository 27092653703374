import { v4 as uuidv4 } from 'uuid';

export default class DropDown {
    constructor(obj) {
        if(!obj) {
            obj = {}
        }

        this.id = obj.id || uuidv4(); 

        this.type = 'dropDown';
        
        this.name = obj.name || null;

        this.position = obj.position || null;

        this.choices = obj.choices || [ 'Option 1', 'Option 2', 'Option 3' ];
        
        this.required = obj.required || false;

        this.labelText = obj.labelText || 'Drop Down';
    }
}
import { createSlice } from '@reduxjs/toolkit';

export const CoreSlice = createSlice({
	name: 'core',
	initialState: { 
		wheelhouseRoot: null,
	}, 
	reducers: {
		setWheelhouseRoot(state, action) {
			state.wheelhouseRoot = action.payload;
		},
	}
});

export const coreActions = {
	setWheelhouseRoot: CoreSlice.actions.setWheelhouseRoot,
}

export default CoreSlice.reducer;
import { 
    FormControl, 
    InputLabel,
    MenuItem, 
    Select 
} from '@material-ui/core';


export default function UserOptionSelector(props) {
    
    const { 
        choices, 
        error, 
        label, 
        name, 
        value, 
        onChange, 
        onBlur 
    } = props;

    if(!!choices && choices.length) {
        return (
            <FormControl error={error} fullWidth>

                <InputLabel required shrink >
                    { label }
                </InputLabel>
    
                <Select
                    name={name}
                    value={value || ''}
                    onChange={onChange}
                    displayEmpty
                    onBlur={onBlur}>
                        <MenuItem disabled value="">-Select-</MenuItem>
                        { choices[0].default_assignment === 0 && 
                            <MenuItem value="0">All</MenuItem>
                        }

                    { choices.map((choice, index) => (
                        <MenuItem key={choice + index} value={choice.id}>{choice.user_level || choice.type || choice.usr_view}</MenuItem>
                    ))}
                </Select>
    
            </FormControl>
        )
    } else {
        return null
    }

}
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import WheelhouseLogo from '../Assets/Logos/logo-dark.svg';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import * as yup from 'yup';
import { currentUserActionsAsync } from '../CurrentUser/CurrentUserState/CurrentUserActionsAsync';
import { currentUserSelectors } from '../CurrentUser/CurrentUserState/CurrentUserSelector';
import { currentUserActions } from '../CurrentUser/CurrentUserState/CurrentUserSlice';
import LoadingSpinnerGreyBackground from './Spinners/LoadingSpinnerGreyBackground';
import { Link } from '@reach/router';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import TermsAndConditions from './TermsAndConditions';
import ContactSupportText from './ContactSupportText';

const useStyles = makeStyles(theme => ({
  alert: theme.alert,
  error: theme.error,
  forgotPassword: theme.forgotPassword,
  loginButton: theme.noNavButton,
  loginInput: theme.noNavInput,
  loginlabel: theme.noNavLabel,
  loginRow: theme.noNavRow,
  loginButtonRow: theme.loginButtonRow,
  loginTile: theme.noNavTile,
  loginTileLogoContainer: theme.loginTileLogoContainer,
  loginTileLogo: theme.loginTileLogo,
  pageBackground: theme.noNavBackground,
  termsContainer: {
    color: '#FFF',
    fontWeight: 'bold',
    marginTop: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'cener',
    flexDirection: 'column',
    rowGap: 2,
    '& span, a': {
      textAlign: 'center',
      textDecoration: 'none',
      color: '#fff',
    }
  },
  tosLink: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline !important'
   },
  },
  ssoLink: {
    alignSelf: 'center',
    color: '#5e7999',
    textDecoration: 'none',
  },
}));

export default function LoginPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loginError = useSelector(currentUserSelectors.loginErrorSelector);
  const wheelhouseRoot = useSelector(state => state.core.wheelhouseRoot);

  const [loading, setLoading] = useState(null);

  const newCredentials = {
    username: '',
    password: '',
    formError: ''
  }

  const DefaultError = () => {
    return (
      <span>The username and password combination could not be found.  Please try again.</span>
    )
  }

  const handleSignIn = (values) => {
    dispatch(currentUserActionsAsync.signInUser(values))
    .unwrap()
    .then(() => {
      dispatch(currentUserActionsAsync.writeWheelhouseToken())
        .then(response => {
          if(response?.payload) {
            const { token } = response.payload;
            window.location.href = `${wheelhouseRoot}index.php?token=${token}`;
          }
      })
    })
    .catch(err => {
      setLoading(false);
      dispatch(currentUserActions.setLoginError('default'))
    }) ;
  }

  const handleErrorClose = () => {
    dispatch(currentUserActions.clearLoginError())
  }

  const credentialsSchema = yup.object().shape({
    username: yup.string()
      .required('Username Required'),
    password: yup.string()
      .required('Password Required'),
  });

  return (

    <div className={classes.pageBackground}>
      { !!loginError && 
        <Alert 
        className={classes.alert}
        onClose={handleErrorClose} 
        severity="error">
          { loginError === 'default' ? <DefaultError /> : loginError }
        </Alert>
      }


      <Formik
        initialValues={newCredentials}
        validationSchema={credentialsSchema}
        onSubmit={(values) => {
          handleErrorClose();
          setLoading(true);
          handleSignIn(values);
        }}
    >{({
      isValid, 
      dirty,
      handleSubmit,
      isSubmitting
    }) => {
      return (
        <Form noValidate autoComplete="off" onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}>
          <div className={classes.loginTile}>
            <div className={classes.loginTileLogoContainer}>
              <img src={WheelhouseLogo} alt="Vessel Vanguard Pro Logo" className={classes.loginTileLogo} />
            </div>

            <div className={classes.loginlabel}>
              <span>Username</span>
            </div>
            <div className={classes.loginRow}>
              <Field id="username" name="username" className={classes.loginInput} />
              <ErrorMessage className={classes.error} component="p" name="username" />
            </div>

            <div className={classes.loginlabel}>
              <span>Password</span>
              <Link className={classes.forgotPassword} tabIndex="-1" to="/forgot-password">
                <span className={classes.forgotPassword}>Forgot Password?</span>
              </Link>
            </div>
            <div className={classes.loginRow}>
              <Field id="password" name="password" type="password" className={classes.loginInput} />
              <ErrorMessage className={classes.error} component="p" name="password" />

            </div>

            <div className={classes.loginButtonRow}>
              <button disabled={!isValid || !dirty} type="submit" className={classes.loginButton}>Login</button>
              <Link to="/sso_login" className={classes.ssoLink}>Login with SSO</Link>
            </div>

            <ContactSupportText></ContactSupportText>
          </div>
        </Form>)}}
      </Formik>

      <TermsAndConditions></TermsAndConditions>

      { loading && 
         <LoadingSpinnerGreyBackground open={true} />
      }
    </div>
  ) 
}

export default class AddressUtils {

  static availableStatesUS = [
        'AL', 
        'AK', 
        'AR', 
        'AZ', 
        'CA', 
        'CO', 
        'CT', 
        'DC', 
        'DE', 
        'FL', 
        'GA', 
        'HI', 
        'IA', 
        'ID', 
        'IL', 
        'IN', 
        'KS', 
        'KY', 
        'LA', 
        'MA', 
        'MD', 
        'ME', 
        'MI', 
        'MN', 
        'MO', 
        'MS', 
        'MT', 
        'NC', 
        'ND', 
        'NE', 
        'NH', 
        'NJ', 
        'NM', 
        'NV', 
        'NY', 
        'OH', 
        'OK', 
        'OR', 
        'PA', 
        'RI', 
        'SC', 
        'SD', 
        'TN', 
        'TX', 
        'UT', 
        'VA', 
        'VI', 
        'VT', 
        'WA', 
        'WI', 
        'WV', 
        'WY',
      ];

      static availableStatesCanada = [
      "AB",
      "BC",
      "MB",
      "NB",
      "NL",
      "NT",
      "NS",
      "NU",
      "ON",
      "PE",
      "QC",
      "SK",
      "YT",
      ];

  static availableCountries = [
    
    { country: "Afghanistan" },
    { country: "Albania"  },
    { country: "Algeria"  },
    { country: "American Samoa"  },
    { country: "Andorra"  },
    { country: "Angola"  },
    { country: "Anguilla"  },
    { country: "Antarctica"  },
    { country: "Antigua and Barbuda"  },
    { country: "Argentina"  },
    { country: "Armenia"  },
    { country: "Aruba"  },
    { country: "Australia"  },
    { country: "Austria"  },
    { country: "Azerbaijan" },
    { country: "Bahamas"  },
    { country: "Bahrain"  },
    { country: "Bangladesh"  },
    { country: "Barbados"  },
    { country: "Belarus"  },
    { country: "Belgium"  },
    { country: "Belize"  },
    { country: "Benin"  },
    { country: "Bermuda"  },
    { country: "Bhutan"  },
    { country: "Bolivia"  },
    { country: "Bosnia and Herzegowina"  },
    { country: "Botswana"  },
    { country: "Bouvet Island"  },
    { country: "Brazil"  },
    { country: "British Indian Ocean Terr."  },
    { country: "Brunei Darussalam"  },
    { country: "Bulgaria"  },
    { country: "Burkina Faso"  },
    { country: "Burundi" },
    { country: "Cambodia"  },
    { country: "Cameroon"  },
    { country: "Canada" },
    { country: "Cape Verde" },
    { country: "Cayman Islands"  },
    { country: "Central African Republic"  },
    { country: "Chad"  },
    { country: "Chile"  },
    { country: "China"  },
    { country: "Christmas Island"  },
    { country: "Cocos (Keeling) Islands"  },
    { country: "Colombia"  },
    { country: "Comoros"  },
    { country: "Congo"  },
    { country: "Congo, Dem. Republic of"  },
    { country: "Cook Islands"  },
    { country: "Costa Rica"},
    { country: "Cote d'Ivoire"  },
    { country: "Croatia (Hrvatska)"  },
    { country: "Cuba" },
    { country: "Cyprus"  },
    { country: "Czech Republic"  },
    { country: "Denmark"  },
    { country: "Djibouti"  },
    { country: "Dominica"  },
    { country: "Dominican Republic" },
    { country: "East Timor"  },
    { country: "Ecuador" },
    { country: "Egypt"  },
    { country: "El Salvador"  },
    { country: "Equatorial Guinea"  },
    { country: "Eritrea"  },
    { country: "Estonia"  },
    { country: "Ethiopia"  },
    { country: "Falkland Is. (Malvinas)" },
    { country: "Faroe Islands" },
    { country: "Fiji" },
    { country: "Finland"  },
    { country: "France"  },
    { country: "French Guiana" },
    { country: "French Polynesia"  },
    { country: "French So. Territories"  },
    { country: "Gabon"  },
    { country: "Gambia" },
    { country: "Georgia"  },
    { country: "Germany"  },
    { country: "Ghana"  },
    { country: "Gibraltar" },
    { country: "Greece"  },
    { country: "Greenland" },
    { country: "Grenada"  },
    { country: "Guadeloupe" },
    { country: "Guam"},
    { country: "Guatemala"  },
    { country: "Guinea"  },
    { country: "Guinea-Bissau" },
    { country: "Guyana"  },
    { country: "Haiti" },
    { country: "Heard &amp; Mc Donald Is"  },
    { country: "Holy See (Vatican City)"  },
    { country: "Honduras"  },
    { country: "Hong Kong" },
    { country: "Hungary"  },
    { country: "Iceland"  },
    { country: "India"  },
    { country: "Indonesia"  },
    { country: "Iran (Islamic Republic)" },
    { country: "Iraq"  },
    { country: "Ireland"  },
    { country: "Israel" },
    { country: "Italy"  },
    { country: "Jamaica"  },
    { country: "Japan"  },
    { country: "Jordan"  },
    { country: "Kazakhstan"  },
    { country: "Kenya"  },
    { country: "Kiribati" },
    { country: "Korea (DPRK)"  },
    { country: "Korea, Republic of"  },
    { country: "Kuwait"  },
    { country: "Kyrgyzstan"  },
    { country: "Lao People's Dem. Rep."  },
    { country: "Latvia"  },
    { country: "Lebanon"  },
    { country: "Lesotho"  },
    { country: "Liberia"  },
    { country: "Libyan Arab Jamahiriya"  },
    { country: "Liechtenstein"  },
    { country: "Lithuania"  },
    { country: "Luxembourg"  },
    { country: "Macau" },
    { country: "Macedonia"  },
    { country: "Madagascar"  },
    { country: "Malawi" },
    { country: "Malaysia" },
    { country: "Maldives"  },
    { country: "Mali"  },
    { country: "Malta"  },
    { country: "Marshall Islands"  },
    { country: "Martinique"  },
    { country: "Mauritania"  },
    { country: "Mauritius" },
    { country: "Mayotte"  },
    { country: "Mexico"  },
    { country: "Micronesia, Fed States"  },
    { country: "Moldova, Republic of"  },
    { country: "Monaco" },
    { country: "Mongolia"  },
    { country: "Montserrat"  },
    { country: "Morocco" },
    { country: "Mozambique"  },
    { country: "Myanmar"  },
    { country: "Namibia"  },
    { country: "Nauru"  },
    { country: "Nepal" },
    { country: "Netherlands" },
    { country: "Netherlands Antilles"  },
    { country: "New Caledonia" },
    { country: "NZ"  },
    { country: "Nicaragua"  },
    { country: "Niger"  },
    { country: "Nigeria"},
    { country: "Niue"  },
    { country: "Norfolk Island"  },
    { country: "Northern Mariana Islands" },
    { country: "Norway" },
    { country: "Oman"  },
    { country: "Pakistan"  },
    { country: "Palau" },
    { country: "Panama"  },
    { country: "Papua New Guinea"  },
    { country: "Paraguay" },
    { country: "Peru"  },
    { country: "Philippines" },
    { country: "Pitcairn"  },
    { country: "Poland"  },
    { country: "Portugal"  },
    { country: "Puerto Rico" },
    { country: "Qatar"  },
    { country: "Reunion" },
    { country: "Romania" },
    { country: "Russian Federation" },
    { country: "Rwanda"  },
    { country: "Saint Kitts and Nevis" },
    { country: "Saint LUCIA" },
    { country: "St. Vincent" },
    { country: "Samoa"  },
    { country: "San Marino" },
    { country: "Sao Tome and Principe" },
    { country: "Saudi Arabia"  },
    { country: "Senegal"  },
    { country: "Seychelles"  },
    { country: "Sierra Leon"  },
    { country: "Singapore"  },
    { country: "Slovakia (Slovak Republic)"  },
    { country: "Slovenia"  },
    { country: "Solomon Islands"  },
    { country: "Somalia" },
    { country: "South Africa"  },
    { country: "So. Georgia/So. Sandwich Is." },
    { country: "Spain" },
    { country: "Sri Lanka"  },
    { country: "St. Helena"  },
    { country: "St. Pierre and Miquelon" },
    { country: "Sudan"  },
    { country: "Suriname" },
    { country: "Svalbard & Jan Mayen Is."  },
    { country: "Swaziland"  },
    { country: "Sweden" },
    { country: "Switzerland" },
    { country: "Syrian Arab Republic" },
    { country: "Taiwan, Republic of China"  },
    { country: "Tajikistan"},
    { country: "Tanzania (United Republic)"  },
    { country: "Thailand"  },
    { country: "Togo" },
    { country: "Tokelau"  },
    { country: "Tonga"  },
    { country: "Trinidad and Tobago" },
    { country: "Tunisia"},
    { country: "Turkey"  },
    { country: "Turkmenistan" },
    { country: "Turks and Caicos Islands"  },
    { country: "Tuvalu"  },
    { country: "Uganda"  },
    { country: "Ukraine"  },
    { country: "United Arab Emirates" },
    { country: "United Kingdom"  },
    { country: "United States"  },
    { country: "U.S. Minor Outlying Is."  },
    { country: "Uruguay" },
    { country: "Uzbekistan" },
    { country: "Vanuatu"  },
    { country: "Venezuela"  },
    { country: "Viet Nam" },
    { country: "Virgin Islands (British)"  },
    { country: "Virgin Islands (U.S.)" },
    { country: "Wallis and Futuna Is" },
    { country: "Western Sahara" },
    { country: "Yemen"  },
    { country: "Yugoslavia"  },
    { country: "Zambia" },
    { country: "Zimbabwe" },
  ]
}




import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
	makeStyles,
  TextField
 } from '@material-ui/core';
import { ErrorMessage, Formik, Form } from 'formik';
import { Fragment, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import CustomFormCategoryPicker from '../Admin/CustomFormBuilder/CustomFormCategories/CustomFormCategoryPicker/CustomFormCategoryPicker';
import NewCategoryDialog from './NewCategoryDialog/NewCategoryDialog';

const useStyles = makeStyles(theme => ({
	actionButton: theme.actionButton
}));


export default function CloneTemplateDialog(props) {
	const classes = useStyles();
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const[newCategoryDialogOpen, setNewCategoryDialogOpen] = useState(false);

  const { 
    availableCategories, 
    createCategoryAction, 
    categoryType,
    open, 
    onClose, 
    originalTemplate, 
    onSubmit 
  } = props;

  const templateSchema = yup.object().shape({
    name: yup.string().required('Required'),
    categoryId: yup.string()
      .required('Required')
      .nullable(),
    categoryName: yup.string()
      .required('Required')
      .nullable(),
  });

  const getCategoryName = id => {
    return availableCategories.find(category => category.id === id).name;
  }

  const handleNewCategorySubmit = category => {
    const params = {
      newCategory: category
    }

    dispatch(createCategoryAction(params)).then(response => {
      const newCategory = response.payload;
      if(!!formikRef.current && !!newCategory) {
        formikRef.current.setFieldValue('categoryId', newCategory.id, false);
        formikRef.current.setFieldValue('categoryName', newCategory.name, false);
    }});
  }

  
  if(!!originalTemplate) {
    return (
      <Fragment>
        <Dialog open={open} onClose={onClose} aria-labelledby="clone-dialog-title">
          <DialogTitle id="clone-dialog-title">Clone Template</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To clone {originalTemplate.name}, enter a name for your new template.
            </DialogContentText>
            <Formik
              innerRef={formikRef}
              initialValues={{ 
                name: "", 
                categoryId: "",
                categoryName: ""
              }}
              validationSchema={templateSchema}
              onSubmit={ async (values) => {
                onSubmit(values);
              }}>{({
                values, 
                errors, 
                touched,
                handleChange, 
                handleBlur, 
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <Form id="clone-dialog-form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <TextField
                      autoFocus
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.name && touched.name)}
                      id="name"
                      label="New Name"
                      fullWidth
                    />

                    <CustomFormCategoryPicker
                      name="categoryId"
                      availableCategories={availableCategories}
                      selectedCategory={values.categoryId}
                      showError={Boolean(errors.categoryId && touched.categoryId)}
                      onChange={e => {
                        handleChange(e);
                        setFieldValue('categoryName', getCategoryName(e.target.value), false);
                      }}
                      onAddClick={() => setNewCategoryDialogOpen(true)}
                      />
                    <ErrorMessage
                      className={classes.error}
                      name="categoryId"
                      component="p" />

                  </Form>
                )
              }}

            </Formik>

          </DialogContent>
          <DialogActions>
            <Button className={classes.actionButton} onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button className={classes.actionButton} type="submit" form="clone-dialog-form" color="primary">
              Clone
            </Button>
          </DialogActions>
        </Dialog>

        <NewCategoryDialog
          categoryType={categoryType}
          open={newCategoryDialogOpen}
          onClose={() => setNewCategoryDialogOpen(false)}
          onSubmit={handleNewCategorySubmit} />

      </Fragment>
    )
  } else {
    return null;
  }
}
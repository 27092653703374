
import { TextField } from "@material-ui/core";
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogTitle, 
  DialogContent, 
	makeStyles,
} from "@material-ui/core";
import { Formik, Form } from 'formik';
import * as yup from 'yup';

const useStyles = makeStyles(theme => ({
	actionButton: theme.actionButton,
}));

export default function UserEsignDialog(props) {
	const classes = useStyles();

  const { open, onClose, onSubmit, user } = props;

  const PINSchema = yup.object().shape({
    PIN: yup.string().required('Required')
    .length(4, 'PIN must be exactly 4 numbers')
    .matches(/^\d+$/, { 
      message: 'PIN must only include numbers',
      excludeEmptyString: true }),
  });

  return (
    <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="manage-PIN-dialog-title"
    aria-describedby="manage-PIN-dialog-description"
    >
      <DialogTitle>Enter PIN</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ PIN: '', username: user }}
          validationSchema={PINSchema}
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >{({
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          values,
        }) => {
        return (
          <Form noValidate id="verify-PIN-form" autoComplete="off" onSubmit={handleSubmit}>

            {/* <pre>{JSON.stringify(values, null, 5)}</pre>
            <pre>{JSON.stringify(errors, null, 5)}</pre> */}
            
            <TextField
              name="PIN"
              id="PIN"
              label="PIN"
              value={values.PIN || ''}
              error={Boolean(errors.PIN && touched.PIN)}
              helperText={errors.PIN && touched.PIN ? errors.PIN : ''}
              inputProps={{ maxLength: 4 }}
              fullWidth
              required
              type="password"
              onBlur={handleBlur}
              onChange={handleChange}
              />

          </Form>)}}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button 
          className={classes.actionButton} 
          onClick={onClose} 
          color="primary">
          Cancel
        </Button>
        <Button 
          type="submit" 
          form="verify-PIN-form"
          className={classes.actionButton} 
          color="primary" 
          autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )

}

export default class UserEsignSelectorInstance {

  constructor(obj) {
      if (!obj) {
          obj = {};
      }   

      this.elementId = obj.elementId || null;

      this.elementName = obj.elementName || null;

      this.elementType = obj.elementType || null;

      this.required = obj.required || false;

      this.value = obj.value || '';
  }
}
import { Tooltip, IconButton } from "@material-ui/core";
import PrintIcon from '@material-ui/icons/Print';

export default function PrintIconButton(props) {
	const { onClick } = props;

	return (
		<Tooltip title="Print">
			<IconButton
				size="small" 
				onClick={onClick}>
				<PrintIcon />
			</IconButton>
		</Tooltip>
	)
}
import { 
  FormControl, 
  FormHelperText,
  InputLabel,
  MenuItem, 
  Select 
} from '@material-ui/core';
import User from '../../../Models/user';

export default function UserLevelSelector(props) {
  const { 
    error, 
    label, 
    name, 
    isExistingUser,
    value, 
    onChange, 
    onBlur 
  } = props;

  const choices = User.userLevels.filter(level => level.userLevel !== '10');

  return (
    <FormControl error={error} fullWidth>

      <InputLabel required shrink>
        { label }
      </InputLabel>

      <Select
        name={name}
        value={value || ''}
        onChange={onChange}
        displayEmpty
        onBlur={onBlur}>
          <MenuItem value="">-Select-</MenuItem>
          { choices.map(choice => (
            <MenuItem key={choice.userLevel} value={choice.userLevel}>{choice.userGroupName}</MenuItem>
          ))}

      </Select>

      { value === '5' && !isExistingUser &&
        <FormHelperText>New program will be created unless otherwise specificed.</FormHelperText>
      }
    </FormControl>
  )
}

export default class SelectorUtils {

  static sortAscending(items, orderByParam) {
    return items.slice().sort((a, b) => a[orderByParam].toLowerCase().localeCompare(b[orderByParam].toLowerCase()));
  }

  static sortDescending(items, orderByParam) {
    return items.slice().sort((a, b) => b[orderByParam].toLowerCase().localeCompare(a[orderByParam].toLowerCase()));
  }
}
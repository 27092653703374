import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    supportRow: {
        marginTop: 16,
        fontSize: '12px',
    },
    supportLink: {
        color: '#5e7999',
        textDecoration: 'none',
    },
}));

export default function SSOLoginPage(props) {
    const classes = useStyles();

    return (
        <div className={classes.supportRow}>
            <label>Need help? </label><a href="tel:888-214-1710" className={classes.supportLink}>888-214-1710</a> | <a href="mailto:support@vesselvanguard.com" className={classes.supportLink}>support@vesselvanguard.com</a>
        </div>
    )
}
import { 
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';


export default function CustomFormCategoryPicker(props) {
    const { 
        availableCategories, 
        onChange, 
        onAddClick,
        selectedCategory,
        showError
    } = props;

    const handleChange = (e) => {
        const selection = e.target.value;
        if(selection === 'add') {
            onAddClick()
        } else {
            onChange({
                target: { 
                    name: props.name, 
                    value: selection
                }
            });
        }
    }

    return (
        <FormControl fullWidth>
            <InputLabel 
                error={showError}
                id="category-picker-label">Category</InputLabel>
            <Select
                labelId="category-picker-label"
                name="category"
                id="category-picker"
                fullWidth
                error={showError}
                value={selectedCategory || ''}
                onChange={handleChange}
                autoWidth
                >

                <MenuItem value="add">Add New Category</MenuItem>
                { availableCategories.map(category => (
                    <MenuItem value={category.id} key={category.id}>{category.name}</MenuItem>
                ))}

            </Select>
        </FormControl>
    )

}
import { 
    FormControl, 
    FormLabel,
    MenuItem, 
    Select 
} from '@material-ui/core';
import PurchaseOrderStatus from '../Models/purchaseOrderStatus';

export default function PurchaseOrderStatusSelector(props) {
    const { value, name, fullWidth, onChange, onBlur } = props;

    const statuses = [
        {value: PurchaseOrderStatus.DRAFT, name: 'Draft'},
        {value: PurchaseOrderStatus.APPROVED, name: 'Approved'},
        {value: PurchaseOrderStatus.DENIED, name: 'Denied'},
        {value: PurchaseOrderStatus.SENT, name: 'Sent to Vendor'},
        {value: PurchaseOrderStatus.PARTIALRECEIVED, name: 'Partially Received'},
        {value: PurchaseOrderStatus.RECEIVED, name: 'Received'},
        {value: PurchaseOrderStatus.CANCELLED, name: 'Cancelled'},
    ];

    return (
        <FormControl fullWidth>

            <FormLabel>Status</FormLabel>

            <Select
                name={name}
                value={value || ''}
                fullWidth={fullWidth}
                onChange={onChange}
                onBlur={onBlur}>
                { statuses.map((status) => (
                    <MenuItem key={status.value} value={status.value}>{status.name}</MenuItem>
                ))}
            </Select>

      </FormControl>
    )
}
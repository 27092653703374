export default class Address {
    constructor(obj) {
        if(!obj) {
            obj = {}
        }

        this.street1 = obj.street1 || '';

        this.street2 = obj.street2 || '';

        this.city = obj.city || '';
        
        this.state = obj.state || '';
        
        this.postalCode = obj.postalCode || '';

        this.country = obj.country || '';
    }
}
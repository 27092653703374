import { 
    FormControl, 
    TextField, 
} from '@material-ui/core';
import { ErrorMessage } from 'formik';

export default function Address(props) {
    const { value, classes, touched, errors, onChange, onBlur } = props;

    return (
        <FormControl>

            <TextField 
                name="street1" 
                label="Street 1" 
                error={Boolean(errors.street1 && touched.street1)}
                value={value.street1} 
                onChange={onChange} 
                onBlur={onBlur}
                fullWidth
                required />
            <ErrorMessage
                className={classes.error}
                name="street1"
                component="p" />

            <TextField 
                name="street2" 
                label="Street 2" 
                error={Boolean(errors.street2 && touched.street2)}
                value={value.street2} 
                onChange={onChange} 
                onBlur={onBlur}
                fullWidth />
            <ErrorMessage
                className={classes.error}
                name="street2"
                component="p" />

            <TextField 
                name="city" 
                label="City" 
                error={Boolean(errors.city && touched.city)}
                value={value.city} 
                onChange={onChange} 
                onBlur={onBlur}
                fullWidth
                required />
            <ErrorMessage
                className={classes.error}
                name="city"
                component="p" />

            <TextField 
                name="state" 
                label="State" 
                error={Boolean(errors.state && touched.state)}
                value={value.state} 
                onChange={onChange} 
                onBlur={onBlur}
                fullWidth />
            <ErrorMessage
                className={classes.error}
                name="state"
                component="p" />

            <TextField 
                name="postalCode" 
                label="Postal Code" 
                error={Boolean(errors.postalCode && touched.postalCode)}
                value={value.postalCode} 
                onChange={onChange} 
                onBlur={onBlur}
                fullWidth />
            <ErrorMessage
                className={classes.error}
                name="postalCode"
                component="p" />

            <TextField 
                name="country" 
                label="Country" 
                error={Boolean(errors.country && touched.country)}
                value={value.country} 
                onChange={onChange} 
                onBlur={onBlur}
                fullWidth
                required />
            <ErrorMessage
                className={classes.error}
                name="country"
                component="p" />

      </FormControl>
    )
}
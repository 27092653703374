import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import awsmobile from '../../../aws-exports';

const apiName = awsmobile.aws_cloud_logic_custom[0].name;

const getUsers = createAsyncThunk(
  'users/getUsers',
  async (params, { getState }) => {
    const users = getState().users;
    return await API.get(apiName, '/users', {
      queryStringParameters: {
        pageNumber: users.pageNumber,
        recordLimit: users.recordLimit,
        userLevel: users.userLevelFilter,
        programId: users.programFilter,
        searchTerm: users.searchTerm
      }
    });
  }
);

const createUser = createAsyncThunk(
  'users/createUser',
  async (params) => {
    const { newUser } = params;
    return await API.post(apiName, '/create-user', { body: newUser });
  }
);

const updateUser = createAsyncThunk(
  'users/updateUser',
  async (params) => {
    const { updatedUser } = params;
    return await API.put(apiName, '/update-user', { body: updatedUser });
  }
);

const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (params) => {
    const { user } = params;
    return await API.del(apiName, '/delete-user/' + user.userId, {});

  }
);

export const userActionsAsync = {
  getUsers,
  createUser,
  deleteUser,
  updateUser,
}
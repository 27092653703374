import { createSlice } from '@reduxjs/toolkit';
import { categoryActionsAsync } from './CategoryActionsAsync';
import * as utils from '../../../../Utilities/reducerUtils';
const { 
    loadCategories, 
    createCategory,
    deleteCategory,
    updateCategory,
} = categoryActionsAsync;


export const CategorySlice = createSlice({
    name: 'categories',
    initialState: {
        list: [],
        selectedCategoryId: null,
    },
    reducers: {
        selectCategory(state, action) {
            state.selectedCategoryId = action.payload.id;
        }
    },
    extraReducers: {
        [createCategory.fulfilled]: (state, action) => {
            const created = action.payload;
            state.list = [...state.list, created]
        },
        [deleteCategory.fulfilled]: (state, action) => {
            const deleted = action.payload;
            state.list = utils.deleteItemFromList(state.list, deleted);
        },
        [loadCategories.fulfilled]: (state, action) => {
            state.list = action.payload;
        },
        [updateCategory.fulfilled]: (state, action) => {
            const updated = action.payload;
            state.list = utils.updateItemInList(state.list, updated);
        },
    }
});

export const { 
    selectCategory, 
} = CategorySlice.actions;

export default CategorySlice.reducer;
import { 
  Container, 
  makeStyles, 
  Grid, 
  TextField,
  Button,
} from '@material-ui/core';
import { templateActionsAsync } from './TemplateState/TemplateActionsAsync';
import { templateActions } from './TemplateState/TemplateSlice'
import CustomFormTemplate from '../../../Models/customFormTemplate';
import { categoryActionsAsync } from '../CustomFormCategories/CategoryState/CategoryActionsAsync';
import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useState } from 'react';
import CustomFormCategoryPicker from '../CustomFormCategories/CustomFormCategoryPicker/CustomFormCategoryPicker';
import NewCategoryDialog from '../../../Core/NewCategoryDialog/NewCategoryDialog';
import { categorySelectors } from '../CustomFormCategories/CategoryState/CategorySelectors';
import AppNav from '../../../Core/AppNav';
import { Fragment } from 'react';
import PageHeader from '../../../Core/PageHeader';
import { currentUserSelectors } from '../../../CurrentUser/CurrentUserState/CurrentUserSelector';

const useStyles = makeStyles(theme => ({
  error: theme.error,
	actionButton: theme.actionButton,
	actionsRight: theme.actionsRight,
	container: theme.container,
}));


export default function NewCustomFormTemplatePage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const formikRef = useRef(null);
  const availableCategories = useSelector(categorySelectors.categorySelectorAll);
  const [newCategoryDialogOpen, setNewCategoryDialogOpen] = useState(false);

  const tenantId = useSelector(currentUserSelectors.currentTenantIdSelector);

  const newTemplate = new CustomFormTemplate({ tenantId: tenantId });


  const templateSchema = yup.object().shape({
    name: yup.string()
      .required('Required'),
    categoryId: yup.string()
      .required('Required')
      .nullable(),
    categoryName: yup.string()
      .required('Required')
      .nullable(),
  });

  const getCategoryName = id => {
    return availableCategories.find(category => category.id === id).name;
  }

  const handleNewCategorySubmit = category => {
    const params = {
      newCategory: category
    }

    dispatch(categoryActionsAsync.createCategory(params)).then(response => {
      const newCategory = response.payload;
      if(!!formikRef.current && !! newCategory) {
        formikRef.current.setFieldValue('categoryId', newCategory.id, false);
        formikRef.current.setFieldValue('categoryName', newCategory.name, false);
    }});
  }

  const handleNewTemplateSubmit = template => {
    const params = {
      newTemplate: template,
    };

    dispatch(templateActionsAsync.createTemplate(params)).then(response => {
      const newTemplate = response.payload;

      const params = {
        templateId: newTemplate.id,
        route: '/form-templates/' + newTemplate.id,
      }

      dispatch(templateActions.selectTemplateAndNavigate(params));

      formikRef.current.resetForm(new CustomFormTemplate());
    });
  }

  return (
    <Fragment>
      <AppNav />
      <Container maxWidth={false} className={classes.container}>
      	<Grid container alignItems="center" justifyContent="center">
					<PageHeader headerText="Create New Custom Form Template"/>

          <Grid item xs={12}>
            <Formik
              innerRef={formikRef}
              initialValues={newTemplate}
              validationSchema={templateSchema}
              onSubmit={ async (values) => {
                handleNewTemplateSubmit(values);
              }}>{({
                values,
                errors,
                touched,
                handleChange, 
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <Form id="new-template-form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Grid container alignItems="flex-start" spacing={1}>
                      <Grid item xs={12}>
                        <TextField 
                          name="name" 
                          label="Name" 
                          error={Boolean(errors.name && touched.name)}
                          value={values.name} 
                          onChange={handleChange} 
                          onBlur={handleBlur}
                          fullWidth
                          required />
                        <ErrorMessage
                          className={classes.error}
                          name="name"
                          component="p" />
                        </Grid>
                        <Grid item xs={12}>
                          <CustomFormCategoryPicker
                            name="categoryId"
                            availableCategories={availableCategories}
                            selectedCategory={values.categoryId}
                            showError={Boolean(errors.categoryId && touched.categoryId)}
                            onChange={e => {
                              handleChange(e);
                              setFieldValue('categoryName', getCategoryName(e.target.value), false);
                            }}
                            onAddClick={() => setNewCategoryDialogOpen(true)}
                          />
                          <ErrorMessage
                            className={classes.error}
                            name="categoryId"
                            component="p" />
                        </Grid>

                        <Grid item xs={12} className={classes.actionsRight}>
                          <Button type='submit' size='small' className={classes.actionButton}>
														Create
                          </Button>
                
                        </Grid>
                    </Grid>
                  </Form>)}}
            </Formik>
          </Grid>
        </Grid>

        <NewCategoryDialog 
          categoryType="Custom"
          open={newCategoryDialogOpen}
          onClose={() => setNewCategoryDialogOpen(false)}
          onSubmit={handleNewCategorySubmit}
        />
      </Container>
    </Fragment>
  )
}

export default class User {

  constructor(obj) {
    if (!obj) {
      obj = {};
    }

    this.id = obj.id || '';

    this.name = obj.name || obj.owner || '';

    this.userId = obj.userId || obj.user_id || '';

    this.email = obj.email || '';

    this.phone = obj.phone || '';

    this.workPhone = obj.workPhone || obj.work_phone || '';

    this.mobilePhone = obj.mobilePhone || obj.mobile_phone || '';

    this.fleetUserLevel = obj.fleetUserLevel || obj.fleet_usr_level || '';

    if(obj.fleetUserGroup === 0 || obj.fleet_usr_group === 0) {
      this.fleetUserGroup = 0;
    } else {
      this.fleetUserGroup = obj.fleetUserGroup || obj.fleet_usr_group || 0;
    }

    this.cognitoGroup = obj.cognitoGroup || '';

    this.fleetUserViews = obj.fleetUserViews || obj.fleet_usr_views || '';

    this.program = obj.program || '';

    this.locations = obj.locations ? obj.locations.map(location => typeof location === 'number' ? location : location.location_id) : null;

    if(obj.all_locations === 'Y') {
      this.allLocations = true;
    } else if(obj.all_locations === 'N') {
      this.allLocations = false;
    } else {
      this.allLocations = obj.allLocations || false;
    }

    this.vessels = obj.vessels ? obj.vessels.map(vessel => typeof vessel === 'number' ? vessel : vessel.location_id) : null;
    
    if(obj.all_vessels === 'Y') {
      this.allVessels = true;
    } else if(obj.all_vessels === 'N') {
      this.allVessels = false;
    } else {
      this.allVessels = obj.allVessels || false;
    }

    this.owner = obj.owner || '';

    this.addDt = obj.addDt || obj.add_dt || '';

    this.userStatus = obj.userStatus || obj.user_status || 'active';

    this.userLevel = obj.userLevel || obj.user_level || '';

    if(!!obj.tenantId && typeof obj.tenantId === 'string') {
      this.tenantId = obj.tenantId.split(',');
    } else if(Array.isArray(obj.tenantId) && obj.tenantId.length) {
      this.tenantId = obj.tenantId;
    } else if(!!obj.program_id && typeof obj.program_id === 'string') {
      this.tenantId = obj.program_id.split(',');
    } else if(Array.isArray(obj.program_id) && obj.program_id.length) {
      this.tenantId = obj.program_id;
    } else if(!!obj.programId && typeof obj.programId === 'string') {
      this.tenantId = obj.programId.split(',');
    } else if(Array.isArray(obj.programId) && obj.programId.length) {
      this.tenantId = obj.programId;
    } else {
      this.tenantId = [];
    }

    this.address1 = obj.address1 || obj.address_1 || '';

    this.address2 = obj.address2 || obj.address_2 || '';

    this.city = obj.city || '';

    this.state = obj.state || '';

    this.zipCode = obj.zipCode || obj.zip_code || '';

    this.country = obj.country || '';

    this.fax = obj.fax || '';

    this.userChangeId = obj.userChangeId || obj.user_chg_id || '';

    this.mobileAppAccess = obj.mobileAppAccess ? this.getTrueBool(obj.mobileAppAccess) : this.getTrueBool(obj.mobile_app_access);

    this.programVerified = obj.programVerified ? this.getTrueBool(obj.programVerified) : this.getTrueBool(obj.program_verified);

    this.fleetUserSettings = obj.fleetUserSettings || [];

    //settings

    this.admin = this.getTrueBool(obj.admin);

    this.dealer = this.getTrueBool(obj.dealer);

    this.spo = this.getTrueBool(obj.spo);

    this.spoName = obj.spoName || obj.spo_name || '';

    //Also use spo_name for dealer_name if dealer_name not available
    this.dealerName = obj.dealerName || obj.dealer_name || obj.spo_name || '';

    this.spoEmail = obj.spoEmail || obj.spo_email || '';

    this.website = obj.website || '';

    this.fleet = this.getTrueBool(obj.fleet);

    this.inventory = this.getTrueBool(obj.inventory);

    this.management = this.getTrueBool(obj.management);

    this.settings = this.getTrueBool(obj.settings);

    this.equipmentTemplates = obj.equipmentTemplates ? this.getTrueBool(obj.equipmentTemplates) : this.getTrueBool(obj.equip_templates);

    this.partsList = obj.partsList ? this.getTrueBool(obj.partsList) : this.getTrueBool(obj.parts_list);

    this.manufacturers = this.getTrueBool(obj.manufacturers);

    this.vesselTemplates = obj.vesselTemplates ? this.getTrueBool(obj.vesselTemplates) : this.getTrueBool(obj.vessel_templates);

    this.openItems = obj.openItems ? this.getTrueBool(obj.openItems) : this.getTrueBool(obj.open_items);

    this.fleetNews = obj.fleetNews ? this.getTrueBool(obj.fleetNews) : this.getTrueBool(obj.fleet_news);

    this.fleetInventory = obj.fleetInventory ? this.getTrueBool(obj.fleetInventory) : this.getTrueBool(obj.fleet_inventory);
    
    this.vesselSpares = obj.vesselSpares ? this.getTrueBool(obj.vesselSpares) : this.getTrueBool(obj.vessel_spares);

    this.fleetManagement = obj.fleetManagement ? this.getTrueBool(obj.fleetManagement) : this.getTrueBool(obj.fleet_management);

    this.fleetUsers = obj.fleetUsers ? this.getTrueBool(obj.fleetUsers) : this.getTrueBool(obj.fleet_users);

    this.homepage = this.getTrueBool(obj.homepage);

    this.suppliers = this.getTrueBool(obj.suppliers);

    this.deleteHistoryLog = obj.deleteHistoryLog ? this.getTrueBool(obj.deleteHistoryLog) : this.getTrueBool(obj.deletehistorylog);

    this.taskReasonRequired = obj.taskReasonRequired ? this.getTrueBool(this.taskReasonRequired) : this.getTrueBool(obj.task_reason_required);

  }

  getTrueBool(item) {
    if(typeof item === 'string') {
      switch (item.toLowerCase()) {
        case 'yes':
        case 'y':
        case '1':
          return true;
        default:
          return false;
      }
    } 
    return !!item;
  }

  static userLevels = [
    { userLevel: '5', userGroupName: 'Fleet Solutions' },
    { userLevel: '6', userGroupName: 'Admin' },
    { userLevel: '7', userGroupName: 'Fleet Advisor' },
    { userLevel: '10', userGroupName: 'Fleet User' },
  ]
}
import { 
  Container, 
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { purchaseOrderSelectors } from './PurchaseOrderState/PurchaseOrderSelectors';
import { purchaseOrderActionsAsync } from './PurchaseOrderState/PurchaseOrderActionsAsync';
import { purchaseOrderActions } from './PurchaseOrderState/PurchaseOrderSlice';
import PurchaseOrderTable from './PurchaseOrderTable';
import AppNav from '../../Core/AppNav';
import { Fragment } from 'react';
import PageHeader from '../../Core/PageHeader';
import { navigate } from '@reach/router';

const useStyles = makeStyles(theme => ({
	container: theme.container,
}));

export default function PurchaseOrderListPage(props) {
  const dispatch = useDispatch();
	const classes = useStyles();
  
  const purchaseOrders = useSelector(purchaseOrderSelectors.purchaseOrderSelectorAll);
  // const [search, setSearch] = useState('');

  const handleSelect = (purchaseOrder) => {
    const params = {
        purchaseOrderId: purchaseOrder.id,
        route: '/purchase-orders/' + purchaseOrder.id,
    }
    
    dispatch(purchaseOrderActions.selectPurchaseOrderAndNavigate(params));
  }

  const handleDelete = (purchaseOrder) => {
    const params = {
        purchaseOrder,
    }

    dispatch(purchaseOrderActionsAsync.deletePurchaseOrderAction(params));
  }

	const headerButtons = [
		{
			buttonText: 'Create New Purchase Order',
			buttonAction: () => navigate("/purchase-orders/new"),
		}
	]

  return (
    <Fragment>
      <AppNav />
      <Container  maxWidth={false} className={classes.container}>

      <PageHeader 
        headerText="Purchase Orders"
       	headerButtons={headerButtons}
        />
    
        {/* <Grid container>
            <Grid item>
                <TextField
                    name="search"
                    value={search}
                    placeholder="Search"
                    type="search"></TextField>
            </Grid>
        </Grid> */}

        <br></br>
        
        <PurchaseOrderTable
          purchaseOrders={purchaseOrders}
          onSelect={handleSelect}
          onDelete={handleDelete}
        />

      </Container>
    </Fragment>
  )
}
import { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { currentUserActionsAsync } from '../CurrentUser/CurrentUserState/CurrentUserActionsAsync';
import { navigate } from '@reach/router';

export default function Logout(props) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(currentUserActionsAsync.signOutUser()).then(() => navigate('/login'));
        ;
    });

    return null;
}
import { configureStore } from '@reduxjs/toolkit';
import templateSliceReducer from './Admin/CustomFormBuilder/CustomFormTemplates/TemplateState/TemplateSlice';
import categorySliceReducer from './Admin/CustomFormBuilder/CustomFormCategories/CategoryState/CategorySlice';
import formInstanceSliceReducer from './FormInstances/FormInstanceState/FormInstanceSlice';
import purchaseOrderSliceReducer from './Purchasing/PurchaseOrders/PurchaseOrderState/PurchaseOrderSlice';
import currentUserSliceReducer from './CurrentUser/CurrentUserState/CurrentUserSlice';
import vesselSliceReducer from './Vessels/VesselSlice';
import fleetUserSliceReducer from './FleetUsers/FleetUserState/FleetUserSlice';
import supplierSliceReducer from './Purchasing/Suppliers/SupplierState/SupplierSlice';
import reportSliceReducer from './Reports/ReportState/ReportSlice';
import locationSliceReducer from './Locations/LocationSlice';
import coreSliceReducer from './Core/CoreState/CoreSlice';
import systemTemplateSliceReducer from './Admin/SystemFormBuilder/SystemFormTemplates/SystemTemplateState/SystemTemplateSlice';
import systemCategoriesSliceReducer from './Admin/SystemFormBuilder/SystemFormCategories/SystemCategoryState/SystemCategorySlice';
import userSliceReducer from './Admin/Users/UserState/UserSlice';
import programSliceReducer from './Programs/ProgramSlice';

const store = configureStore({
  reducer: {
    templates: templateSliceReducer,
    categories: categorySliceReducer,
    formInstances: formInstanceSliceReducer,
    purchaseOrders: purchaseOrderSliceReducer,
    currentUser: currentUserSliceReducer,
    vessels: vesselSliceReducer,
    locations: locationSliceReducer,
    programs: programSliceReducer,
    suppliers: supplierSliceReducer,
    fleetUsers: fleetUserSliceReducer,
    reports: reportSliceReducer,
		core: coreSliceReducer,
    systemTemplates: systemTemplateSliceReducer,
    systemCategories: systemCategoriesSliceReducer,
    users: userSliceReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== 'production',
});


export default store;
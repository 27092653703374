import {
  makeStyles, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import SortIcon from '../../Core/SortIcon';
import StatusToggle from '../StatusToggle/StatusToggle';
import LoadingSpinner from '../../Core/Spinners/LoadingSpinner';
import DeleteIconButton from '../../Core/DeleteIconButton';
import EditIconButton from '../../Core/EditIconButton';

const useStyles = makeStyles(theme => ({
  stripedTableRow: theme.stripedTableRow,
  tableHeader: theme.tableHeader,
	tableRow: theme.tableRow,
}));

export default function UsersTable(props) {
  const classes = useStyles();

  const { 
    users, 
		loadingUsers,
    onDelete,  
    onSelect, 
    onSetOrderByParams, 
    onStatusChange 
  } = props;

  if (!!users && users.length > 0) {
    return (
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell width="10%">
              	Name
								<SortIcon
                  onSetOrderByParams={onSetOrderByParams}
                  sortParam='name'/>
              </TableCell>
              <TableCell width="65%">
                User Id
              	<SortIcon
                  onSetOrderByParams={onSetOrderByParams}
                  sortParam='userId'/>
              </TableCell>
              <TableCell align="center">
                Status
              </TableCell>
              <TableCell align="center">Edit</TableCell>
              <TableCell align="center">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id} className={[classes.stripedTableRow, classes.tableRow].join(' ')}>
                <TableCell scope="row">
                    {user.name}
                </TableCell>
                <TableCell scope="row">
                    {user.userId}
                </TableCell>
                <TableCell align="center">
                  <StatusToggle 
                    user={user}
                    onChange={onStatusChange} />
                </TableCell>
                <TableCell align="center"> 
									<EditIconButton onClick={() => onSelect(user)} />
                </TableCell>
                <TableCell align="center">    
									<DeleteIconButton onClick={() => onDelete(user)} />        
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
	}	else if(loadingUsers) {
		return <LoadingSpinner />
	} else if(!loadingUsers && !!users && !users.length) {
    return <p>No Users found!</p>
  }
}
import { Container, makeStyles, Typography } from '@material-ui/core';
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppNav from '../Core/AppNav';
import PageHeader from '../Core/PageHeader';
import AlertMessage from '../Core/AlertMessage';
import { currentUserSelectors } from './CurrentUserState/CurrentUserSelector';
import { currentUserActionsAsync } from './CurrentUserState/CurrentUserActionsAsync';
import ChangePasswordDialog from './ChangePasswordDialog/ChangePasswordDialog';
import ManagePINDialog from './ManagePINDialog/ManagePINDialog';

const useStyles = makeStyles(theme => ({
	container: theme.container,
  profilePageText: theme.profilePageText
}));

export default function ProfilePage(props) {
	const classes = useStyles();
  const dispatch = useDispatch();

  const username = useSelector(currentUserSelectors.currentUserIdSelector);

  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
  const [managePINDialogOpen, setManagePINDialogOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState(null);

  const headerButtons = [
		{
			buttonText: 'Change Password',
			buttonAction: () => setChangePasswordDialogOpen(true),
		},
    {
			buttonText: 'Manage PIN',
			buttonAction: () => setManagePINDialogOpen(true),
		}
	]

  const handleChangePassword = (values) => {
    dispatch(currentUserActionsAsync.changePassword(values))
      .unwrap()
      .then(response => {
        if(response) {
          setApiMessage({
            message: 'Password successfully changed.',
            severity: 'success'
          });
        }
        else {
          setApiMessage(null);
        }
      })
      .catch(err => setApiMessage({
        message: err.message,
        severity: 'error'
      }));

    setChangePasswordDialogOpen(false);
  }

  const handleSubmitNewPIN = (values) => {
    dispatch(currentUserActionsAsync.setPIN({ PIN: values.newPIN })).then(() => setManagePINDialogOpen(false));
  }

  const handleSubmitChangePIN = (values) => {
    const { oldPIN, newPIN } = values;
    dispatch(currentUserActionsAsync.updatePIN({ oldPIN, newPIN }))
      .unwrap()
      .then(response => {

        if(response) {
          setApiMessage({
            message: 'PIN successfully changed.',
            severity: 'success'
          });
        }
        else {
          setApiMessage(null);
        }
      })
      .catch(err => setApiMessage({
        message: 'Unable to update PIN',
        severity: 'error'
      }));

    setManagePINDialogOpen(false);
  }


  return (
    <Fragment> 

      <AppNav />
      <Container  maxWidth={false}  className={classes.container}>

        {!!apiMessage && 
          <AlertMessage 
            message={apiMessage.message}
            onClose={() => setApiMessage(null)}
            severity={apiMessage.severity}
          />
        } 

        <PageHeader 
          headerText={"My Profile - " + username}
          headerButtons={headerButtons}
        />

        <div className={classes.profilePageText}>

          <Typography variant="h3">System Security</Typography>
          <p>
            Preventing unauthorized access to your electronic accounts and devices relies on strong passwords and operational controls. 
          </p>

          <Typography variant="h3">Manage Password</Typography>
          <p>
            Passwords are six characters long and are case sensitive. It is recommended that you use upper and lower case characters as 
            well as numbers and other characters. Do not share your password with others. If you need to add additional users, simply 
            click on <strong>USER</strong> in your menu bar at the top of the screen. In the drop down menu, select <strong>SETTINGS</strong>. 
            Once in settings, go to the <strong>USER ADMINISTRATION</strong> icon at the bottom. Within User Administration, 
            select <strong>ADD USER</strong> and add an additional user.
          </p>  

          <Typography variant="h3">Manage PIN</Typography>
          <p>
            As an enhanced security feature, all e-signatures require a PIN verification. Pins are four digits. Use a code you will 
            easily remember as you will need to utilize that code each time you approve any form requiring an e-signature.
          </p>

        </div>

      </Container>

      <ChangePasswordDialog
        open={changePasswordDialogOpen}  
        onClose={() => setChangePasswordDialogOpen(false)}
        onSubmit={handleChangePassword}
      />

      <ManagePINDialog
        open={managePINDialogOpen}  
        onClose={() => setManagePINDialogOpen(false)}
        onSubmitNewPIN={handleSubmitNewPIN}
        onSubmitChangePIN={handleSubmitChangePIN}
      />

    </Fragment>
  )
}

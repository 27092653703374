import { createSlice } from '@reduxjs/toolkit';
import { fleetUserActionsAsync } from './FleetUserActionsAsync';
import { navigate } from '@reach/router';
import User from '../../Models/user';

const { 
  createFleetUser,
  getFleetOptionsByTenantId,
  getFleetUsersByTenantId,
  getFleetUserSettings,
  getFleetUserSettingsSelectionsByUserId,
  getFleetUserLocations,
  toggleFleetUserStatus,
  updateFleetUser,
  deleteFleetUser,
} = fleetUserActionsAsync;

export const FleetUserSlice = createSlice({
  name: 'fleetUsers',
  initialState: {
    list: [],
    options: {},
    orderBy: null,
    selectedUserId: null,
		isLoading: false,
    settings: null,
  },
  reducers: {
    setOrderBy(state, action) {
      state.orderBy = action.payload;
    },
    selectUser(state, action) {
      state.selectedUserId = action.payload;
    },
    unselectUser(state, action) {
      state.selectedUserId = null;
    },
    selectUserAndNavigate(state, action) {
      if(!!action.payload) {
        navigate(action.payload.route);
        state.selectedUserId = action.payload.userId;
      }
    },
    unselectUserAndNavigate(state, action) {
      if(!!action.payload) {
        navigate(action.payload.route);
        state.selectedUserId = null;
      }
    },
  },
  extraReducers: {
		[createFleetUser.fulfilled]: (state, action) => {
			state.list = [...state.list, {...new User(action.payload)}];
		},
		[deleteFleetUser.fulfilled]: (state, action) => {
			state.list = state.list.filter(user => user.userId !== action.payload.deletedUserID);
		},
    [getFleetUsersByTenantId.fulfilled]: (state, action) => {
      state.list = action.payload.map(user => {
        return {...new User(user)}
      });
			state.isLoading = false;
    },
		[getFleetUsersByTenantId.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getFleetUsersByTenantId.rejected]: (state, action) => {
			state.isLoading = false;
    },
    [getFleetOptionsByTenantId.fulfilled]: (state, action) => {
      state.options = action.payload;
    },
    [getFleetUserLocations.fulfilled]: (state, action) => {
      let foundUser = state.list.find(user => String(user.id) === action.payload.userId);
      foundUser.locations = action.payload.locations;
      foundUser.vessels = action.payload.vessels;
    },
    [getFleetUserSettings.fulfilled]: (state, action) => {
      state.settings = action.payload.featuresList;
    },
    [getFleetUserSettingsSelectionsByUserId.fulfilled]: (state, action) => {
      const foundUser = state.list.find(user => String(user.id) === action.payload.userId);
      foundUser.fleetUserSettings = action.payload.userSettings;
    },
    [toggleFleetUserStatus.fulfilled]: (state, action) => {
			state.list = state.list.map(user => user.userId === action.payload.userId ? {...new User(action.payload)} : user); 
    },
		[updateFleetUser.fulfilled]: (state, action) => {
			state.list = state.list.map(user => user.userId === action.payload.userId ? {...new User(action.payload)} : user);
		},
  }
});

export const fleetUserActions = {
  selectUser: FleetUserSlice.actions.selectUser,
  selectUserAndNavigate: FleetUserSlice.actions.selectUserAndNavigate,
  setOrderBy: FleetUserSlice.actions.setOrderBy,
  unselectUser: FleetUserSlice.actions.unselectUser,
  unselectUserAndNavigate: FleetUserSlice.actions.unselectUserAndNavigate,
}

export default FleetUserSlice.reducer;

import DropDown from './DropDown';
import { useSelector } from 'react-redux';
import { fleetUserSelectors } from '../../FleetUsers/FleetUserState/FleetUserSelector';

export default function UserSelector(props) {
    const users = useSelector (fleetUserSelectors.fleetUsersIdSelector);

    return (
        <DropDown choices={users} {...props} />
    )
}
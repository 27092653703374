import { v4 as uuidv4 } from 'uuid';
import PurchaseOrderLineItemStatus from './purchaseOrderLineItemStatus';

export default class PurchaseOrderLineItem {
    constructor(obj) {
        if(!obj) {
            obj = {}
        }

        this.id = obj.id || uuidv4();

        this.item = obj.item || '';
        
        this.status = obj.status || PurchaseOrderLineItemStatus.DRAFT;
        
        this.lineNumber = obj.lineNumber || 1;

        this.quantity = obj.quantity || 1;

        this.unitCost = obj.unitCost || 0;

        this.created = obj.created;
        
        this.lastUpdated = obj.lastUpdated;
    }
}
import { TextField } from "@material-ui/core";
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogTitle, 
  DialogContent, 
  DialogContentText,
	makeStyles,
} from "@material-ui/core";
import { Formik, Form } from 'formik';
import { useSelector } from "react-redux";
import * as yup from 'yup';
import { currentUserSelectors } from "../CurrentUserState/CurrentUserSelector";


const useStyles = makeStyles(theme => ({
	actionButton: theme.actionButton,
}));

export default function ManagePINDialog(props) {
  const classes = useStyles();

  const { 
    open, 
    onClose, 
    onSubmitNewPIN,
    onSubmitChangePIN 
  } = props;

  const userHasPIN = useSelector(currentUserSelectors.currentUserHasPINSelector);

  const changePINSchema = yup.object().shape({
    oldPIN: yup.string()
      .required('Required'),
    newPIN: yup.string().required('Required')
      .length(4, 'PIN must be exactly 4 numbers')
      .matches(/^\d+$/, { 
        message: 'PIN must only include numbers',
        excludeEmptyString: true }),
    newPINConfirm: yup.string().required('Required')
      .test('PINsMatch', 'PINs must match', function(value){
        return this.parent.newPIN === value
      }),
  });

  const newPINSchema = yup.object().shape({
    newPIN: yup.string().required('Required')
      .length(4, 'PIN must be exactly 4 numbers')
      .matches(/^\d+$/, { 
        message: 'PIN must only include numbers',
        excludeEmptyString: true }),
    newPINConfirm: yup.string().required('Required')
      .test('PINsMatch', 'PINs must match', function(value){
        return this.parent.newPIN === value
      }),
  });

  if(userHasPIN) {
    return (
      <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="manage-PIN-dialog-title"
      aria-describedby="manage-PIN-dialog-description"
      >
        <DialogTitle>Manage PIN</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Change your PIN
          </DialogContentText>
          <Formik
            initialValues={{ 
              oldPIN: '',
              newPIN: '',
              newPINConfirm: ''
            }}
            validationSchema={changePINSchema}
            onSubmit={(values) => {
              onSubmitChangePIN(values);
            }}
          >{({
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            values,
          }) => {
          return (
            <Form noValidate id="manage-PIN-form" autoComplete="off" onSubmit={handleSubmit}>

              {/* <pre>{JSON.stringify(values, null, 5)}</pre>
              <pre>{JSON.stringify(errors, null, 5)}</pre> */}
              
              <TextField
                name="oldPIN"
                id="oldPIN"
                label="Old PIN"
                value={values.oldPIN || ''}
                error={Boolean(errors.oldPIN && touched.oldPIN)}
                helperText={errors.oldPIN && touched.oldPIN ? errors.oldPIN : ''}
                inputProps={{ maxLength: 4 }}
                fullWidth
                required
                type="password"
                onBlur={handleBlur}
                onChange={handleChange}
                />

              <TextField
                name="newPIN"
                id="newPIN"
                label="New PIN"
                value={values.newPIN || ''}
                error={Boolean(errors.newPIN && touched.newPIN)}
                helperText={errors.newPIN && touched.newPIN ? errors.newPIN : ''}
                inputProps={{ maxLength: 4 }}
                fullWidth
                required
                type="password"
                onBlur={handleBlur}
                onChange={handleChange}
                />

              <TextField
                name="newPINConfirm"
                id="newPINConfirm"
                label="Confirm New PIN"
                value={values.newPINConfirm || ''}
                error={Boolean(errors.newPINConfirm && touched.newPINConfirm)}
                helperText={errors.newPINConfirm && touched.newPINConfirm ? errors.newPINConfirm : ''}
                inputProps={{ maxLength: 4 }}
                fullWidth
                required
                type="password"
                onBlur={handleBlur}
                onChange={handleChange}
                />

            </Form>)}}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button 
            className={classes.actionButton} 
            onClick={onClose} 
            color="primary">
            Cancel
          </Button>
          <Button 
            type="submit" 
            form="manage-PIN-form"
            className={classes.actionButton} 
            color="primary" 
            autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    )
  } else {
    return (
      <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="new-PIN-dialog-title"
      aria-describedby="new-PIN-dialog-description"
      >
        <DialogTitle>New PIN</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose a new PIN
          </DialogContentText>
          <Formik
            initialValues={{ 
              newPIN: '',
              newPINConfirm: ''
            }}
            validationSchema={newPINSchema}
            onSubmit={(values) => {
              onSubmitNewPIN(values);
            }}
          >{({
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            values,
          }) => {
          return (
            <Form noValidate id="new-PIN-form" autoComplete="off" onSubmit={handleSubmit}>

              {/* <pre>{JSON.stringify(values, null, 5)}</pre>
              <pre>{JSON.stringify(errors, null, 5)}</pre> */}
              
              <TextField
                name="newPIN"
                id="newPIN"
                label="New PIN"
                value={values.newPIN || ''}
                error={Boolean(errors.newPIN && touched.newPIN)}
                helperText={errors.newPIN && touched.newPIN ? errors.newPIN : ''}
                inputProps={{ maxLength: 4 }}
                fullWidth
                required
                onBlur={handleBlur}
                onChange={handleChange}
                />

              <TextField
                name="newPINConfirm"
                id="newPINConfirm"
                label="Confirm New PIN"
                value={values.newPINConfirm || ''}
                error={Boolean(errors.newPINConfirm && touched.newPINConfirm)}
                helperText={errors.newPINConfirm && touched.newPINConfirm ? errors.newPINConfirm : ''}
                inputProps={{ maxLength: 4 }}
                fullWidth
                required
                onBlur={handleBlur}
                onChange={handleChange}
                />

            </Form>)}}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button 
            className={classes.actionButton} 
            onClick={onClose} 
            color="primary">
            Cancel
          </Button>
          <Button 
            type="submit" 
            form="new-PIN-form"
            className={classes.actionButton} 
            color="primary" 
            autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { formInstancesByTenantId } from '../../graphql/queries';
import {
    createFormInstance,
    deleteFormInstance,
    updateFormInstance,
} from '../../graphql/mutations';
import FormInstance from '../../Models/formInstance';
import FormInstanceUtils from '../FormInstanceUtils/formInstanceUtils';
import { getCurrentTenant } from '../../CurrentUser/CurrentUserState/CurrentUserSelector';


const createInstance = createAsyncThunk(
    'formInstances/createFormInstance',
    async (params, { getState }) => {
        const { template } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;
        const tenantId = getCurrentTenant(currentUser);

        let instances;

        if(!params.formElementInstances) {
            instances = FormInstanceUtils.getFormElementInstances(template.formElements);
        } else {
            instances = FormInstanceUtils.getFormElementInstances(params.formElementInstances)
        }

        const newFormInstance = new FormInstance({
            type: template.type,
            categoryId: template.categoryId,
            categoryName: template.categoryName,
            templateId: template.id,
            templateName: template.name,
            tenantId: tenantId,
            createdBy: currentUser.userId,
            formElementInstances: instances,
        });

        const response = await API.graphql({
            query: createFormInstance,
            variables: { input: newFormInstance },
            authMode: authMode,
        });
        
        return response.data.createFormInstance;
    });

const cloneInstance = createAsyncThunk(
    'formInstances/cloneFormInstance',
    async (params, { getState }) => {
        const { originalInstance } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;
        const tenantId = getCurrentTenant(currentUser);

        const input = new FormInstance({
            type: originalInstance.type,
            categoryId: originalInstance.categoryId,
            categoryName: originalInstance.categoryName,
            templateId: originalInstance.templateId,
            templateName: originalInstance.templateName,
            tenantId: tenantId,
            createdBy: currentUser.userId,
            formElementInstances: originalInstance.formElementInstances,
        });

        const response = await API.graphql({
            query: createFormInstance,
            variables: {input: input},
            authMode: authMode,
        });

        return response.data.createFormInstance;
    });

const deleteInstance = createAsyncThunk(
    'formInstances/deleteFormInstance',
    async (params, { getState }) => {
        const { formInstance } = params;        
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const response = await API.graphql({
            query: deleteFormInstance,
            variables: { input: { id: formInstance.id } },
            authMode: authMode,
        });

        return response.data.deleteFormInstance;
    });

const loadInstances = createAsyncThunk(
    'formInstances/loadFormInstances',
    async (params, { getState }) => {
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;
        const tenantId = getCurrentTenant(currentUser);

        const response = await API.graphql({
            query: formInstancesByTenantId,
            variables: { tenantId: tenantId },
            authMode: authMode,
        });

        return response.data.formInstancesByTenantId.items;
    });

const updateInstance = createAsyncThunk(
    'templates/updateFormInstance',
    async (params, { getState }) => {
        const { updatedFormInstance } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const newUpdatedFormInstance = new FormInstance({
            ...updatedFormInstance,
            lastUpdatedBy: getState().currentUser.userId,
        });

        const response = await API.graphql({
            query: updateFormInstance,
            variables: { input: newUpdatedFormInstance },
            authMode: authMode,
        });

        return response.data.updateFormInstance;
    });

export const formInstanceActionsAsync = {
    createInstance,
    deleteInstance,
    loadInstances,
    updateInstance,
    cloneInstance,
};
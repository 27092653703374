import { 
    Button, 
    Grid, 
    IconButton,
    makeStyles,
    TextField 
} from '@material-ui/core';
import { ErrorMessage, FieldArray } from 'formik';
import { Fragment } from 'react';
import PurchaseOrderLineItem from '../Models/purchaseOrderLineItem';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';


const useStyles = makeStyles(theme => ({
    error: theme.error,
    root: {
        marginTop: 10,
    },
    listItem: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: theme.borderRadius,
    }
}));

export default function LineItemSelector(props) {
    const { lineItems, onBlur, onChange } = props;
    const classes = useStyles();

    return (
        <Grid className={classes.root} container spacing={2}>

            <FieldArray
                name="lineItems"
                render={arrayHelpers => (
                    <Fragment>
                       { lineItems.map((lineItem, index) => (
                        <Fragment key={index}>
                            <Grid item xs={4}>
                                <TextField 
                                    name={`lineItems.${index}.item`}
                                    label="Item" 
                                    value={lineItem.item} 
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    fullWidth />
                                <ErrorMessage
                                    className={classes.error}
                                    name={`lineItems.${index}.item`}
                                    component="p" />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField 
                                    name={`lineItems.${index}.unitCost`} 
                                    label="Unit Cost" 
                                    type="number"
                                    value={lineItem.unitCost} 
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    fullWidth />
                                <ErrorMessage
                                    className={classes.error}
                                    name={`lineItems.${index}.unitCost`}
                                    component="p" />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField 
                                    name={`lineItems.${index}.quantity`}
                                    label="Quantity" 
                                    type="number"
                                    value={lineItem.quantity} 
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    fullWidth />
                                <ErrorMessage
                                    className={classes.error}
                                    name={`lineItems.${index}.quantity`}
                                    component="p" />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton 
                                onClick={() => {
                                    arrayHelpers.remove(index)
                                }}>
                                    <DeleteForeverIcon />
                                </IconButton>
                            </Grid>
                        </Fragment>
                        ))}
                            
                        <Button 
                            variant='outlined'
                            size='small'
                            type="button" 
                            onClick={() => arrayHelpers.push(new PurchaseOrderLineItem({ lineNumber: lineItems.length + 1 }))}>
                          Add a Line Item
                        </Button>
                      <div>
                 </div>
               </Fragment>
             )}
            >
            </FieldArray>
        </Grid>
    )
}
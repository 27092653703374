import { createSlice } from '@reduxjs/toolkit';
import { reportActionsAsync } from './ReportActionsAsync';

const { getDashboardById } = reportActionsAsync;

export const ReportSlice = createSlice({
    name: 'reports',
    initialState: {
        selectedReport: null,
    },
    reducers: {},
    extraReducers: {
        [getDashboardById.fulfilled]: (state, action) => {
            state.selectedReport = action.payload.EmbedUrl;
        },
    }
});

export default ReportSlice.reducer;
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import awsmobile from '../../aws-exports';
import { getCurrentTenant } from '../../CurrentUser/CurrentUserState/CurrentUserSelector';

const apiName = awsmobile.aws_cloud_logic_custom[0].name;

const getFleetUsersByTenantId = createAsyncThunk(
  'fleetUsers/getFleetUsersByTenantId',
  async (params, { getState }) => {
    const tenantId = getCurrentTenant(getState().currentUser);
    return await API.get(apiName, '/fleet-users/' + tenantId, {});
  }
);

const getFleetOptionsByTenantId = createAsyncThunk(
  'fleetUsers/getFleetOptionsByTenantId',
  async (params, { getState }) => {
    const tenantId = getCurrentTenant(getState().currentUser);
    return await API.get(apiName, '/fleet-options/' + tenantId, {});
  }
);

const getFleetUserSettings = createAsyncThunk(
  'fleetUsers/getFleetUserSettingsByUserId',
  async (params) => {
    return await API.get(apiName, '/fleet-user-settings', {});
  }
);

const getFleetUserSettingsSelectionsByUserLevel = createAsyncThunk(
  'fleetUsers/getFleetUserSettingsByUserLevel',
  async (params) => {
    const { userLevel } = params;
    return await API.get(apiName, '/fleet-user-settings', {
      queryStringParameters: {
        userLevel: userLevel,
      }
    });
  }
);

const getFleetUserSettingsSelectionsByUserId = createAsyncThunk(
  'fleetUsers/getFleetUserSettingsSelectionsByUserId',
  async (params) => {
    const { userId }  = params;
    return await API.get(apiName, '/fleet-user-settings', {
      queryStringParameters: {
        userId: userId,
      }
    });
  }
);

const verifyCognitoUser = createAsyncThunk(
  'fleetUsers/verifyCognitoUser',
  async (params) => {
    const { username }  = params;
    return await API.get(apiName, '/verify-user/' + username, {});
  }
);

const getFleetUserLocations = createAsyncThunk(
  'fleetUsers/getFleetUserLocations',
  async (params) => {
    const { userId }  = params;
    return await API.get(apiName, '/fleet-user-locations/' + userId, {});
  }
);

const createFleetUser = createAsyncThunk(
  'fleetUsers/createFleetUser',
  async (params) => {
    const { newUser } = params;
    return await API.post(apiName, '/create-fleet-user', { body: newUser });
  }
);

const deleteFleetUser = createAsyncThunk(
  'fleetUsers/deleteFleetUser',
  async (params) => {
    const { user } = params;
    return await API.del(apiName, '/delete-user/' + user.userId, {});
  }
);

const updateFleetUser = createAsyncThunk(
  'fleetUsers/updateFleetUser',
  async (params) => {
    const { updatedUser } = params;
    return await API.post(apiName, '/update-fleet-user/' + updatedUser.userId, { body: updatedUser });
  }
);

const toggleFleetUserStatus = createAsyncThunk(
  'fleetUsers/toggleFleetUserStatus',
  async (params) => {
    const { user } = params;
    return await API.put(apiName, '/toggle-fleet-user-status/' + user.userId, { body: user })
  }
);

const authenticatePIN = createAsyncThunk(
  'currentUser/authenticatePIN',
  async (params) => {
    const { PIN, username } = params;
    return await API.get(apiName, '/check-pin/', {
      queryStringParameters: {
        PIN,
        username
      }
    });
  }
)

export const fleetUserActionsAsync = {
  authenticatePIN,
  createFleetUser,
  verifyCognitoUser,
  getFleetUserLocations,
  getFleetOptionsByTenantId,
  getFleetUserSettingsSelectionsByUserLevel,
  getFleetUserSettingsSelectionsByUserId,
  getFleetUserSettings,
  getFleetUsersByTenantId,
  toggleFleetUserStatus,
  updateFleetUser,
  deleteFleetUser,
}

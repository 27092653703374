import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Auth, Hub } from 'aws-amplify';
import { currentUserActionsAsync } from '../CurrentUser/CurrentUserState/CurrentUserActionsAsync';
import { currentUserActions } from '../CurrentUser/CurrentUserState/CurrentUserSlice';
import LoadingSpinnerGreyBackground from './Spinners/LoadingSpinnerGreyBackground';
import { makeStyles } from '@material-ui/core';
import WheelhouseLogo from '../Assets/Logos/logo-dark.svg';

const useStyles = makeStyles(theme => ({
  alert: theme.alert,
  error: theme.error,
  forgotPassword: theme.forgotPassword,
  loginButton: theme.noNavButton,
  loginInput: theme.noNavInput,
  loginlabel: theme.noNavLabel,
  loginRow: theme.noNavRow,
  loginButtonRow: theme.loginButtonRow,
  loginTile: theme.noNavTile,
  loginTileLogoContainer: theme.loginTileLogoContainer,
  loginTileLogo: theme.loginTileLogo,
  pageBackground: theme.noNavBackground,
  termsContainer: {
    color: '#FFF',
    fontWeight: 'bold',
    marginTop: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'cener',
    flexDirection: 'column',
    rowGap: 2,
    '& span, a': {
      textAlign: 'center',
      textDecoration: 'none',
      color: '#fff',
    }
  },
  tosLink: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline !important'
   },
  },
  ssoText: {
    textAlign: 'center',
  },
}));

const PRESIGNUP_ERROR = "Error in SAML response processing: PreSignUp failed with error ";
const USER_LINKED_MESSAGE = "SSO User Linked-";

export default function SSOLoginPage(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const wheelhouseRoot = useSelector(state => state.core.wheelhouseRoot);
    const [SSOError, setSSOError] = useState(null)
;
    const handleAuthMessage = (msg) => {
      const { event, data } = msg.payload;
      if (event === "cognitoHostedUI_failure") {
        const message = decodeURIComponent(data.message.replace(/\+/g, '%20'));

        if (message.startsWith(PRESIGNUP_ERROR)) {
          const error = message.substr(PRESIGNUP_ERROR.length);

          if (error.startsWith(USER_LINKED_MESSAGE)) {
            const provider = error.substring(USER_LINKED_MESSAGE.length, error.length-2); // -2 to remove period and space in message
            Auth.federatedSignIn({ provider: provider });
          } else if(error.includes('Unauthorized Provider')) {
            setSSOError('Unauthorized SSO Login.');
          } else {
            setSSOError(error);
          }

        }
      }
    };

    useEffect(() => {
      Hub.listen('auth', handleAuthMessage);
      return function cleanup() {
        Hub.remove('auth', handleAuthMessage);
      }
    }, []);

    useEffect(() => {
      if (wheelhouseRoot){
        Auth.currentAuthenticatedUser()
          .then((cognitoUser) => {
            if (cognitoUser) {
              currentUserActionsAsync.redirectToWheelhouse(wheelhouseRoot);
            }
          })
          .catch(() => {
            if(!!SSOError) {
              dispatch(currentUserActions.setLoginErrorAndNavigate({ 
                route: '/login',
                error: SSOError
             }))
            }
          });
      }
    }, [dispatch, SSOError, wheelhouseRoot]);

    return (
        <div className={classes.pageBackground}>
          <div className={classes.loginTile}>
            <div className={classes.loginTileLogoContainer}>
              <img src={WheelhouseLogo} alt="Vessel Vanguard Pro Logo" className={classes.loginTileLogo} />
            </div>
            <p className={classes.ssoText}>Processing SSO Login...
            </p>
          </div>
            
          <LoadingSpinnerGreyBackground open={true} />
        </div>
    );
}

import moment from 'moment';

export default class DateUtils {

  static getDateString = input => {
    const date = new Date(input);
    return date.toLocaleDateString();
  }

  static getTime = input => {
    return moment(input).format('LT');
  }

}


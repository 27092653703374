import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import AlertMessage from './AlertMessage';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { Typography } from '@material-ui/core';
import { currentUserActionsAsync } from '../CurrentUser/CurrentUserState/CurrentUserActionsAsync';
import { useState } from 'react';
import { navigate } from '@reach/router';


const useStyles = makeStyles(theme => ({
  error: theme.error,
  pageBackground: theme.noNavBackground,
  noNavButton: theme.noNavButton,
  noNavButtonRow: theme.noNavButtonRow,
  noNavInput: theme.noNavInput,
  noNavLabel: theme.noNavLabel,
  noNavRow: theme.noNavRow,
  noNavTile: theme.noNavTile,
}));


export default function ForgotPasswordPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [username, setUsername] = useState(null);
  const [pageError, setPageError] = useState(null);


  const usernameSchema = yup.object().shape({
    username: yup.string()
    .required('Username Required'),
  });

  const newPasswordSchema = yup.object().shape({
    code: yup.string()
    .required('Code Required'),
    password: yup.string().required('Required')
      .min(6, 'Password must be at least 6 characters'),
    passwordConfirm: yup.string().required('Required')
      .test('passwordsMatch', 'Passwords must match', function(value){
        return this.parent.password === value
      }),
  });

  const handleCancel = () => {
    navigate('/login');
  }

  const handleSubmitForm = (params) => {
    dispatch(currentUserActionsAsync.submitNewPassword(params))
    .then(response => {
      if(response.error) {
        console.log(response.error);
        setPageError({
          message: response.error.message,
          severity: 'error'
        });
      } else {
        dispatch(currentUserActionsAsync.signInUser(params)).unwrap().then(() => {
          return currentUserActionsAsync.changeRdsPassword(params)
        }).then(() => {
          navigate('/login');
        }).catch((error) => {
          setPageError({
            message: "Failed to update password! Please contact Vessel Vanguard Support",
            severity: 'error'
          });
          console.error('error', error);
        })
      }
    })
    .catch(error => console.error('error', error))
  }

  if(!username) {
    return (
      <div className={classes.pageBackground}>
        
        <Formik
          initialValues={{ username: '' }}
          validationSchema={usernameSchema}
          onSubmit={(values) => {
            dispatch(currentUserActionsAsync.handleForgotPassword(values))
              .then(() => setUsername(values.username))
              .catch(error => console.error(error));
          }}
        >{({
          isValid, 
          dirty,
          handleSubmit,
        }) => {
        return (
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>

            <div className={classes.noNavTile}>

              <Typography variant="h3">Enter Username</Typography>
             
              <div className={classes.noNavLabel}>
                <span>Username</span>
              </div>
              <div className={classes.noNavRow}>
                <Field id="username" name="username" className={classes.noNavInput} />
                <ErrorMessage className={classes.error} component="p" name="username" />
              </div>

              <div className={classes.noNavButtonRow}>
                <button type="button" onClick={handleCancel} className={classes.noNavButton}>Cancel</button>

                <button  disabled={!isValid || !dirty} type="submit" className={classes.noNavButton}>Send Code</button>
              </div>
            </div>

          </Form>)}}
        </Formik>
      </div>
    )
  } else if(username) {
    return (
      <div className={classes.pageBackground}>


        { !!pageError && 
          <AlertMessage
            message={pageError.message}
            onClose={() => setPageError(null)}
            severity={pageError.severity}
          />
        }

        <Formik
          initialValues={{ 
            code: '',
            password: '',
            passwordConfirm: ''
          }}
          validationSchema={newPasswordSchema}
          onSubmit={(values) => {
            const params = {
              ...values,
              username,
            }
            handleSubmitForm(params);
          }}
        >{({
          isValid, 
          dirty,
          handleSubmit,
        }) => {
        return (
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <div className={classes.noNavTile}>

              <Typography variant="h2">Enter Code and New Password</Typography>
             
              <div className={classes.noNavLabel}>
                <span>Verification Code</span>
              </div>
              <div className={classes.noNavRow}>
                <Field id="code" name="code" className={classes.noNavInput} />
                <ErrorMessage className={classes.error} component="p" name="code" />
              </div>

              <div className={classes.noNavLabel}>
                <span>New Password</span>
              </div>
              <div className={classes.noNavRow}>
                <Field id="password" name="password" type="password" className={classes.noNavInput} />
                <ErrorMessage className={classes.error} component="p" name="password" />
              </div>

              <div className={classes.noNavLabel}>
                <span>Confirm Password</span>
              </div>
              <div className={classes.noNavRow}>
                <Field id="passwordConfirm" name="passwordConfirm" type="password" className={classes.noNavInput}/>
                <ErrorMessage className={classes.error} component="p" name="passwordConfirm" />
              </div>

              <div className={classes.noNavButtonRow}>
                <button type="button" onClick={handleCancel}  className={classes.noNavButton}>Cancel</button>

                <button disabled={!isValid || !dirty} type="submit" className={classes.noNavButton}>Submit</button>
              </div>
            </div>

          </Form>)}}
        </Formik>
      </div>
    )
  } else return null;
}

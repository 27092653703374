import { 
    Button, 
    FormLabel, 
    Grid, 
    makeStyles,
    TextField 
} from '@material-ui/core';
import { FieldArray } from 'formik';
import { Fragment } from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 10,
    }
}));

export default function ChoiceSelector(props) {
    const { choices, onBlur, onChange } = props;
    const classes = useStyles();

    return (
        <Grid className={classes.root} container spacing={2}>
            <Grid item xs={12}>
                <FormLabel>Choices</FormLabel>
            </Grid>
            <FieldArray
                name="choices"
                render={arrayHelpers => (
                    <Fragment>
                      {choices && choices.length > 0 ? (
                        choices.map((choice, index) => (
                          <Grid item xs={12} key={index}>
                            <TextField 
                                name={`choices.${index}`}
                                value={choice}
                                color="secondary"
                                onChange={onChange}
                                onBlur={onBlur}
                                />
                            {' '}
                            <Button
                                variant='outlined'
                                size='small'
                                type="button"
                                onClick={() => arrayHelpers.remove(index)} 
                            >Remove</Button>
                            {' '}
                            <Button
                                variant='outlined'
                                size='small'
                                type="button"
                                onClick={() => arrayHelpers.insert(index + 1, '')} 
                                >Add</Button>
                          </Grid>
                        ))
                      ) : (
                        <Button 
                            variant='outlined'
                            size='small'
                            type="button" 
                            onClick={() => arrayHelpers.push('')}>
                          Add a Choice
                        </Button>
                      )}
                      <div>
                 </div>
               </Fragment>
             )}
            >
            </FieldArray>
        </Grid>
    )
}
const purchaseOrderLineItemStatus = Object.freeze({
    "DRAFT": "DRAFT",
    "APPROVED": "APPROVED",
    "DENIED": "DENIED",
    "SENT": "SENT",
    "PARTIALRECEIVED": "PARTIALRECEIVED",
    "RECEIVED": "RECEIVED",
    "CANCELLED": "CANCELLED",
});

export default purchaseOrderLineItemStatus;
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { 
    categorysByTenantId, 
} from '../../../../graphql/queries';
import {
    createCustomFormCategory,
    deleteCustomFormCategory,
    updateCustomFormCategory,
} from '../../../../graphql/mutations';
import { getCurrentTenant } from '../../../../CurrentUser/CurrentUserState/CurrentUserSelector';

    
export const createCategory = createAsyncThunk(
    'categories/createCategory',
    async (params, { getState }) => {
        const { newCategory } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;
        const tenantId = getCurrentTenant(currentUser);

        const input = {
            ...newCategory,
            tenantId: tenantId,
            createdBy: currentUser.userId,
        }

        const response = await API.graphql({
            query: createCustomFormCategory,
            variables: { input: input },
            authMode: authMode,
        });

        return response.data.createCustomFormCategory;
    });

export const deleteCategory = createAsyncThunk(
    'categories/deleteCategory',
    async (params, { getState }) => {
        const { category } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const response = await API.graphql({
            query: deleteCustomFormCategory,
            variables: { input: { id: category.id } },
            authMode: authMode,
        });

        return response.data.deleteCustomFormCategory;
    });
        
export const loadCategories = createAsyncThunk(
    'categories/loadCategories',
    async (params, { getState }) => {
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;
        const tenantId = getCurrentTenant(currentUser);

        const response = await API.graphql({
            query: categorysByTenantId,
            variables: { tenantId: tenantId },
            authMode: authMode,
        });
        return response.data.categorysByTenantId.items;
    });


export const updateCategory = createAsyncThunk(
    'categories/updateCategory',
    async (updatedCategory, { getState }) => {
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const response = await API.graphql({
            query: updateCustomFormCategory,
            variables: { input: updatedCategory },
            authMode: authMode,
        });

        return response.data.updateCustomFormCategory;
    });

export const categoryActionsAsync = {
    createCategory,
    deleteCategory,
    loadCategories,
    updateCategory,
}
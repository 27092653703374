import { Grid, makeStyles, TextField  } from '@material-ui/core';
import { ErrorMessage, Formik, Form } from 'formik';
import * as yup from 'yup';
import UserOptionSelector from './UserOptionSelector/UserOptionSelector';
import LocationMultiSelector from './LocationMultiSelector/LocationMultiSelector';
import VesselMultiSelector from './VesselMultiSelector/VesselMultiSelector';
import { useSelector } from 'react-redux';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Fragment, useEffect, useRef } from 'react';
import UserActionButtons from '../Core/UsersActionButtons/UserActionButtons';
import FleetUserSettings from './FleetUserSettings/FleetUserSettings';


const useStyles = makeStyles(theme => ({
  actionsRight: theme.actionsRight,
  error: theme.error,
}));

export default function RenderFleetUser(props) {
  const classes = useStyles();
  const { 
    user, 
    isExistingUser,
    getFleetUserSettingsSelections,
    getFleetUserLocations,
    onCancel, 
    onChangeUserLevel,
    onSubmit, 
  } = props;

  const userOptions = useSelector(state => state.fleetUsers.options);
  const availableLocations = useSelector(state => state.locations.list);
  const availableVessels = useSelector(state => state.vessels.list);
  const fleetUserSettings = useSelector(state => state.fleetUsers.settings);
  const formikRef = useRef(null);

  useEffect(() => {
    if(isExistingUser && !user.fleetUserSettings.length) {
      getFleetUserSettingsSelections(user, formikRef);
    }
  }, [user, getFleetUserSettingsSelections, isExistingUser]);

  useEffect(() => {
    if(isExistingUser && (!user.locations || !user.vessels)) {
      getFleetUserLocations(user, formikRef);
    }
  }, [user, getFleetUserLocations, isExistingUser]);

  const existingUserSchema = yup.object().shape({
    name: yup.string().required('Required'),
    userId: yup.string().required('Required'),
    email: yup.string().email('Must be a valid email').required('Required'),
    allLocations: yup.boolean(),
    locations: yup.array().ensure().when('allLocations', {
      is: false,
      then: (schema) => schema.min(1, 'Choose at least one location'),
    }),
    allVessels: yup.boolean(),
    vessels: yup.array().ensure().when('allVessels', {
      is: false,
      then: (schema) => schema.min(1, 'Choose at least one vessel'),
    }),
    fleetUserLevel: yup.number().required('Required'),
    fleetUserGroup: yup.number().required('Required'),
    fleetUserViews: yup.number().required('Required'),
  });

  const newUserSchema = existingUserSchema.concat(yup.object().shape({
    password: yup.string().required('Required').min(6, 'Password must be at least 6 characters'),
    passwordConfirm: yup.string().required('Required').test('passwordsMatch', 'Passwords must match', function(value){
      return this.parent.password === value
    }),
  }));

  if(!!user && !!user.tenantId) {
  return (      
    <Formik
      initialValues={user}
      innerRef={formikRef}
      validationSchema={isExistingUser ? existingUserSchema : newUserSchema}
      onSubmit={ async (values) => {
        if(values.fleetUserGroup === "0") {
          values.fleetUserGroup = 0;
        }
        onSubmit(values);
      }}
      >{({
        values,
        errors,
        touched,
        handleChange, 
        handleBlur,
        handleSubmit,
        setFieldValue,
        isValid, 
        dirty,
      }) => {
      return (
        <Form id="fleet-user-form" noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* <pre>{JSON.stringify(values, null, 5)}</pre>
            <pre>{JSON.stringify(errors, null, 5)}</pre> */}

            <Grid item xs={12} md={9}>
              <TextField 
                name="name" 
                label="Name" 
                error={Boolean(errors.name && touched.name)}
                helperText={errors.name && touched.name ? errors.name : ''}
                value={values.name || ''} 
                onChange={handleChange} 
                onBlur={handleBlur}
                fullWidth
                required />
            </Grid>

            <Grid item xs={12} md={9}>
              <TextField 
                name="userId" 
                label="User ID" 
                error={Boolean(errors.userId && touched.userId)}
                helperText={errors.userId && touched.userId ? errors.userId : ''}
                value={values.userId || ''} 
                onChange={handleChange} 
                onBlur={handleBlur}
                fullWidth 
                required 
                disabled={isExistingUser}
                />
            </Grid>

            <Grid item xs={12} md={9}>
              <TextField 
                name="email" 
                label="Email Address" 
                error={Boolean(errors.email && touched.email)}
                helperText={errors.email && touched.email ? errors.email : ''}
                value={values.email || ''} 
                onChange={handleChange} 
                onBlur={handleBlur}
                fullWidth
                required />
            </Grid>

            <Grid item xs={12} md={9}>
              <MuiPhoneNumber 
                id="workPhone"
                name="workPhone"
                label="Work Phone"
                onBlur={handleBlur}
                onChange={handleChange('workPhone')}
                value={values.workPhone}
                error={Boolean(errors.workPhone && touched.workPhone)}
                helperText={errors.workPhone}
                defaultCountry={'us'}
                fullWidth />
            </Grid>

            <Grid item xs={12} md={9}>
              <MuiPhoneNumber 
                id="mobilePhone"
                name="mobilePhone"
                label="Mobile Phone"
                onBlur={handleBlur}
                onChange={handleChange('mobilePhone')}
                value={values.mobilePhone}
                error={Boolean(errors.mobilePhone && touched.mobilePhone)}
                helperText={errors.mobilePhone}
                defaultCountry={'us'}
                fullWidth />
            </Grid>



            { !isExistingUser && 
              <Fragment>
                <Grid item xs={12} md={9}>
                  <TextField 
                    name="password" 
                    label="Password" 
                    error={Boolean(errors.password && touched.password)}
                    helperText={errors.password && touched.password ? errors.password : ''}
                    value={values.password || ''} 
                    onChange={handleChange} 
                    onBlur={handleBlur}
                    type="password"
                    fullWidth 
                    required />
                </Grid>

                <Grid item xs={12} md={9}>
                  <TextField 
                    name="passwordConfirm" 
                    label="Password Confirm" 
                    error={Boolean(errors.passwordConfirm && touched.passwordConfirm)}
                    helperText={errors.passwordConfirm && touched.passwordConfirm ? errors.passwordConfirm : ''}
                    value={values.passwordConfirm || ''} 
                    onChange={handleChange} 
                    onBlur={handleBlur}
                    type="password"
                    fullWidth 
                    required />
                </Grid>
              </Fragment>
            }

            <Grid item xs={12} md={9}>
              <UserOptionSelector 
                name="fleetUserLevel" 
                label="User level" 
                choices={userOptions.fleetUserLevels}
                value={values.fleetUserLevel || ''} 
                error={Boolean(errors.fleetUserLevel && touched.fleetUserLevel)}
                onChange={(e) => {
                  if(isExistingUser) {
                    onChangeUserLevel(e.target.value, setFieldValue);
                  }
                  handleChange(e);
                }} 
                onBlur={handleBlur}
                fullWidth 
                required 
              />
            </Grid>

            <Grid item xs={12} md={9}>
              <UserOptionSelector 
                name="fleetUserGroup" 
                label="Groups" 
                choices={userOptions.fleetAssignments}
                value={values.fleetUserGroup || 0}
                error={Boolean(errors.fleetUserGroup && touched.fleetUserGroup)}
                onChange={handleChange} 
                onBlur={handleBlur}
                fullWidth 
                required 
              />
            </Grid>

            <Grid item xs={12} md={9}>
              <UserOptionSelector 
                name="fleetUserViews" 
                label="Views" 
                choices={userOptions.fleetUserViews}
                value={values.fleetUserViews || ''} 
                error={Boolean(errors.fleetUserViews && touched.fleetUserViews)}
                onChange={handleChange} 
                onBlur={handleBlur}
                fullWidth 
                required 
              />
            </Grid>

            <Grid item xs={6}>
              <LocationMultiSelector 
                name="locations"
                label="Locations"
                choices={availableLocations}
                error={Boolean(errors.locations && touched.locations)}
                onChange={handleChange}
                selectAll={values.allLocations}
                selectAllName="allLocations"
                setFieldValue={setFieldValue}
                values={values}
              />
              <ErrorMessage 
                name="locations"
                component="p"
                className={classes.error}
              />
            </Grid>

            <Grid item xs={6}>
              <VesselMultiSelector 
                name="vessels"
                label="Vessels"
                choices={availableVessels}
                onChange={handleChange}
                selectAll={values.allVessels}
                selectAllName="allVessels"
                setFieldValue={setFieldValue}
                values={values}
              />
            </Grid>

            { fleetUserSettings && isExistingUser && 
              <FleetUserSettings
                values={values}
                fleetUserSettings={fleetUserSettings}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }

						<UserActionButtons
							disabled={!(isValid && dirty)}
							onCancel={onCancel}
						/>

          </Grid>
        </Form>
      )}}
    </Formik>
  )} else {
    return null;
  }
}
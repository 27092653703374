import { 
    Chip, 
    FormLabel,
} from '@material-ui/core';

export default function ElementLabel(props) {
    const { labelText, component, required } = props;



    return (
        <FormLabel component={component || 'p'}>
                { labelText }
                {' '}
                { required && 
                <Chip size="small" label="Required" /> }   
            </FormLabel>
    )
}
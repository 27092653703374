import { createSelector } from 'reselect';

export const getCurrentTenant = (currentUser) => {
  const { selectedTenantId, tenantIds } = currentUser;
  if(!!selectedTenantId) {
    return selectedTenantId;
  }
  else if(tenantIds.length === 1) {
    return tenantIds[0];
  }
  return null;
}

const getCanViewPermission = (settings, group, settingName) => {
  if(!!group && group !== "FleetUser") {
    return true;
  } else if(!!settings) {
    return settings[settingName] !== "ACCESS_DENIED";
  }
  return null;
}

const currentTenantIdSelector = createSelector(
  state => state.currentUser,
  (currentUser) => {
    return getCurrentTenant(currentUser);
  }
)

const currentUserIdSelector = createSelector(
  state => state.currentUser.userId,
  (userId) => {
    if(!!userId) {
      return userId;
    }
    return null;
  }
)

const userGroupSelector = createSelector(
  state => state.currentUser.group,
  (group) => {
    if(!!group) {
      return group;
    }
    return null;
  }
)

const currentUserHasPINSelector = createSelector(
  state => state.currentUser.hasPIN,
  (hasPIN) => {
    return hasPIN;
  }
)

const canViewSettingsTabSelector = createSelector(
  state => state.currentUser.fleetSettings,
  state => state.currentUser.group,
  (settings, group) => {
    return getCanViewPermission(settings, group, 'settings_tab');
  }
)

const canViewInventoryTabSelector = createSelector(
  state => state.currentUser.fleetSettings,
  state => state.currentUser.group,
  (settings, group) => {
    return getCanViewPermission(settings, group, 'inventory_tab');
  }
)

const canViewWorkOrdersTabSelector = createSelector(
  state => state.currentUser.fleetSettings,
  state => state.currentUser.group,
  (settings, group) => {
    return getCanViewPermission(settings, group, 'work_tab');
  }
)

const canViewReportsTabSelector = createSelector(
  state => state.currentUser.fleetSettings,
  state => state.currentUser.group,
  (settings, group) => {
    return getCanViewPermission(settings, group, 'reports_tab');
  }
)

const currentUserSettingsSelector = createSelector(
  state => state.currentUser.fleetSettings,
  (settings) => {
    return settings;
  }
)

const loginErrorSelector = createSelector(
  state => state.currentUser.loginError,
  (error) => {
    if(error) {
      return error;
    }
    return null;
  }
)

export const currentUserSelectors = {
  currentTenantIdSelector,
  currentUserIdSelector,
  currentUserSettingsSelector,
  currentUserHasPINSelector,
  userGroupSelector,
  canViewSettingsTabSelector,
  canViewInventoryTabSelector,
  canViewWorkOrdersTabSelector,
  canViewReportsTabSelector,
  loginErrorSelector
}
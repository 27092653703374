import { Checkbox, FormControlLabel, Grid, makeStyles, TextField, Typography  } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Fragment, useState } from 'react';
import UserActionButtons from '../../Core/UsersActionButtons/UserActionButtons';
import UserLevelSelector from './UserLevelSelector/UserLevelSelector';
import CountrySelector from './CountrySelector/CountrySelector';
import StateSelector from './StateSelector/StateSelector';
import YesNoSelector from './YesNoSelector/YesNoSelector';
import ProgramSelector from './ProgramSelector/ProgramSelector';
import FleetSettings from './FleetSettings/FleetSettings';
import LoadingSpinner from '../../Core/Spinners/LoadingSpinner';

const useStyles = makeStyles(theme => ({
  noWrap: {
    display: 'flex',
    flexWrap: 'nowrap',
    margin: '0 auto'
  },
  checkBox: {
    whiteSpace: 'nowrap',
    marginLeft:  5
  }
}));

export default function RenderUser(props) {
  const classes = useStyles();

  const [useExistingProgram, setUseExistingProgram] = useState(false);

  const { 
    changedByUser, 
    isExistingUser, 
    onCancel, 
    onSubmit, 
    user 
  } = props;

  const newUserSchema = yup.object().shape({
    name: yup.string().required('Required'),
    userId: yup.string().required('Required'),
    email: yup.string().email('Must be a valid email').required('Required'),
    password: yup.string().required('Required').min(6, 'Password must be at least 6 characters'),
    passwordConfirm: yup.string().required('Required').test('passwordsMatch', 'Passwords must match', function(value){
      return this.parent.password === value
    }),
    userLevel: yup.string().required('Required'),
  });

  const existingUserSchema = yup.object().shape({
    name: yup.string().required('Required'),
    userId: yup.string().required('Required'),
    email: yup.string().email('Must be a valid email').required('Required'),
    userLevel: yup.string().required('Required'),
  });

  const showPrograms = (userLevel) => {
    if((userLevel === 5 || userLevel === '5') && isExistingUser) {
      return true;
    } else if(userLevel === 7 || userLevel === '7') {
      return true;
    } else if((userLevel === 5 || userLevel === '5') && useExistingProgram) {
      return true;
    } else {
      return false;
    }
  }

  const setDefaults = (userLevel, setFieldValue) => {
    if(userLevel === '6') {
      //set true
      setFieldValue('admin', 'true', false);
      setFieldValue('spo', 'true', false);
      setFieldValue('equipmentTemplates', 'true', false);
      setFieldValue('partsList', 'true', false);
      setFieldValue('manufacturers', 'true', false);
      setFieldValue('vesselTemplates', 'true', false);
      setFieldValue('openItems', 'true', false);
      setFieldValue('vesselSpares', 'true', false);
      setFieldValue('suppliers', 'true', false);

      //set false
      setFieldValue('dealer', '', false);
      setFieldValue('fleet', '', false);
      setFieldValue('inventory', '', false);
      setFieldValue('management', '', false);
      setFieldValue('settings', '', false);
      setFieldValue('fleetInventory', '', false);
      setFieldValue('fleetManagement', '', false);
      setFieldValue('fleetUsers', '', false);
      setFieldValue('homepage', '', false);
      setFieldValue('deleteHistoryLog', '', false);
      setFieldValue('taskReasonRequired', '', false);
    } else if (userLevel === '5') {
      //set true
      setFieldValue('admin', 'true', false);
      setFieldValue('dealer', 'true', false);
      setFieldValue('spo', 'true', false);
      setFieldValue('fleet', 'true', false);
      setFieldValue('inventory', 'true', false);
      setFieldValue('management', 'true', false);
      setFieldValue('settings', 'true', false);
      setFieldValue('partsList', 'true', false);
      setFieldValue('suppliers', 'true', false);
      setFieldValue('fleetInventory', 'true', false);
      setFieldValue('vesselSpares', 'true', false);
      setFieldValue('fleetManagement', 'true', false);
      setFieldValue('fleetUsers', 'true', false);
      setFieldValue('homepage', 'true', false);
      setFieldValue('deleteHistoryLog', 'true', false);
      setFieldValue('taskReasonRequired', 'true', false);
      setFieldValue('programVerified', 'true', false);
      
      //set false
      setFieldValue('equipmentTemplates', '', false);
      setFieldValue('manufacturers', '', false);
      setFieldValue('vesselTemplates', '', false);
      setFieldValue('openItems', '', false);
    } else {
      //set false
      setFieldValue('admin', '', false);
      setFieldValue('dealer', '', false);
      setFieldValue('spo', '', false);
      setFieldValue('fleet', '', false);
      setFieldValue('inventory', '', false);
      setFieldValue('management', '', false);
      setFieldValue('settings', '', false);
      setFieldValue('partsList', '', false);
      setFieldValue('suppliers', '', false);
      setFieldValue('fleetInventory', '', false);
      setFieldValue('vesselSpares', '', false);
      setFieldValue('fleetManagement', '', false);
      setFieldValue('fleetUsers', '', false);
      setFieldValue('homepage', '', false);
      setFieldValue('deleteHistoryLog', '', false);
      setFieldValue('taskReasonRequired', '', false);
      setFieldValue('equipmentTemplates', '', false);
      setFieldValue('manufacturers', '', false);
      setFieldValue('vesselTemplates', '', false);
      setFieldValue('openItems', '', false);
    }
  }

  if(!!user) {
    return (
      <Formik
        initialValues={user}
        validationSchema={ isExistingUser ? existingUserSchema : newUserSchema}
        onSubmit={ async (values) => {
          if(isExistingUser) {
            values.userChangeId = changedByUser;
          }
          if(values.userLevel === '6') {
            values.tenantId = '0';
          } else if (values.userLevel !== '6' && Array.isArray(values.tenantId)) {
            values.tenantId = values.tenantId.filter(id => id !== '0');
          }

          onSubmit(values);
        }}
      >{({
        values,
        errors,
        touched,
        handleChange, 
        handleBlur,
        handleSubmit,
        isValid, 
        dirty,
        setFieldValue
      }) => {
        return (
          <Form id="user-form" noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <Typography variant="h3">
                  Account 
                </Typography>
              </Grid>

              <Grid item xs={6} className={classes.noWrap} >
                <UserLevelSelector
                  name="userLevel"
                  label="User Level"
                  error={Boolean(errors.userLevel && touched.userLevel)}
                  isExistingUser={isExistingUser}
                  value={values.userLevel || ''} 
                  onChange={(e) => { 
                    const { value } = e.target;
                    if(value !== '5') {
                      setUseExistingProgram(false);
                    }
                    setDefaults(value, setFieldValue);
                    handleChange(e);
                  }} 
                  onBlur={handleBlur}
                />

                { values.userLevel === '5' && !isExistingUser &&
                  <FormControlLabel
                    name={'name'}
                    className={classes.checkBox}
                    onChange={() => setUseExistingProgram(!useExistingProgram)}
                    control={<Checkbox checked={useExistingProgram} color="primary" />}
                    label="Use Existing Program"
                    />
                }
              </Grid>

              <Grid item xs={6}>
                <TextField 
                  name="userId" 
                  label="User ID" 
                  error={Boolean(errors.userId && touched.userId)}
                  helperText={errors.userId && touched.userId ? errors.userId : ''}
                  value={values.userId || ''} 
                  onChange={handleChange} 
                  onBlur={handleBlur}
                  required 
                  disabled={isExistingUser}
                  fullWidth
                  />
              </Grid>

              { !isExistingUser && 
                <Fragment>
                  <Grid item xs={6}>
                    <TextField 
                      name="password" 
                      label="Password" 
                      error={Boolean(errors.password && touched.password)}
                      helperText={errors.password && touched.password ? errors.password : ''}
                      value={values.password || ''} 
                      onChange={handleChange} 
                      onBlur={handleBlur}
                      type="password"
                      fullWidth
                      required />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField 
                      name="passwordConfirm" 
                      label="Password Confirm" 
                      error={Boolean(errors.passwordConfirm && touched.passwordConfirm)}
                      helperText={errors.passwordConfirm && touched.passwordConfirm ? errors.passwordConfirm : ''}
                      value={values.passwordConfirm || ''} 
                      onChange={handleChange} 
                      onBlur={handleBlur}
                      type="password"
                      fullWidth
                      required />
                  </Grid>
                </Fragment>
              }

              <Grid item xs={2}>
                <YesNoSelector
                  name="mobileAppAccess"
                  label="Mobile App Access"
                  value={values.mobileAppAccess || ''} 
                  onChange={handleChange} 
                  onBlur={handleBlur} 
                 />
              </Grid>

              { showPrograms(values.userLevel) &&

                <Fragment>
                  <Grid item xs={12}>
                    <Typography variant="h3">
                      Program (Fleet)
                    </Typography>
                  </Grid>

                  <Grid item xs={9}>
                    <ProgramSelector
                      name="tenantId"
                      label="Program"
                      value={values.tenantId} 
                      onChange={(e) => {
                        if(values.userLevel === '5') {
                          setFieldValue('programVerified', 'true', false);
                        }
                        handleChange(e);
                      }} 
                      onBlur={handleBlur}
                      selectMultiple={values.userLevel === '7'}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <YesNoSelector
                      name="programVerified"
                      label="Program Verified"
                      value={values.programVerified || ''} 
                      onChange={handleChange} 
                      onBlur={handleBlur} 
                    />
                  </Grid>
                </Fragment>
              }

              <Grid item xs={12}>
                <Typography variant="h3">
                  Contact 
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <TextField 
                  name="name" 
                  label="Name" 
                  error={Boolean(errors.name && touched.name)}
                  helperText={errors.name && touched.name ? errors.name : ''}
                  value={values.name || ''} 
                  onChange={handleChange} 
                  onBlur={handleBlur}
                  required
                  fullWidth />
              </Grid>
              
              <Grid item xs={6}>
                <TextField 
                  name="email" 
                  label="Email Address" 
                  error={Boolean(errors.email && touched.email)}
                  helperText={errors.email && touched.email ? errors.email : ''}
                  value={values.email || ''} 
                  onChange={handleChange} 
                  onBlur={handleBlur}
                  required 
                  fullWidth />
              </Grid>


              <Grid item xs={3}>
                <MuiPhoneNumber 
                  id="phone"
                  name="phone"
                  label="Home Phone"
                  onBlur={handleBlur}
                  onChange={handleChange('phone')}
                  value={values.phone}
                  error={Boolean(errors.phone && touched.phone)}
                  helperText={errors.phone}
                  defaultCountry={'us'}
                  fullWidth />
              </Grid>

              <Grid item xs={3}>
                <MuiPhoneNumber 
                  id="workPhone"
                  name="workPhone"
                  label="Work Phone"
                  onBlur={handleBlur}
                  onChange={handleChange('workPhone')}
                  value={values.workPhone}
                  error={Boolean(errors.workPhone && touched.workPhone)}
                  helperText={errors.workPhone}
                  defaultCountry={'us'}
                  fullWidth />
              </Grid>

              <Grid item xs={3}>
                <MuiPhoneNumber 
                  id="mobilePhone"
                  name="mobilePhone"
                  label="Mobile Phone"
                  onBlur={handleBlur}
                  onChange={handleChange('mobilePhone')}
                  value={values.mobilePhone}
                  error={Boolean(errors.mobilePhone && touched.mobilePhone)}
                  helperText={errors.mobilePhone}
                  defaultCountry={'us'}
                  fullWidth />
              </Grid>

              <Grid item xs={3}>
                <MuiPhoneNumber 
                  id="fax"
                  name="fax"
                  label="Fax"
                  onBlur={handleBlur}
                  onChange={handleChange('fax')}
                  value={values.fax}
                  error={Boolean(errors.fax && touched.fax)}
                  helperText={errors.fax}
                  defaultCountry={'us'}
                  fullWidth />
              </Grid>

              <Grid item xs={6}>
                <TextField 
                  name="address1" 
                  label="Address 1" 
                  error={Boolean(errors.address1 && touched.address1)}
                  helperText={errors.address1 && touched.address1 ? errors.address1 : ''}
                  value={values.address1 || ''} 
                  onChange={handleChange} 
                  onBlur={handleBlur}
                  fullWidth
                  />
              </Grid>

              <Grid item xs={6}>
                <TextField 
                  name="address2" 
                  label="Address 2" 
                  error={Boolean(errors.address2 && touched.address2)}
                  helperText={errors.address2 && touched.address2 ? errors.address2 : ''}
                  value={values.address2 || ''} 
                  onChange={handleChange} 
                  onBlur={handleBlur}
                  fullWidth
                  />
              </Grid>

              <Grid item xs={3}>
                <TextField 
                  name="city" 
                  label="City" 
                  value={values.city || ''} 
                  onChange={handleChange} 
                  onBlur={handleBlur}
                  fullWidth
                  />
              </Grid>

              <Grid item xs={3}>
                <StateSelector
                  name="state"
                  label="State"
                  value={values.state || ''} 
                  onChange={handleChange} 
                  onBlur={handleBlur} />
              </Grid>


              <Grid item xs={3}>
                <TextField 
                  name="zipCode" 
                  label="Zip Code" 
                  value={values.zipCode || ''} 
                  onChange={handleChange} 
                  onBlur={handleBlur}
                  fullWidth
                  />
              </Grid>

              <Grid item xs={3}>
                <CountrySelector
                  name="country"
                  label="Country"
                  value={values.country || ''} 
                  onChange={handleChange} 
                  onBlur={handleBlur} />
              </Grid>
             
              { values.userLevel === '6' && 
                <Grid item xs={3}>
                  <YesNoSelector
                    name="spo"
                    label="SPO"
                    value={values.spo || ''} 
                    onChange={handleChange} 
                    onBlur={handleBlur} 
                  />
                </Grid>
              } 

              { (values.userLevel === '5' || values.userLevel === '7') &&
                <FleetSettings 
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values}
                />
              }

              <UserActionButtons
  							disabled={!(isValid && dirty)}
  							onCancel={onCancel}
  						/>

              {/* <pre>{JSON.stringify(values, null, 1)}</pre> */}

            </Grid>
          </Form>
        )}}
      </Formik>
    )
  } else {
    return <LoadingSpinner />
  }
}
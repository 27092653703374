import { 
    FormControl, 
    FormHelperText, 
    makeStyles,
    MenuItem, 
    Select 
} from '@material-ui/core';
import ElementLabel from './ElementLabel';

const useStyles = makeStyles(theme => ({
    dropDownWidth: theme.minInputWidth,
}));

export default function DropDown(props) {
    const classes = useStyles();
    const { 
        error,  
        name, 
        onChange, 
        onBlur, 
        touched, 
        value,
        choices,
        formElement: {
            labelText = {},
            required = {},
        },
    } = props;

    const isError = Boolean(error && touched);

    const localChoices = choices ? choices : props.formElement.choices;

    return (
        <FormControl error={isError} className={classes.dropDownWidth}>

            <ElementLabel 
                labelText={labelText}
                component='div'
                required={required}/>

            <Select
                name={name}
                value={value || ''}
                onChange={onChange}
                onBlur={onBlur}>
                { localChoices.map((choice, index) => (
                    <MenuItem key={choice + index} value={choice}>{choice}</MenuItem>
                ))}
            </Select>

            <FormHelperText id="dropdown-text">{isError ? error : null}</FormHelperText>

      </FormControl>
    )
}
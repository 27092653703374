import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import awsmobile from '../aws-exports';
import { getCurrentTenant } from '../CurrentUser/CurrentUserState/CurrentUserSelector';

const apiName = awsmobile.aws_cloud_logic_custom[0].name;

const getVesselsByTenantId = createAsyncThunk(
    'vessels/getVesselsByTenantId',
    async (params, { getState }) => {
      const tenantId = getCurrentTenant(getState().currentUser);
        return await API.get(apiName, '/vessels/' + tenantId, {});
    }
);

export const vesselActionsAsync = {
    getVesselsByTenantId,
}
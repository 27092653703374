import { Router } from "@reach/router";
import ManageCustomFormTemplatesPage from './Admin/CustomFormBuilder/CustomFormTemplates/ManageCustomFormTemplatesPage';
import ManageSystemFormTemplatesPage from './Admin/SystemFormBuilder/SystemFormTemplates/ManageSystemFormTemplatesPage';
import NewCustomFormTemplatePage from './Admin/CustomFormBuilder/CustomFormTemplates/NewCustomFormTemplatePage';
import NewSystemFormTemplatePage from './Admin/SystemFormBuilder/SystemFormTemplates/NewSystemFormTemplatePage';
import CustomFormBuilderPage from './Admin/CustomFormBuilder/CustomFormBuilderPage';
import SystemFormBuilderPage from './Admin/SystemFormBuilder/SystemFormBuilderPage';
import EditFormInstancePage from './FormInstances/EditFormInstancePage';
import NewFormInstancePage from './FormInstances/NewFormInstancePage';
import ManageFormsPage from './FormInstances/ManageFormsPage';
import FormInstancesListPage from './FormInstances/FormInstancesListPage';
import NewPurchaseOrderPage from "./Purchasing/PurchaseOrders/NewPurchaseOrderPage";
import PurchaseOrderListPage from "./Purchasing/PurchaseOrders/PurchaseOrderListPage";
import EditPurchaseOrderPage from "./Purchasing/PurchaseOrders/EditPurchaseOrderPage";
import LoginPage from './Core/Login';
import SSOLoginPage from './Core/SSOLogin';
import ProcessSSOLoginPage from './Core/ProcessSSOLogin';
import LogoutPage from "./Core/Logout";
import ReportPage from './Reports/ReportPage';
import NewFleetUserPage from "./FleetUsers/NewFleetUserPage";
import ManageFleetUsersPage from "./FleetUsers/ManageFleetUsersPage";
import EditFleetUserPage from "./FleetUsers/EditFleetUserPage";
import ManageUsersPage from "./Admin/Users/ManageUsersPage";
import NewUserPage from "./Admin/Users/NewUserPage";
import EditUserPage from "./Admin/Users/EditUserPage";
import ForgotPasswordPage from "./Core/ForgotPasswordPage";
import ProfilePage from "./CurrentUser/ProfilePage";

export default function AppRouter(props) {

  return (
    <Router primary={false}>
      <LoginPage default path="login" />
      <SSOLoginPage path="sso_login" />
      <SSOLoginPage path="sso_login/:provider" />
      <ProcessSSOLoginPage path="sso_login_processing" />
      
      <LogoutPage path="logout" />
      <ForgotPasswordPage path="forgot-password" />

      <ProfilePage path="profile" />

      <ManageCustomFormTemplatesPage path="form-templates" />
      <NewCustomFormTemplatePage path="form-templates/new" />
      <CustomFormBuilderPage path="form-templates/:templateId" />

      <ManageSystemFormTemplatesPage path="system-templates" />
      <NewSystemFormTemplatePage path="system-templates/new" />
      <SystemFormBuilderPage path="system-templates/:templateId" />
      
      <ManageFormsPage  path="form-instances" />
      <FormInstancesListPage path="form-instances/:templateId" />
      <EditFormInstancePage path="form-instances/:templateId/edit/:instanceId" />
      <NewFormInstancePage path="form-instances/:templateId/new" />

      <PurchaseOrderListPage path="purchase-orders" />
      <NewPurchaseOrderPage path="purchase-orders/new" />
      <EditPurchaseOrderPage path="purchase-orders/:orderId" />

      <ReportPage path="reports/:id" />

      <ManageFleetUsersPage path="fleet-users" />
      <NewFleetUserPage path="fleet-users/new/:adminId" />
      <NewFleetUserPage path="fleet-users/new" />

      <EditFleetUserPage path="fleet-users/:userId" />

      <ManageUsersPage path="users" />
      <NewUserPage path="users/new" />
      <EditUserPage path="users/:userId" />

    </Router>
  )
}

import { Grid, IconButton, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import FormBuilderElementProvider from '../../../../Core/FormElementProvider/FormElementProvider';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Draggable } from "react-beautiful-dnd";



const useStyles = makeStyles(theme => ({
    canvasActions: {
        textAlign: 'right',
    },
    canvasElementWrapper: {
        padding: 10,
        margin: '10px 0',
        borderRadius: theme.borderRadius,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        },
    },
}));


export default function FormBuilderCanvasElement(props) {
    const { 
        formElement,
        onDelete, 
        onEditClick,
        index,
    } = props;
    const classes = useStyles();
    

    return (
        <Draggable key={formElement.id}
        draggableId={formElement.id}
        index={index}>
            {(provided, snapshot) => (
                
            <Grid container ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                alignItems="center" 
                className={classes.canvasElementWrapper} 
                key={formElement.id}>
                    <Grid item xs={10}>
                        <FormBuilderElementProvider 
                            formElement={formElement}/>
                    </Grid>
                    
                    <Grid className={classes.canvasActions} item xs={2}>
                    <IconButton 
                        onClick={() => onEditClick(formElement)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton 
                        onClick={() => onDelete(formElement)}>
                        <DeleteForeverIcon />
                    </IconButton>
                </Grid>
            </Grid> 
            )}
        </Draggable>
    )
}


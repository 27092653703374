import { createSlice } from '@reduxjs/toolkit';
import { systemTemplateActionsAsync } from './SystemTemplateActionsAsync';
import { navigate } from '@reach/router';
import * as utils from '../../../../Utilities/reducerUtils';
const {
    loadSystemTemplates,
    createSystemTemplate,
    deleteSystemTemplate,
    cloneSystemTemplate,
    updateSystemTemplate,
    addFormElementToSystemTemplate,
    removeFormElementFromSystemTemplate,
    updateFormElementPosition,
    updateFormElement,
} = systemTemplateActionsAsync;

export const SystemTemplateSlice = createSlice({
    name: 'systemTemplates',
    initialState: {
        list: [],
        selectedFormElementId: null,
        selectedTemplateId: null,
    },
    reducers: {
        selectTemplate(state, action) {
            state.selectedTemplateId = action.payload;
        },
        selectTemplateAndNavigate(state, action) {
            if(!!action.payload) {
                navigate(action.payload.route);
                state.selectedTemplateId = action.payload.templateId;
            }
        },
        selectFormElement(state, action) {
            state.selectedFormElementId = action.payload;
        },
        unselectTemplate(state, action) {
            state.selectedTemplateId = null;
        }
    },
    extraReducers: {
        [loadSystemTemplates.fulfilled]: (state, action) => {
            state.list = action.payload;
        },
        [createSystemTemplate.fulfilled]: (state, action) => {
            const created = action.payload;
            state.list = [...state.list, created]
        },
        [deleteSystemTemplate.fulfilled]: (state, action) => {
            const deleted = action.payload;
            state.list = utils.deleteItemFromList(state.list, deleted);
        },
        [cloneSystemTemplate.fulfilled]: (state, action) => {
            const cloned = action.payload;
            state.list = [...state.list, cloned]
        },
        [updateSystemTemplate.fulfilled]: (state, action) => {
            const updated = action.payload;
            state.list = utils.updateItemInList(state.list, updated);
        },
        [addFormElementToSystemTemplate.fulfilled]: (state, action) => {
            const updated = action.payload;
            state.list = utils.updateItemInList(state.list, updated);
        },
        [removeFormElementFromSystemTemplate.fulfilled]: (state, action) => {
            const updated = action.payload;
            state.list = utils.updateItemInList(state.list, updated);
        },
        [updateFormElementPosition.fulfilled]: (state, action) => {
            const updated = action.payload;
            state.list = utils.updateItemInList(state.list, updated);
        },
        [updateFormElement.fulfilled]: (state, action) => {
            const updated = action.payload;
            state.list = utils.updateItemInList(state.list, updated);
        },
    }
});

export const systemTemplateActions = {
    selectFormElement: SystemTemplateSlice.actions.selectFormElement,
    selectTemplate:  SystemTemplateSlice.actions.selectTemplate,
    selectTemplateAndNavigate: SystemTemplateSlice.actions.selectTemplateAndNavigate,
    unselectTemplate: SystemTemplateSlice.actions.unselectTemplate,
}

export default SystemTemplateSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { navigate } from '@reach/router';
import { userActionsAsync } from './UserActionsAsync';
import User from '../../../Models/user';
import * as utils from '../../../Utilities/reducerUtils';
import { getTotalPages } from './UserSelectors';

const { 
  createUser, 
  deleteUser, 
  getUsers, 
  updateUser 
} = userActionsAsync;

export const UserSlice = createSlice({
  name: 'users',
  initialState: {
    list: [],
    availableUsers: null,
    orderBy: null,
    selectedUserId: null,
		isLoading: false,
    pageNumber: 1,
    recordLimit: 20,
    searchTerm: '',
    userLevelFilter: '',
    programFilter: ''
  },
  reducers: {
    setOrderBy(state, action) {
      state.orderBy = action.payload;
    },
    setPageNumber(state, action) {
      state.pageNumber = action.payload;
    },
    setSearchTerm(state, action) {
      state.userLevelFilter = '';
      state.programFilter = '';
      state.searchTerm = action.payload;
    },
    setUserLevelFilter(state, action) {
      state.programFilter = '';
      state.searchTerm = '';
      state.userLevelFilter = action.payload;
    },
    setProgramFilter(state, action) {
      state.userLevelFilter = '';
      state.searchTerm = '';
      state.programFilter = action.payload;
    },
    showAll(state, action) {
      state.userLevelFilter = '';
      state.searchTerm = '';
      state.programFilter = '';
    },
    selectUser(state, action) {
      state.selectedUserId = action.payload;
    },
    unselectUser(state, action) {
      state.selectedUserId = null;
    },
    selectUserAndNavigate(state, action) {
      if(!!action.payload) {
        navigate(action.payload.route);
        state.selectedUserId = action.payload.userId;
      }
    },
    unselectUserAndNavigate(state, action) {
      if(!!action.payload) {
        navigate(action.payload.route);
        state.selectedUserId = null;
      }
    },
  },
  extraReducers: {
    [createUser.fulfilled]: (state, action) => {
      state.list = [...state.list, {...new User(action.payload)}];
    },
    [deleteUser.fulfilled]: (state, action) => {
			state.list = state.list.filter(user => user.userId !== action.payload.deletedUserID);
    },
    [getUsers.fulfilled]: (state, action) => {
      const { users, totalUsers } = action.payload;

      const totalPages = getTotalPages(totalUsers, state.recordLimit);
      if(state.pageNumber > totalPages ) {
        state.pageNumber = 1;
      }

      state.availableUsers = totalUsers;

      state.list = users.map(user => {
        return {...new User(user)}
      });
			state.isLoading = false;
    },
		[getUsers.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getUsers.rejected]: (state, action) => {
			state.isLoading = false;
    },
    [updateUser.fulfilled]: (state, action) => {
      const updated = {...new User(action.payload)};
      state.list = utils.updateItemInList(state.list, updated);
    },
  }
});

export const userActions = {
  selectUser: UserSlice.actions.selectUser,
  selectUserAndNavigate: UserSlice.actions.selectUserAndNavigate,
  setOrderBy: UserSlice.actions.setOrderBy,
  setPageNumber: UserSlice.actions.setPageNumber,
  setSearchTerm: UserSlice.actions.setSearchTerm,
  setUserLevelFilter: UserSlice.actions.setUserLevelFilter,
  setProgramFilter: UserSlice.actions.setProgramFilter,
  showAll: UserSlice.actions.showAll,
  unselectUser: UserSlice.actions.unselectUser,
  unselectUserAndNavigate: UserSlice.actions.unselectUserAndNavigate,
}

export default UserSlice.reducer;
import { v4 as uuidv4 } from 'uuid';

export default class CustomFormCategory {

    constructor(obj) {
        if (!obj) {
            obj = {};
        }

        this.id = obj.id || uuidv4();

        this.name = obj.name || '';

        this.tenantId = obj.tenantId || null;

        this.createdBy = obj.createdBy || null;

        this.lastUpdatedBy = obj.lastUpdatedBy || null;
    }
}
import { createSelector } from 'reselect';

const programSelectorAll = createSelector(
  state => state.programs.list,
  (programs) => {
    return programs;
  }
)

export const programSelectors = {
  programSelectorAll
}
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import awsmobile from '../../aws-exports';

const apiName = awsmobile.aws_cloud_logic_custom[0].name;

const getDashboardById = createAsyncThunk(
    'reports/getDashboard',
    async (params, { getState }) => {
        // todo: do we really need tenantId?
        // shouldn't that come from the bearer token as stored custom attribute
        // not passing it yet, but leaving comments to discuss
        const id = params;  
        return await API.post(apiName, `/reports/${id}`, {});
    }
);

export const reportActionsAsync = {
    getDashboardById
}
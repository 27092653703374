import { createSlice } from '@reduxjs/toolkit';
import { formInstanceActionsAsync } from './FormInstanceActionsAsync';
import { navigate } from '@reach/router';
import * as utils from '../../Utilities/reducerUtils';
const {     
    createInstance,
    deleteInstance,
    loadInstances,
    updateInstance,
    cloneInstance,
} = formInstanceActionsAsync;


export const FormInstanceSlice = createSlice({
    name: 'formInstances',
    initialState: {
        list: [],
        orderBy: null,
        selectedFormInstanceId: null,
    },
    reducers: {
        selectFormInstance(state, action) {
            state.selectedFormInstanceId = action.payload;
        },
        selectFormInstanceAndNavigate(state, action) {
            if(!!action.payload) {
                navigate(action.payload.route);
                state.selectedFormInstanceId = action.payload.formInstanceId;
            }
        },
        unselectFormInstance(state, action) {
            state.selectedFormInstanceId = null;
        },
        setOrderBy(state, action) {
            state.orderBy = action.payload;
        },
    },
    extraReducers: {
        [loadInstances.fulfilled]: (state, action) => {
            state.list = action.payload;
        },
        [createInstance.fulfilled]: (state, action) => {
            const created = action.payload;
            state.list = [...state.list, created];
        },
        [deleteInstance.fulfilled]: (state, action) => {
            const deleted = action.payload;
            state.list = utils.deleteItemFromList(state.list, deleted);
        },
        [updateInstance.fulfilled]: (state, action) => {
            const updated = action.payload;
            state.list = utils.updateItemInList(state.list, updated);
        },
        [cloneInstance.fulfilled]: (state, action) => {
            const cloned = action.payload;
            state.list = [...state.list, cloned];
        },
    }
});

export const formInstanceActions = { 
    selectFormInstance:  FormInstanceSlice.actions.selectFormInstance,
    selectFormInstanceAndNavigate: FormInstanceSlice.actions.selectFormInstanceAndNavigate,
    setOrderBy: FormInstanceSlice.actions.setOrderBy,
		unselectFormInstance: FormInstanceSlice.actions.unselectFormInstance,
}

export default FormInstanceSlice.reducer;

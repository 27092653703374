import {
  Box, 
  Table,
  TableBody,
} from '@material-ui/core';
import NestedTemplateTableRow from './NestedTemplateTableRow/NestedTemplateTableRow';



export default function NestedTemplateTable(props) {
  const { category } = props;
  
  if(category.templates.length > 0) {
    return (
      <Box margin={1}>
        <Table aria-label="templates">
	        <TableBody>
	          {category.templates.map((template) => (
	            <NestedTemplateTableRow
	              key={template.id}
	              template={template}
	              {...props}
	             />
	          ))}
	        </TableBody>
        </Table>
      </Box>
    )
  } else {
    return null;
  }
}
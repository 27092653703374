import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  upIcon: {
    position: 'absolute',
    left: 1,
    bottom: 3,
    height: 18,
  },
  downIcon: {
    position: 'absolute',
    left: 1,
    top: 2,
    height: 18,
  },
  sortIcon: {
    paddingTop: 12,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
}));

export default function SortIcon(props) {
  const classes = useStyles();

  const { onSetOrderByParams, sortParam } = props;

  return (
		<Tooltip title="Sort">
		  <IconButton
		    onClick={() => onSetOrderByParams(sortParam)}
		    size="small"
		    disableRipple
				color="secondary"
		    className={classes.sortIcon}>
		    <ExpandLessIcon className={classes.upIcon} />
		    <ExpandMoreIcon className={classes.downIcon} />
		  </IconButton>
		</Tooltip>
  )

}
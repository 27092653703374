import { 
	Link,
  makeStyles, 
} from '@material-ui/core';
import WheelhouseLogo from '../Assets/Logos/logo.svg';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from '@reach/router';
import { currentUserSelectors } from '../CurrentUser/CurrentUserState/CurrentUserSelector';
import { Fragment } from 'react';

const useStyles = makeStyles(theme => ({
  appNav: {
		height: 85,
		flexWrap: 'nowrap',
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		marginRight: 'auto',
		marginLeft: 'auto',
		paddingRight: 44,
		paddingLeft: 44,
		maxWidth: 1366,
		fontFamily: "'Montserrat', sans-serif",
		textTransform: 'uppercase',
		fontSize: 14,
		fontWeight: 500,
		letterSpacing: 1,
		lineHeight: '1em',
		fontStyle: 'normal',
		textDecoration: 'none',
  },
  appNavMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: theme.palette.primary.contrastText,
  },
  appNavLogo: {
    display: 'flex',
    alignItems: 'center',
		height: '100%',
    '& img': {
      height: 53,
			marginLeft: -12,
    } 
  },
  appNavWrapper: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    marginBottom: 40,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'fixed',
		top: 0,
		zIndex: 1000,

  },
	container: theme.container,
	navDropDown: theme.navDropDown,
	navDropDownContent: theme.navDropDownContent,
	navLink: theme.navLink,
	navLinkWrapper: theme.navLinkWrapper,
	navUserIcon: theme.navUserIcon,
	spacer: theme.spacer,
}));

export default function AppNav(props) {
  const classes = useStyles();

	const wheelhouseRoot = useSelector(state => state.core.wheelhouseRoot);
  const canViewInventoryTab = useSelector(currentUserSelectors.canViewInventoryTabSelector);
  const canViewWorkOrdersTab = useSelector(currentUserSelectors.canViewWorkOrdersTabSelector)
  const canViewSettingsTab = useSelector(currentUserSelectors.canViewSettingsTabSelector);
  const canViewReportsTab = useSelector(currentUserSelectors.canViewReportsTabSelector);


  return (
    <div className={classes.appNavWrapper}>
        <div className={classes.appNav}>
          <div className={classes.appNavLogo}>
			  <Link href={wheelhouseRoot}>
                <img src={WheelhouseLogo} alt="Vessel Vanguard Pro" />
              </Link>
          </div>
					<div className={classes.spacer}></div>
          <div className={classes.appNavMenu}>

						<div className={classes.navLinkWrapper}>
							<Link href={wheelhouseRoot} className={classes.navLink}>Maintenance</Link>
						</div>

            { canViewInventoryTab && 
  						<div className={classes.navLinkWrapper}>
  							<Link href={wheelhouseRoot + '/admin_fleet/inventory/'} className={classes.navLink}>Inventory</Link>
  						</div>
            }
						
            { canViewWorkOrdersTab && 
  						<div className={classes.navLinkWrapper}>
  							<Link href={wheelhouseRoot + '/admin_fleet/management/'} className={classes.navLink}>Work Orders</Link>
  						</div>
            }

            { canViewReportsTab && 
  						<div className={classes.navLinkWrapper}>
  							<Link href={wheelhouseRoot + '/admin_fleet/reports/'}  className={classes.navLink}>Reports</Link>
  						</div>
            }

						<div className={classes.navLinkWrapper}>
						<span className={classes.navLink}>User</span>

							<div className={classes.navDropDown}>
								<div className={classes.navDropDownContent}>
                  <RouterLink to="/profile">
                    Profile
                  </RouterLink>
                  { canViewSettingsTab && 
                    <Fragment>
                      <br/><br/>
    									<Link href={wheelhouseRoot + '/admin_fleet/settings/'} >
    										Settings
    									</Link> 
                    </Fragment>
                  }
                  <br/><br/>
									<Link href={wheelhouseRoot + '/logout.php'} >
										Logout
									</Link> 
									</div>
								</div>
						</div>

          </div>
        </div>
  	</div>
  )

}
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, Auth } from 'aws-amplify';
import awsmobile from '../../aws-exports';


const apiName = awsmobile.aws_cloud_logic_custom[0].name;

const getCurrentUserSettings = createAsyncThunk(
  'currentUser/getCurrentUserSettings',
  async (params) => {
    const { userId } = params;
    return await API.get(apiName, '/current-user-settings/' + userId);
  }
)


const signInUser = createAsyncThunk(
  'currentUser/signInUser',
  async (params) => {
    return await Auth.signIn(params);
  }
)

//TODO: Remove this once mobile app uses Cognito for authentication
const changeRdsPassword = (params) => {
  const { password, username } = params;
  const body = { 
    newPassword: password, 
    userId: username,
  }
  return API.put(apiName, '/update-password', { body: body })
}

const changeCognitoPassword = (params) => {
  const { user, oldPassword, newPassword } = params;
  return Auth.changePassword(user, oldPassword, newPassword)
}

const changePassword = createAsyncThunk(
  'currentUser/changePassword',
  async (params) => {
    const { newPassword } = params;
    const user = await Auth.currentAuthenticatedUser();
    return await Promise.all([
      changeCognitoPassword({ ...params, user }), changeRdsPassword({ password: newPassword, username: user.username })
    ]);
  }
)

const writeWheelhouseToken = createAsyncThunk(
  'currentUser/writeWheelhouseToken',
  async () => {
      return API.post(apiName, '/token', {});
  }
)

const redirectToWheelhouse = async (wheelhouseRoot) => {
  return API.post(apiName, '/token', {}).then(response => {
    const { token } = response;
    window.location.href = `${wheelhouseRoot}index.php?token=${token}`;
  }).catch(err => {
    console.log(err);
  });
}

const signOutUser = createAsyncThunk(
  'currentUser/signOutUser',
  async () => {
    return await Auth.signOut().then(response => {
      console.log('signed out');
    }).catch(err => {
      console.log(err);
    })
  }
)

const handleForgotPassword = createAsyncThunk(
  'currentUser/handleForgotPassword',
  async (params) => {
    const { username } = params;
    return await Auth.forgotPassword(username)
      .then(response => console.log(response))
      .catch(error => console.log(error))
  }
)


const submitNewPassword = createAsyncThunk(
  'currentUser/submitNewPassword',
  async (params) => {
    const { code, username, password } = params;
    return await Auth.forgotPasswordSubmit(username, code, password);
  }
)

const checkIfUserHasPIN = createAsyncThunk(
  'currentUser/checkIfUserHasPIN',
  async (params) => {

    return await API.get(apiName, '/check-if-pin/', {});
  }
)

const setPIN = createAsyncThunk(
  'currentUser/setPIN',
  async (params) => {
    const { PIN } = params;
    return await API.post(apiName, '/set-pin/' + PIN, {});
  }
)

const updatePIN = createAsyncThunk(
  'currentUser/updatePIN',
  async (params) => {
    const { oldPIN, newPIN } = params;
    return await API.put(apiName, '/update-pin/', {
      queryStringParameters: {
        newPIN,
        oldPIN
      }
    });
  }
)

export const currentUserActionsAsync = {
  changePassword,
  checkIfUserHasPIN,
  signInUser,
  signOutUser,
  submitNewPassword,
  getCurrentUserSettings,
  handleForgotPassword,
  setPIN,
  updatePIN,
  redirectToWheelhouse,
  changeRdsPassword,
  writeWheelhouseToken,
}

import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmbeddedDashboard from './EmbeddedDashboard';
import { reportActionsAsync } from './ReportState/ReportActionsAsync';
import { reportSelectors } from './ReportState/ReportSelectors';
import AppNav from '../Core/AppNav';
import { Container, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	container: theme.container,
}));


export default function ReportPage(props) {
    console.log('Running ReportPage');
		const classes = useStyles();
    const dispatch = useDispatch();
    const { id } = props;

    const selectedReport = useSelector(reportSelectors.selectedReport);

    if (!selectedReport) {
        dispatch(reportActionsAsync.getDashboardById(id));
    }

    if (!!selectedReport) {
        return (
            <Fragment>
								<AppNav />
								<Container  maxWidth={false} className={classes.container}>
                	<EmbeddedDashboard url={selectedReport}></EmbeddedDashboard>
								</Container>
            </Fragment>
        )
    } else {
        return null;
    }
}
import { Fragment, useState } from 'react';
import {
	IconButton,
  Collapse,
  makeStyles, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import NestedTemplateTable from '../Admin/CustomFormBuilder/CustomFormTemplates/TemplateTable/NestedTemplateTable';
import OpenIcon from './OpenIcon';

const useStyles = makeStyles(theme => ({
  categoryButton: theme.tableButton,
  tableRow: theme.tableRow,  
  tableHeader: theme.tableHeader,
}));

 
const Row = (props) => {
    const { category } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    return (
			<Fragment>
      <TableRow className={classes.tableRow} >
        <TableCell scope="row">
          	{category.name}
        </TableCell>
				<TableCell align="right">
					<IconButton onClick={() => setOpen(!open)}>
						<OpenIcon open={open} />
					</IconButton>
				</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <NestedTemplateTable 
              category={category} 
              {...props} />
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
    )
}


export default function SystemCategoryTable(props) {
  const classes = useStyles();

  const { categories } = props;

  if (!!categories) {
		return (
			<TableContainer>
				<Table aria-label="collapsible table">
					<TableHead>
						<TableRow className={classes.tableHeader}>
							<TableCell>
								System Forms And Checklists
							</TableCell>
							<TableCell>&nbsp;</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{categories.map((category) => (
							<Row 
								key={category.id} 
								category={category}
								{...props} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
		)
  } else return null;
}
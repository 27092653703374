import { 
    FormControl, 
    FormControlLabel, 
    FormHelperText, 
    Radio, 
    RadioGroup, 
} from '@material-ui/core';
import ElementLabel from './ElementLabel';


export default function MultipleChoice(props) {
    const { 
        error, 
        name, 
        onBlur, 
        onChange,
        touched,
        value,
        choices,
        formElement: {
            displayInRow = {},
            labelText = {},
            required = {}
        },
    } = props;

    const isError = Boolean(error && touched);

    const localChoices = choices ? choices : props.formElement.choices;

    return (
        <FormControl component="fieldset" error={isError}>

            <ElementLabel 
                labelText={labelText}
                component='legend'
                required={required}/>

            <RadioGroup 
                row={displayInRow} 
                name={name}
                onChange={onChange}
                onBlur={onBlur} >
                { localChoices.map((choice, index) => (
                    <FormControlLabel
                    key={choice + index}
                    label={choice}
                    control={<Radio color="default" checked={Boolean(value === choice)} value={choice} />}  
                    />
                ))}
            </RadioGroup>

            <FormHelperText id="dropdown-text">{isError ? error : null}</FormHelperText>

        </FormControl>
    )
}
import { 
  Container, 
  makeStyles, 
  Box,
  Button,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { formInstanceSelectors } from './FormInstanceState/FormInstanceSelectors';
import { templateSelectors } from '../Admin/CustomFormBuilder/CustomFormTemplates/TemplateState/TemplateSelectors';
import FormInstanceTable from '../Core/FormInstanceTable/FormInstanceTable';
import { templateActions } from '../Admin/CustomFormBuilder/CustomFormTemplates/TemplateState/TemplateSlice';
import { formInstanceActionsAsync } from './FormInstanceState/FormInstanceActionsAsync';
import { formInstanceActions } from './FormInstanceState/FormInstanceSlice';
import { Link, navigate } from "@reach/router";
import { useEffect, useState } from 'react';
import PageHeader from '../Core/PageHeader';
import ConfirmDeleteDialog from '../Core/ConfirmDeleteDialog';
import AppNav from '../Core/AppNav';
import { Fragment } from 'react';
import CloneInstanceDialog from "./CloneInstanceDialog";
import PrintFormInstance from './PrintFormInstance/PrintFormInstance';


const useStyles = makeStyles(theme => ({
  navButton: theme.navButton,
	actionButton: theme.actionButton,
	container: theme.container,
}));


export default function FormInstancesListPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { templateId } = props;

  const [orderAsc, setOrderAsc] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const selectedTemplate = useSelector(templateSelectors.templateSelectorById);
  const instancesByTemplate = useSelector(formInstanceSelectors.orderedInstanceSelector);
  const selectedInstance = useSelector(formInstanceSelectors.instanceSelectorById);

  const [isCloneInstanceDialogOpen, setIsCloneInstanceDialogOpen] = useState(false);
  const [print, setPrint] = useState(false);


  useEffect(() => {
    if(!selectedTemplate) {
      dispatch(templateActions.selectTemplate(templateId));
    }
  }, [selectedTemplate, templateId, dispatch]);

  const setOrderByParams = (orderByParam) => {
    setOrderAsc(!orderAsc); 
    dispatch(formInstanceActions.setOrderBy({ orderByParam, orderAsc }));
  }

  const handleSelect = (formInstance) => {
    const params = {
      formInstanceId: formInstance.id,
      route: '/form-instances/' + formInstance.templateId + '/edit/' +  formInstance.id,
    }
    dispatch(formInstanceActions.selectFormInstanceAndNavigate(params));
  }

	const handleDeleteDialogClose = () => {
    dispatch(formInstanceActions.unselectFormInstance());
    setDeleteDialogOpen(false);
	}
  const handleCloneClick = (formInstance) => {
    dispatch(formInstanceActions.selectFormInstance(formInstance.id));
    setIsCloneInstanceDialogOpen(true);
  }

  const handleClone = () => {
    dispatch(formInstanceActionsAsync.cloneInstance({ originalInstance: selectedInstance })).then(response => {
      const clonedInstance = response.payload;

      const params = {
          formInstanceId: clonedInstance.id,
          route: '/form-instances/' + clonedInstance.templateId + '/edit/' + clonedInstance.id,
      }

      dispatch(formInstanceActions.selectFormInstanceAndNavigate(params));
    });
  }

  const handleCloneDialogClose = () => {
      setIsCloneInstanceDialogOpen(false);
      dispatch(formInstanceActions.unselectFormInstance());
  }

	const handleDeleteFormInstanceClick = formInstance => {
		dispatch(formInstanceActions.selectFormInstance(formInstance.id));setDeleteDialogOpen(true);
	}

	const handleDeleteFormInstanceConfirm = () => {
		dispatch(formInstanceActionsAsync.deleteInstance({ formInstance: selectedInstance })).then(() => {
      handleDeleteDialogClose();
    });
	}

  const handlePrint = (instance) => {
    dispatch(formInstanceActions.selectFormInstance(instance.id));
    setPrint(true);
  }

  useEffect(() => {
    if(print) {
      window.print();
      setPrint(false);
    }
  }, [print])

	const headerButtons = [
		{
			buttonText: 'Add New',
			buttonAction: () => navigate('/form-instances/' + selectedTemplate.id + '/new')
		}
	]

  if(!!selectedTemplate) {
    return (
      <Fragment>
        <Box display="block" displayPrint="none">
  				<AppNav />
          <Container  maxWidth={false} className={classes.container}>

            <PageHeader
              headerText={selectedTemplate.name}
              headerButtons={headerButtons}
            />
        
            <FormInstanceTable
              formInstances={instancesByTemplate}
              onSetOrderByParams={setOrderByParams}
              onClone={handleCloneClick}
              onSelect={handleSelect}
              onDelete={handleDeleteFormInstanceClick}
              onPrint={handlePrint}
            />

            
            <Link className={classes.navButton} to="/form-instances">
              <Button className={classes.actionButton}>Back</Button> 
            </Link>

          </Container>

  				<ConfirmDeleteDialog 
  	        open={deleteDialogOpen}
  					item={'this entry'}
  					itemType={'Entry'}
  					onClose={handleDeleteDialogClose}
  					onConfirm={handleDeleteFormInstanceConfirm}
  				/>
          <CloneInstanceDialog
              open={isCloneInstanceDialogOpen}
              onClose={handleCloneDialogClose}
              onSubmit={handleClone}
              originalInstance={selectedInstance}
              />
        </Box>
        <Box display="none" displayPrint="block">
          <PrintFormInstance 
            selectedTemplate={selectedTemplate}
            selectedFormInstance={selectedInstance}
          />
        </Box>
			</Fragment>
    )
  } else {
    return null;
  }
}
import { v4 as uuidv4 } from 'uuid';
import PurchaseOrderStatus from './purchaseOrderStatus';
import Address from '../../Core/Models/address';
import PaymentTerms from '../Models/paymentTerms';

export default class PurchaseOrder {
    constructor(obj) {
        if(!obj) {
            obj = {}
        }

        this.id = obj.id || uuidv4();

        this.tenantId = obj.tenantId;
        
        this.status = obj.status || PurchaseOrderStatus.DRAFT;

        this.supplierId = obj.supplierId || '';

        this.supplierName = obj.supplierName || '';

        this.supplierPhoneNumber = obj.supplierPhoneNumber || null;

        this.supplierAddress = obj.supplierAddress || new Address();

        this.lineItems = obj.lineItems || [];

        this.number = obj.number || null;

        this.shipToContactName = obj.shipToContactName || '';

        this.shipToPhoneNumber = obj.shipToPhoneNumber || null;

        this.shipToAddress = obj.shipToAddress || new Address();

        this.paymentTerms = obj.paymentTerms || PaymentTerms.NET30;

        this.shippingCost = obj.shippingCost || 0;

        this.tax = obj.tax || 0;

        this.total = 0;  // todo: calculate from line items

        this.createdBy = obj.createdBy;

        this.lastUpdatedBy = obj.lastUpdatedBy;
    }
}
import { createSlice } from '@reduxjs/toolkit';
import { systemCategoryActionsAsync } from './SystemCategoryActionsAsync';
import * as utils from '../../../../Utilities/reducerUtils';
const { 
    loadSystemCategories,
    createSystemCategory,
    deleteSystemCategory,
} = systemCategoryActionsAsync;


export const SystemCategorySlice = createSlice({
    name: 'systemCategories',
    initialState: {
        list: [],
        selectedCategoryId: null,
    },
    reducers: {
        selectCategory(state, action) {
            state.selectedCategoryId = action.payload.id;
        }
    },
    extraReducers: {
        [loadSystemCategories.fulfilled]: (state, action) => {
            state.list = action.payload;
        },
        [createSystemCategory.fulfilled]: (state, action) => {
            const created = action.payload;
            state.list = [...state.list, created]
        },
        [deleteSystemCategory.fulfilled]: (state, action) => {
            const deleted = action.payload;
            state.list = utils.deleteItemFromList(state.list, deleted);
        },
    }
});

export const { 
    selectCategory, 
} = SystemCategorySlice.actions;

export default SystemCategorySlice.reducer;
import * as yup from 'yup';
import FormElementUtils from './formElementUtils';

export default class ValidationUtils {

  static getyupType(elementType) {
    const { elementTypes } = FormElementUtils;

    switch(elementType) {
      case elementTypes.checkBox:
        return yup.boolean();
      case elementTypes.dropDown:
      case elementTypes.multipleChoice:
      case elementTypes.yesOrNo:
      case elementTypes.textInput:
      case elementTypes.vesselSelector:
			case elementTypes.userSelector:
      case elementTypes.userEsignSelector:
        return yup.string();
      case elementTypes.datePicker:
        return yup.date().typeError('Invalid Date');
      default:
        return null;
    }
  }

  static getyupSchema(elements) {
    const schema = {};

    elements.forEach(element => {
      const name = element.elementName ? element.elementName : element.name;
      const type = element.elementType ? element.elementType : element.type;

      if(element.required) {
        schema[name] = this.getyupType(type).required('This is required');
      } else {
        schema[name] = this.getyupType(type);
      }
    });

    return yup.object().shape(schema);
  }

  static getInitialValues(elements) {
    let initialValues = {};

    elements.forEach(element => {
      const name = element.elementName ? element.elementName : element.name;
      const type = element.elementType ? element.elementType : element.type;

      if(type === FormElementUtils.elementTypes.checkBox) {
        initialValues[name] = element.checked || false;
      } else if(type === FormElementUtils.elementTypes.datePicker) {
        initialValues[name] = element.value || new Date();
      } else {
        initialValues[name] = element.value || '';
      }
    });

    return initialValues;
  }

}



import { v4 as uuidv4 } from 'uuid';

export default class Paragraph {
    constructor(obj) {
        if(!obj) {
            obj = {}
        }

        this.id = obj.id || uuidv4(); 

        this.type = 'paragraph';

        this.name = obj.name || null;

        this.position = obj.position || null;

        this.text = obj.text || 'This is a paragraph.  You can alter it to say whatever you like.';
    }
}
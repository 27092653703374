import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import DateUtils from '../../Utilities/dateUtils';
import SortIcon from '../SortIcon';
import DeleteIconButton from '../DeleteIconButton';
import EditIconButton from '../EditIconButton';
import CloneIconButton from '../CloneIconButton';
import PrintIconButton from '../PrintIconButton';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    marginBottom: 16,
    borderRadius: 'inherit',
  },
  stripedTableRow: theme.stripedTableRow,
  tableHeader: theme.tableHeader,
	tableRow: theme.tableRow,
}));


export default function FormInstanceTable(props) {
    const classes = useStyles();

    const { 
      formInstances, 
      onDelete, 
      onSelect, 
      onClone, 
      onPrint,
      onSetOrderByParams 
    } = props;

    if (!!formInstances && formInstances.length > 0) {
    return (
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell>
	                Log Date
	                <SortIcon
	                  onSetOrderByParams={onSetOrderByParams}
	                  sortParam='date'/>
              </TableCell>
              <TableCell>
	                Log Time
	                <SortIcon
	                  onSetOrderByParams={onSetOrderByParams}
	                  sortParam='date'/>
              </TableCell>
              <TableCell>
	                Logged By
	                <SortIcon
	                  onSetOrderByParams={onSetOrderByParams}
	                  sortParam='loggedBy'/>
              </TableCell>
              <TableCell align="center">Print</TableCell>
              <TableCell align="center">Clone</TableCell>
              <TableCell align="center">Edit</TableCell>
              <TableCell align="center">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formInstances.map((instance) => (
              <TableRow key={instance.id} className={[classes.stripedTableRow, classes.tableRow].join(' ')} >
              <TableCell width="10%" scope="row">
                  {DateUtils.getDateString(instance.createdAt)}
              </TableCell>       
              <TableCell width="10%" scope="row">
                  {DateUtils.getTime(instance.createdAt)}
              </TableCell>
                <TableCell width="60%" scope="row">
                  {instance.createdBy}
              </TableCell>
              <TableCell align="center">
								<PrintIconButton onClick={() => onPrint(instance)}/>
              </TableCell>    
              <TableCell align="center">
								<CloneIconButton onClick={() => onClone(instance)}/>
              </TableCell>      
              <TableCell align="center">   
								<EditIconButton onClick={() => onSelect(instance)} />         
              </TableCell>               
              <TableCell align="center">
								<DeleteIconButton onClick={() => onDelete(instance)} />
              </TableCell>
            </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  } else if (!!formInstances && formInstances.length === 0) {
    return (      
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="empty table">
          <TableBody>
						<TableRow>
							<TableCell>
            		No past entries have been filled out for this form or checklist.
							</TableCell>
						</TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else return null;
}
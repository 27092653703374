import { 
  Container, 
  Paper,
  makeStyles, 
  Grid, 
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { formInstanceSelectors } from './FormInstanceState/FormInstanceSelectors';
import { templateSelectors } from '../Admin/CustomFormBuilder/CustomFormTemplates/TemplateState/TemplateSelectors';
import { templateActions } from '../Admin/CustomFormBuilder/CustomFormTemplates/TemplateState/TemplateSlice';
import { formInstanceActions } from './FormInstanceState/FormInstanceSlice';
import { formInstanceActionsAsync } from './FormInstanceState/FormInstanceActionsAsync';
import FormElementProvider from '../Core/FormElementProvider/FormElementProvider';
import { Formik, Form } from 'formik';
import ValidationUtils from '../Utilities/validationUtils';
import FormInstanceUtils from './FormInstanceUtils/formInstanceUtils';
import { navigate } from '@reach/router';
import AppNav from '../Core/AppNav';
import { Fragment } from 'react';
import PageHeader from '../Core/PageHeader';
import FormInstanceActionButtons from './FormInstanceActionButtons/FormInstanceActionButtons';

const useStyles = makeStyles(theme => ({
  formRender: theme.formRender,
  centerTitle: theme.centerTitle,
	container: theme.container,
}));


export default function EditFormInstancePage(props) {
  const dispatch = useDispatch();
  const { instanceId, templateId } = props;
  const classes = useStyles();

  let customFormSchema = {};
  let customFormInitialValues = {};

  const selectedTemplate = useSelector(templateSelectors.templateSelectorById);
  if(!selectedTemplate) {
    dispatch(templateActions.selectTemplate(templateId));
  }

  const selectedFormInstance = useSelector(formInstanceSelectors.instanceSelectorById);
  if(!selectedFormInstance) {
    dispatch(formInstanceActions.selectFormInstance(instanceId));
  }


  if(!!selectedFormInstance) {
    customFormSchema = ValidationUtils.getyupSchema(selectedFormInstance.formElementInstances)

    customFormInitialValues = ValidationUtils.getInitialValues(selectedFormInstance.formElementInstances);
  }

  const handleSubmit = values => {

    const updatedElementInstances = FormInstanceUtils.mapValuesToFormElements(values, selectedFormInstance.formElementInstances);

    const params = {
      updatedFormInstance: {
        ...selectedFormInstance,
        formElementInstances: updatedElementInstances,
      },
    }

    dispatch(formInstanceActionsAsync.updateInstance(params)).then(response => {
      const { templateId } = response.payload;
      navigate("/form-instances/" + templateId);
    });
    
  }

  const handlePrint = () => {
    window.print();
  }

  const getFormElement = (instance, elements) => {
    return elements.find(element => instance.elementId === element.id);
  }

	const handleCancel = () => {
		navigate('/form-instances/' + selectedTemplate.id);
	}

  if(!!selectedFormInstance && !!selectedTemplate) {
    return (
			<Fragment>
				<AppNav />
        <Container   maxWidth={false} className={classes.container}>
					<PageHeader headerText="Edit Form" />

          <Paper elevation={4} className={classes.formRender}>
            <Formik 
              initialValues={customFormInitialValues}
              validationSchema={customFormSchema}
              onSubmit={ async (values) => {
                handleSubmit(values);
              }}>{({
                isValid, 
                dirty,
                values,
                errors,
                touched,
                handleChange, 
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldError
              }) => {
                return (
                  <Form id="form-instance" noValidate autoComplete="off" onSubmit={handleSubmit}>

                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h3" className={classes.centerTitle}>{selectedTemplate.name}</Typography>
                      </Grid>
                      {selectedFormInstance.formElementInstances.map((instance) => (
                        <Grid item xs={12} key={instance.elementId}>
                          <FormElementProvider
                            formElement={getFormElement(instance, selectedTemplate.formElements)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            setFieldError={setFieldError}
                            touched={touched[instance.elementName]}
                            value={values[instance.elementName]}
                            error={errors[instance.elementName]}
                            name={instance.elementName}
                            />
                        </Grid>
                      ))}
                      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

										<FormInstanceActionButtons
											disabled={!(isValid && dirty)}
											onPrint={handlePrint}
											onCancel={handleCancel}
										/>

                  </Grid>
                </Form>
              )}}  
            </Formik>
          </Paper>
        </Container>
			</Fragment>
    )
  } else {
    return null;
  }
}
import { 
    Checkbox,
    FormControl, 
    FormControlLabel, 
    FormHelperText,
} from '@material-ui/core';
import ElementLabel from './ElementLabel';

export default function CheckBox(props) {
    const { 
        error, 
        name, 
        onChange, 
        value,
        formElement: {
            labelText = {}, 
            required = {},
        }, 
    } = props;


    return (
      <FormControl component="fieldset" error={Boolean(error)}>

          <ElementLabel 
              component='legend'
              required={required}/>

          <FormControlLabel
              name={name}
              onChange={onChange}
              control={<Checkbox checked={value} color="default" />}
              label={labelText}
              />
          <FormHelperText id="checklist-text">{error}</FormHelperText>
      </FormControl>      
    )
}
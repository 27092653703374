import { Fragment } from 'react';
import PropUtils from '../../../../Utilities/propUtils';
import { FormControlLabel, Checkbox } from '@material-ui/core';

export default function FormElementDetailCheckboxes(props) {
    const { formElement, onChange, values } = props;

    const getCheckboxValues = (formElement) => {
        const checkboxes = []
        for(let i in formElement) {
            if(typeof formElement[i] === 'boolean') {
                checkboxes.push({key: i, value: formElement[i]});
            }
        }
        return checkboxes;
    }

    const checkboxes = getCheckboxValues(formElement);

    return (
        <Fragment>
            { checkboxes.map(checkbox => (
                <FormControlLabel
                    key={checkbox.key}
                    label={PropUtils.getDisplayFormat(checkbox.key)}
                    control={
                    <Checkbox
                        color="default"
                        checked={values[checkbox.key]}
                        onChange={onChange}
                        name={checkbox.key}
                    />} 
                />
            ))}
        </Fragment>
    )
}
import {
    Chip,
    makeStyles,
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    tableHeader: theme.tableHeader,
		stripedTableRowClickable: theme.stripedTableRowClickable,
}));

export default function NestedFormInstanceTableRow(props) {
    const classes = useStyles();
    const { template, onSelect } = props;

    const instanceCount = useSelector(state => state.formInstances.list.filter(instance => instance.templateId === template.id).length);

    return (
        <TableRow className={classes.stripedTableRowClickable} onClick={() => onSelect(template)}>
            <TableCell width="90%" component="th" scope="row">
                <Typography component="div">
                    {template.name}{" "}
                        <Chip size="small" label={instanceCount} />
                </Typography>
            </TableCell>
        </TableRow>
    )
}
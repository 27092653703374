import CheckBox from '../Models/checkBox';
import DatePicker from '../Models/datePicker';
import DropDown from '../Models/dropDown';
import Header from '../Models/header';
import MultipleChoice from '../Models/multipleChoice';
import YesOrNo from '../Models/yesOrNo';
import Paragraph from '../Models/paragraph';
import TextInput from '../Models/textInput';
import VesselSelector from '../Models/vesselSelector';
import UserSelector from '../Models/userSelector';
import UserEsignSelector from '../Models/userEsignSelector';

export default class FormElementUtils {
    static positionInterval = 10000;

    static elementTypes = Object.freeze({
        checkBox: 'checkBox',
        datePicker: 'datePicker',
        dropDown: 'dropDown',
        header: 'header',
        paragraph: 'paragraph',
        multipleChoice: 'multipleChoice',
        yesOrNo: 'yesOrNo',
        textInput: 'textInput',
        vesselSelector: 'vesselSelector',
        userSelector: 'userSelector',
        userEsignSelector: 'userEsignSelector',
    });

    static castToRelevantClass(element) {
        const { type } = element;

         switch (type) {
            case this.elementTypes.checkBox:
                return new CheckBox(element);
            case this.elementTypes.datePicker:
                return new DatePicker(element);
            case this.elementTypes.dropDown:
                return new DropDown(element);
            case this.elementTypes.header:
                return new Header(element);
            case this.elementTypes.paragraph:
                return new Paragraph(element);
            case this.elementTypes.multipleChoice:
                return new MultipleChoice(element);
            case this.elementTypes.yesOrNo:
                return new YesOrNo(element);
            case this.elementTypes.textInput:
                return new TextInput(element);
            case this.elementTypes.vesselSelector:
                return new VesselSelector(element);
            case this.elementTypes.userSelector:
                return new UserSelector(element);
            case this.elementTypes.userEsignSelector:
                return new UserEsignSelector(element);
            default:
                return null;
        }
    }

    static getNewElementPositionOnAdd(params) {
        const { getState } = params;
        const selectedCustomTemplateId = getState().templates.selectedTemplateId;
        const selectedSystemTemplateId = getState().systemTemplates.selectedTemplateId;
        const customTemplates = getState().templates.list;
        const systemTemplates = getState().systemTemplates.list;
        const allTemplates = customTemplates.concat(systemTemplates);
        let selectedTemplateId = null;

        if(selectedCustomTemplateId !== null) {
            selectedTemplateId = selectedCustomTemplateId;
        } else if(selectedSystemTemplateId !== null) {
            selectedTemplateId = selectedSystemTemplateId;
        }

        const formElements = allTemplates.find(template => template.id === selectedTemplateId).formElements;
        let lastPosition;

        if(formElements.length > 0) {
            lastPosition = formElements[formElements.length -1].position;
        } else {
            lastPosition = 0;
        }

        return lastPosition + this.positionInterval;
    }

    static getNewElementPositionOnDrag(params) {
        const { template, destination } = params;
        const { formElements } = template;
        const destinationElementPosition = formElements.find(element => element.id === destination.droppableId).position;

        if(destination.index === 0) {
            return Math.floor(destinationElementPosition / 2);
        } else if(destination.index === (formElements.length)) {
            return destinationElementPosition + this.positionInterval;
        } else {
            const params = {
                destinationIndex: destination.index,
                formElements: formElements,
            }
            return this.calculateNewPositionBetwenTwoElements(params);
        }

    }

    static calculateNewPositionBetwenTwoElements(params) {
        const { destinationIndex, formElements } = params;

        const precedingItemPosition = formElements[destinationIndex - 1].position;

        const nextItemPosition = formElements[destinationIndex].position;

        return Math.floor(((nextItemPosition - precedingItemPosition) /2) + precedingItemPosition);

    }

    static reorderElementlist(params) {
        const { elementList, sourceIndex, destinationIndex} = params;

        const result = Array.from(elementList);
        const [removed] = result.splice(sourceIndex, 1);
        result.splice(destinationIndex, 0, removed);
    
        return result;
    }
}
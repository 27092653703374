import { Tooltip, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

export default function EditIconButton(props) {
	const { onClick } = props;

	return (
		<Tooltip title="Edit">
			<IconButton
				size="small" 
				onClick={onClick}>
				<EditIcon />
			</IconButton>
		</Tooltip>
	)
}
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomFormTemplate = /* GraphQL */ `
  mutation CreateCustomFormTemplate(
    $input: CreateCustomFormTemplateInput!
    $condition: ModelCustomFormTemplateConditionInput
  ) {
    createCustomFormTemplate(input: $input, condition: $condition) {
      id
      name
      tenantId
      type
      categoryId
      categoryName
      createdBy
      lastUpdatedBy
      formElements {
        id
        type
        name
        choices
        position
        labelText
        text
        size
        required
        multiLine
        numbersOnly
        displayInRow
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCustomFormTemplate = /* GraphQL */ `
  mutation UpdateCustomFormTemplate(
    $input: UpdateCustomFormTemplateInput!
    $condition: ModelCustomFormTemplateConditionInput
  ) {
    updateCustomFormTemplate(input: $input, condition: $condition) {
      id
      name
      tenantId
      type
      categoryId
      categoryName
      createdBy
      lastUpdatedBy
      formElements {
        id
        type
        name
        choices
        position
        labelText
        text
        size
        required
        multiLine
        numbersOnly
        displayInRow
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCustomFormTemplate = /* GraphQL */ `
  mutation DeleteCustomFormTemplate(
    $input: DeleteCustomFormTemplateInput!
    $condition: ModelCustomFormTemplateConditionInput
  ) {
    deleteCustomFormTemplate(input: $input, condition: $condition) {
      id
      name
      tenantId
      type
      categoryId
      categoryName
      createdBy
      lastUpdatedBy
      formElements {
        id
        type
        name
        choices
        position
        labelText
        text
        size
        required
        multiLine
        numbersOnly
        displayInRow
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCustomFormCategory = /* GraphQL */ `
  mutation CreateCustomFormCategory(
    $input: CreateCustomFormCategoryInput!
    $condition: ModelCustomFormCategoryConditionInput
  ) {
    createCustomFormCategory(input: $input, condition: $condition) {
      id
      name
      tenantId
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCustomFormCategory = /* GraphQL */ `
  mutation UpdateCustomFormCategory(
    $input: UpdateCustomFormCategoryInput!
    $condition: ModelCustomFormCategoryConditionInput
  ) {
    updateCustomFormCategory(input: $input, condition: $condition) {
      id
      name
      tenantId
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCustomFormCategory = /* GraphQL */ `
  mutation DeleteCustomFormCategory(
    $input: DeleteCustomFormCategoryInput!
    $condition: ModelCustomFormCategoryConditionInput
  ) {
    deleteCustomFormCategory(input: $input, condition: $condition) {
      id
      name
      tenantId
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFormInstance = /* GraphQL */ `
  mutation CreateFormInstance(
    $input: CreateFormInstanceInput!
    $condition: ModelFormInstanceConditionInput
  ) {
    createFormInstance(input: $input, condition: $condition) {
      id
      type
      tenantId
      categoryId
      categoryName
      templateId
      templateName
      createdBy
      lastUpdatedBy
      formElementInstances {
        elementId
        elementName
        elementType
        required
        value
        checked
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateFormInstance = /* GraphQL */ `
  mutation UpdateFormInstance(
    $input: UpdateFormInstanceInput!
    $condition: ModelFormInstanceConditionInput
  ) {
    updateFormInstance(input: $input, condition: $condition) {
      id
      type
      tenantId
      categoryId
      categoryName
      templateId
      templateName
      createdBy
      lastUpdatedBy
      formElementInstances {
        elementId
        elementName
        elementType
        required
        value
        checked
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteFormInstance = /* GraphQL */ `
  mutation DeleteFormInstance(
    $input: DeleteFormInstanceInput!
    $condition: ModelFormInstanceConditionInput
  ) {
    deleteFormInstance(input: $input, condition: $condition) {
      id
      type
      tenantId
      categoryId
      categoryName
      templateId
      templateName
      createdBy
      lastUpdatedBy
      formElementInstances {
        elementId
        elementName
        elementType
        required
        value
        checked
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPurchaseOrder = /* GraphQL */ `
  mutation CreatePurchaseOrder(
    $input: CreatePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    createPurchaseOrder(input: $input, condition: $condition) {
      id
      tenantId
      supplierId
      supplierName
      supplierAddress {
        street1
        street2
        city
        state
        postalCode
        country
      }
      supplierPhoneNumber
      status
      lineItems {
        id
        item
        lineNumber
        quantity
        status
        unitCost
      }
      number
      shipToContactName
      shipToPhoneNumber
      shipToAddress {
        street1
        street2
        city
        state
        postalCode
        country
      }
      paymentTerms
      shippingCost
      tax
      total
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePurchaseOrder = /* GraphQL */ `
  mutation UpdatePurchaseOrder(
    $input: UpdatePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    updatePurchaseOrder(input: $input, condition: $condition) {
      id
      tenantId
      supplierId
      supplierName
      supplierAddress {
        street1
        street2
        city
        state
        postalCode
        country
      }
      supplierPhoneNumber
      status
      lineItems {
        id
        item
        lineNumber
        quantity
        status
        unitCost
      }
      number
      shipToContactName
      shipToPhoneNumber
      shipToAddress {
        street1
        street2
        city
        state
        postalCode
        country
      }
      paymentTerms
      shippingCost
      tax
      total
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePurchaseOrder = /* GraphQL */ `
  mutation DeletePurchaseOrder(
    $input: DeletePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    deletePurchaseOrder(input: $input, condition: $condition) {
      id
      tenantId
      supplierId
      supplierName
      supplierAddress {
        street1
        street2
        city
        state
        postalCode
        country
      }
      supplierPhoneNumber
      status
      lineItems {
        id
        item
        lineNumber
        quantity
        status
        unitCost
      }
      number
      shipToContactName
      shipToPhoneNumber
      shipToAddress {
        street1
        street2
        city
        state
        postalCode
        country
      }
      paymentTerms
      shippingCost
      tax
      total
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSupplier = /* GraphQL */ `
  mutation CreateSupplier(
    $input: CreateSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    createSupplier(input: $input, condition: $condition) {
      id
      tenantId
      name
      billingAddress {
        street1
        street2
        city
        state
        postalCode
        country
      }
      isActive
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSupplier = /* GraphQL */ `
  mutation UpdateSupplier(
    $input: UpdateSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    updateSupplier(input: $input, condition: $condition) {
      id
      tenantId
      name
      billingAddress {
        street1
        street2
        city
        state
        postalCode
        country
      }
      isActive
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSupplier = /* GraphQL */ `
  mutation DeleteSupplier(
    $input: DeleteSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    deleteSupplier(input: $input, condition: $condition) {
      id
      tenantId
      name
      billingAddress {
        street1
        street2
        city
        state
        postalCode
        country
      }
      isActive
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSystemFormTemplate = /* GraphQL */ `
  mutation CreateSystemFormTemplate(
    $input: CreateSystemFormTemplateInput!
    $condition: ModelSystemFormTemplateConditionInput
  ) {
    createSystemFormTemplate(input: $input, condition: $condition) {
      id
      name
      type
      categoryId
      categoryName
      formElements {
        id
        type
        name
        choices
        position
        labelText
        text
        size
        required
        multiLine
        numbersOnly
        displayInRow
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSystemFormTemplate = /* GraphQL */ `
  mutation UpdateSystemFormTemplate(
    $input: UpdateSystemFormTemplateInput!
    $condition: ModelSystemFormTemplateConditionInput
  ) {
    updateSystemFormTemplate(input: $input, condition: $condition) {
      id
      name
      type
      categoryId
      categoryName
      formElements {
        id
        type
        name
        choices
        position
        labelText
        text
        size
        required
        multiLine
        numbersOnly
        displayInRow
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSystemFormTemplate = /* GraphQL */ `
  mutation DeleteSystemFormTemplate(
    $input: DeleteSystemFormTemplateInput!
    $condition: ModelSystemFormTemplateConditionInput
  ) {
    deleteSystemFormTemplate(input: $input, condition: $condition) {
      id
      name
      type
      categoryId
      categoryName
      formElements {
        id
        type
        name
        choices
        position
        labelText
        text
        size
        required
        multiLine
        numbersOnly
        displayInRow
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSystemFormCategory = /* GraphQL */ `
  mutation CreateSystemFormCategory(
    $input: CreateSystemFormCategoryInput!
    $condition: ModelSystemFormCategoryConditionInput
  ) {
    createSystemFormCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSystemFormCategory = /* GraphQL */ `
  mutation UpdateSystemFormCategory(
    $input: UpdateSystemFormCategoryInput!
    $condition: ModelSystemFormCategoryConditionInput
  ) {
    updateSystemFormCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSystemFormCategory = /* GraphQL */ `
  mutation DeleteSystemFormCategory(
    $input: DeleteSystemFormCategoryInput!
    $condition: ModelSystemFormCategoryConditionInput
  ) {
    deleteSystemFormCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
    }
  }
`;

import { createSelector } from 'reselect';

const categorySelectorAll = createSelector(
    state => state.categories.list,
    (categories) => categories,
)

const categorySelectorById = createSelector(
    state => state.categories.list,
    state => state.categories.selectedCategoryId,
    (categories, selectedCategoryId) => {
        if (!!(categories && categories.length) && selectedCategoryId) {
            return categories.find(category => category.id === selectedCategoryId)
        }
        return null;
    }
)

export const categorySelectors = {
    categorySelectorAll,
    categorySelectorById,
}
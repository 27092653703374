import { 
  Button, 
  Typography, 
  makeStyles, 
  Grid,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  actionsRight: theme.actionsRight,
  actionButton: theme.actionButton,
  pageHeader: theme.pageHeader,
}));

export default function PageHeader(props) {
  const classes = useStyles();

  const { headerText, headerButtons } = props;

  return (
    <Grid container className={classes.pageHeader} alignItems="center" justifyContent="space-between">

      <Grid item sm={6} xs={12}>
        <Typography variant="h2">
          { headerText }
        </Typography>
      </Grid>

			<Grid  className={classes.actionsRight} item>
				{ !!headerButtons && headerButtons.map((button, index) => (
	          <Button 
							key={index}
	            onClick={button.buttonAction} 
	            className={classes.actionButton}>
	            { button.buttonText }
	          </Button>
	  
				))}
			</Grid>


    </Grid>
  )
}
import { 
    IconButton,
    List, 
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    makeStyles,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useSelector } from 'react-redux';
import { systemTemplateSelectors } from '../../SystemFormTemplates/SystemTemplateState/SystemTemplateSelectors';

const useStyles = makeStyles(theme => ({
    manageDialogList: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: theme.borderRadius,
    },
}));

export default function SystemFormCategoryList(props) {
    const { categories, onDeleteClick } = props;
    const classes = useStyles();
    const templatesByCategory = useSelector(systemTemplateSelectors.systemTemplateSelectorPerCategory);

    return (
        <List dense className={classes.manageDialogList}>
        { categories.length > 0 && categories.map(category => (
            <ListItem key={category.id}>
                <ListItemText
                    primary={category.name} />
                <ListItemSecondaryAction>
                    <IconButton 
                    disabled={templatesByCategory.find(iCategory => iCategory.id === category.id).templates.length > 0}
                    onClick={() => { onDeleteClick(category) }}>
                        <DeleteForeverIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            ))}
        { categories.length === 0 && 
            <ListItem>
                <ListItemText
                primary="No categories have been added yet. " />
            </ListItem>
        }
        </List> 
    )
}
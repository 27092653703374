import { makeStyles } from '@material-ui/core';
import { useDrag } from 'react-dnd';
import ItemTypes from '../FormBuilderUtils/itemTypes';
import PropUtils from '../../../Utilities/propUtils';

const useStyles = makeStyles(theme => ({
    menuItem: {
        padding: 4,
        border: `2px dotted ${theme.palette.secondary.main}`,
        borderRadius: theme.borderRadius,
        margin: '4px 0',
        '&:hover': {
            cursor: 'pointer',
            border: `2px dotted #000`,

        },
        display: 'flex',
        justifyContent: 'space-between',
    }
}));

export default function FormBuilderMenuItem(props) {
    const classes = useStyles();
    const { type, onAddClick } = props;

    const [, drag] = useDrag({
        type: ItemTypes.MENU_ELEMENT,
        item: { type }, 
    });

    return (
        <div ref={drag} onClick={() => onAddClick(type)} className={classes.menuItem}>
            <div>{PropUtils.getDisplayFormat(type)}</div>
            <div>+</div>
        </div>
    )
}
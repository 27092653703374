import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    Grid,
} from '@material-ui/core';
import FormBuilderElementProvider from '../../../Core/FormElementProvider/FormElementProvider';


export default function FormBuilderPreviewDialog(props) {
    const { open, onClose, template } = props; 

    if(!!template) {
        return (
            <Dialog
            open={open}
            onClose={onClose}
            scroll='paper'
            fullWidth={true}
            maxWidth={'lg'}
            aria-labelledby="Form Template Preview" 
            aria-describedby="Preview Form Template">
                <DialogTitle>Preview: { template.name }</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                    { template.formElements.map(element => (
                        <Grid key={element.id} item xs={12}>
                            <FormBuilderElementProvider formElement={element}/>
                        </Grid>
                    ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    } else {
        return null;
    }
}
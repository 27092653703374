import { v4 as uuidv4 } from 'uuid';

export default class TextInput {
    constructor(obj) {
        if(!obj) {
            obj = {}
        }

        this.id = obj.id || uuidv4(); 

        this.type = 'textInput';

        this.name = obj.name || null;

        this.position = obj.position || null;

        this.required = obj.required || false;

        this.labelText = obj.labelText || 'Text Input';

        this.multiLine = obj.multiLine || false;
        
        this.numbersOnly = obj.numbersOnly || false;
    }
}
import { makeStyles } from '@material-ui/core';
import { useDrop } from 'react-dnd';
import ItemTypes from '../FormBuilderUtils/itemTypes';
import FormBuilderCanvasElement from './FormBuilderCanvasElement/FormBuilderCanvasElement';
import { Droppable } from "react-beautiful-dnd";


const useStyles = makeStyles(theme => ({
    canvas: {
        height: '100%',
        borderRadius: `0px 0 0 ${theme.borderRadius}px`,
        borderLeft: `1px solid ${theme.palette.primary.main}`,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        overflow: 'hidden',
        boxSizing: 'border-box',
    },
    canvasActions: {
        textAlign: 'right',
    },
    canvasBody: {
        padding: 5,
        height: '100%',
        overflowY: 'scroll',
    },
}));

export default function FormBuilderCanvas(props) {
    const classes = useStyles();
    const { 
        localElements,
        onDrop,  
        onDelete, 
        onEditClick, 
     } = props;

    const [, drop] = useDrop({
        accept: ItemTypes.MENU_ELEMENT,
        drop: (value) => {
            const { type } = value;
            onDrop(type)
        },
        collect: (monitor) => {},
      });


    return (
        <div ref={drop} className={classes.canvas}>
            <div elevation={0} className={classes.canvasBody}>
                { !!localElements && localElements.map((formElement, index) => (
                    <Droppable key={formElement.id} droppableId={formElement.id}> 
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                        <FormBuilderCanvasElement 
                            index={index}
                            key={formElement.id}
                            formElement={formElement}
                            onDelete={onDelete}
                            onEditClick={onEditClick} />
                        <div>{provided.placeholder}</div>
                        </div>
                        )}
                    </Droppable>
                ))}
            </div>
        </div>
    ) 
}

import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';

const projectColors = {
  primaryColor: 'rgb( 5, 28, 44 )',
  secondaryColor: '#fff',
  lightGrey: '#f3f3f3',
  darkBlue: '#051C2C',
	headerBlue: 'rgb( 9, 51, 82 )',
  borderGrey: '#7F7F7F',
  dividerGrey: '#5e5e5e80',
  errorRed: '#D22B2B',
  buttonBlue: '#2353C5',
  hoverGreen: '#7FBC02',
  loginLinkBlue: '#5e7999'
}


const theme = createMuiTheme({
  palette: {
    primary: {
      main: projectColors.primaryColor,
    },
    secondary: {
      main: projectColors.secondaryColor,
    },
		lightGrey: projectColors.lightGrey,

  },
  typography: {
	fontFamily: "'Inter', sans-serif",
    h2: { 
      fontSize: 22,
      fontWeight: 400,
      marginBottom: 16,
	
    },
    h3: {
      fontSize: 18,
      fontWeight: 400,
      marginTop: 8,
      marginBottom: 8,
    },
		h4: {
			fontSize: 16,
      fontWeight: 400,
      marginTop: 8,
      marginBottom: 8,
		},
  },
	actionButton: {
    cursor: 'pointer',
    marginBottom: 10,
    textTransform: 'none',
		fontWeight: 600,
		padding: '2px 4px',
		border: '1px solid' + projectColors.buttonBlue,
		color: projectColors.buttonBlue,
		backgroundColor: projectColors.secondaryColor,
    '&:hover': {
			color: projectColors.secondaryColor,
			backgroundColor: projectColors.buttonBlue,
    },
		'&:disabled': {
			border: '1px solid' + projectColors.lightGrey,
		}
  },
  actionsRight: {
    textAlign: 'right',
		'& button': {
			marginLeft: 5
		}
  },
  alert: {
    marginBottom: 10,
    maxWidth: 490,
    textAlign: 'center'
  },
  borderRadius: 4,
  centerTitle: {
    textAlign: 'center',
  },
	container: {
		maxWidth: 'calc(44px + 1366px + 44px)',
		paddingRight: 44,
		paddingLeft: 44,
		marginTop: 121,
	},
  forgotPassword: {
    color: projectColors.loginLinkBlue,
    textDecoration: 'none',
  },
  loginButtonRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  loginTileLogo: {
    width: '100%'
  },
  loginTileLogoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 35,
  },
	navDivider: {
		height: 44,
		backgroundColor: projectColors.dividerGrey,
	},
	navDropDown: {
		position: 'relative',
    display: 'inline-block',
    cursor: 'pointer',
    right: '10px',
		'& a': {
			fontSize: '15px',
			padding: 0,
			margin: '5px 0px',
			textDecoration: 'none',
			color: projectColors.secondaryColor,
		},
		'& a:hover': {
			color: projectColors.hoverGreen,
			textDecoration: 'none',
		},
	},
	navDropDownContent: {
		display: 'none',
    position: 'absolute',
    textAlign: 'center',
    color: projectColors.secondaryColor,
    background: projectColors.primaryColor,
    minWidth: '100%',
    boxShadow: '0px 5px 2px 0px rgba(0, 0, 0, 0.2)',
    padding: '5px 10px',
    zIndex: 1,
    right: -12,
		top: 41,
	},
	navLink: {
		color: projectColors.secondaryColor,
		'&:hover': {
			textDecoration: 'none',
			cursor: 'pointer',
		},
	},
	navLinkWrapper: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		paddingLeft: 28,
		'&:hover $navDropDownContent': {
			display: 'block',
		},
	},
  noNavBackground: {
    backgroundColor: projectColors.darkBlue,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noNavButton: {
    width: '40%',
    backgroundColor: '#0057bf',
    color: '#FFF',
    border: '1px solid #2353C5',
    cursor: 'pointer',
    height: 28,
    boxSizing: 'border-box',
    fontWeight: 'bold',
    borderRadius: 3,
    '& :hover': {
      backgroundColor: '#002B5E',
    }
  },
  noNavButtonRow: {
    display: 'flex',
    flexDirection: 'row',
    alignIems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  noNavInput: {
    width: '100%',
    height: 28,
    background: '#FFF',
    borderRadius: 3,
    padding: 5,
    border: '1px solid rgba(26, 54, 93, .2)',
    boxSizing: 'border-box',
  },
  noNavLabel: {
    display: 'flex',
    aligniIems: 'center',
    justifyContent: 'space-between',
    marginBottom: 5,
    '& span': {
      color: '#5e7999',
      fontSize: 15,
    }
  },
  noNavRow: {
    display: 'flex',
    flexDirection: 'column',
    alignIems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  noNavTile: {
    padding: 35,
    width: 400,
    backgroundColor: '#FFF',
    borderRadius: 5,
  },
  error: {
    color: projectColors.errorRed,
    fontSize: 12,
  },
	formRender: {
    padding: 16,
		marginBottom: 16,
	},
  minInputWidth: {
    minWidth: '25vw',
  },
  navButton: {
    textDecoration: 'none',
    color: projectColors.darkBlue,
  },
  pageHeader: {
    borderBottom: '1px solid ' + projectColors.borderGrey,
    marginBottom: 16,
  },
  printContainer: {
		maxWidth: 'calc(44px + 1366px + 44px)',
		paddingRight: 44,
		paddingLeft: 44,
  },
  profilePageText: {
    margin: 20,
    '& p': {
      marginBottom: 30,
      marginTop: 10
    },
    '7 h2': {
      fontWeight: 'bold'
    }
  },
	spacer: {
		width: '18.5%'
	},
  stripedTableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: projectColors.lightGrey,
    },
  },
	stripedTableRowClickable: {
    '&:nth-of-type(odd)': {
      backgroundColor: projectColors.lightGrey,
    },
		cursor: 'pointer'
  },
	tableButton: {
    cursor: 'pointer',
    textTransform: 'none',
		border: '1px solid' + projectColors.secondaryColor,
		color: projectColors.secondaryColor,
		backgroundColor: projectColors.primaryColor,
		'&:hover' : {
			color: projectColors.primaryColor,
			backgroundColor: projectColors.secondaryColor,
    }
  },
  tableHeader: {
    backgroundColor: projectColors.headerBlue,        
    '& th': {
      fontWeight: 700,
			fontSize: '14.5px',
			padding: '10px 5px',
			color: projectColors.secondaryColor,
    },
		'& th:first-child': {
			paddingLeft: 10,
		}
  },
	'& tr': {
		fontWeight: 400,
		fontSize: '14.5px',
		padding: '10px 5px',
	},
	'& td': {
		fontWeight: 400,
		fontSize: '14.5px',
		padding: '10px 5px',
	},
  tableRow: {
    '& > *': {
    	borderBottom: 'unset',
    },
		'& td': {
			fontWeight: 400,
			fontSize: '14.5px',
			padding: '10px 5px',
		},
		'& td:first-child': {
			paddingLeft: 10,
		}
	},
});

export default theme;

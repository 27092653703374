import { createSlice } from '@reduxjs/toolkit';
import { locationActionsAsync } from './LocationActionsAsync';

const { getLocationsByTenantId } = locationActionsAsync;

export const LocationSlice = createSlice({
    name: 'locations',
    initialState: {
        list: [],
    },
    reducers: {},
    extraReducers: {
        [getLocationsByTenantId.fulfilled]: (state, action) => {
            state.list = action.payload;
        },
    }
});

export default LocationSlice.reducer;

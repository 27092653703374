import { v4 as uuidv4 } from 'uuid';

export default class UserSelector {
    constructor(obj) {
        if(!obj) {
            obj = {}
        }
        this.id = obj.id || uuidv4(); 

        this.type = 'userSelector';

        this.name = obj.name || null;

        this.position = obj.position || null;

        this.choices = obj.choices || [];

        this.required = obj.required || false;

        this.labelText = obj.labelText || 'Users';
    }
}
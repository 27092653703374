/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomFormTemplate = /* GraphQL */ `
  query GetCustomFormTemplate($id: ID!) {
    getCustomFormTemplate(id: $id) {
      id
      name
      tenantId
      type
      categoryId
      categoryName
      createdBy
      lastUpdatedBy
      formElements {
        id
        type
        name
        choices
        position
        labelText
        text
        size
        required
        multiLine
        numbersOnly
        displayInRow
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCustomFormTemplates = /* GraphQL */ `
  query ListCustomFormTemplates(
    $filter: ModelCustomFormTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomFormTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        tenantId
        type
        categoryId
        categoryName
        createdBy
        lastUpdatedBy
        formElements {
          id
          type
          name
          choices
          position
          labelText
          text
          size
          required
          multiLine
          numbersOnly
          displayInRow
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const templatesByTenantId = /* GraphQL */ `
  query TemplatesByTenantId(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCustomFormTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    templatesByTenantId(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        tenantId
        type
        categoryId
        categoryName
        createdBy
        lastUpdatedBy
        formElements {
          id
          type
          name
          choices
          position
          labelText
          text
          size
          required
          multiLine
          numbersOnly
          displayInRow
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCustomFormCategory = /* GraphQL */ `
  query GetCustomFormCategory($id: ID!) {
    getCustomFormCategory(id: $id) {
      id
      name
      tenantId
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCustomFormCategorys = /* GraphQL */ `
  query ListCustomFormCategorys(
    $filter: ModelCustomFormCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomFormCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        tenantId
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const categorysByTenantId = /* GraphQL */ `
  query CategorysByTenantId(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCustomFormCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categorysByTenantId(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        tenantId
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFormInstance = /* GraphQL */ `
  query GetFormInstance($id: ID!) {
    getFormInstance(id: $id) {
      id
      type
      tenantId
      categoryId
      categoryName
      templateId
      templateName
      createdBy
      lastUpdatedBy
      formElementInstances {
        elementId
        elementName
        elementType
        required
        value
        checked
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listFormInstances = /* GraphQL */ `
  query ListFormInstances(
    $filter: ModelFormInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFormInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        tenantId
        categoryId
        categoryName
        templateId
        templateName
        createdBy
        lastUpdatedBy
        formElementInstances {
          elementId
          elementName
          elementType
          required
          value
          checked
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const formInstancesByTenantId = /* GraphQL */ `
  query FormInstancesByTenantId(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFormInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formInstancesByTenantId(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        tenantId
        categoryId
        categoryName
        templateId
        templateName
        createdBy
        lastUpdatedBy
        formElementInstances {
          elementId
          elementName
          elementType
          required
          value
          checked
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPurchaseOrder = /* GraphQL */ `
  query GetPurchaseOrder($id: ID!) {
    getPurchaseOrder(id: $id) {
      id
      tenantId
      supplierId
      supplierName
      supplierAddress {
        street1
        street2
        city
        state
        postalCode
        country
      }
      supplierPhoneNumber
      status
      lineItems {
        id
        item
        lineNumber
        quantity
        status
        unitCost
      }
      number
      shipToContactName
      shipToPhoneNumber
      shipToAddress {
        street1
        street2
        city
        state
        postalCode
        country
      }
      paymentTerms
      shippingCost
      tax
      total
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPurchaseOrders = /* GraphQL */ `
  query ListPurchaseOrders(
    $filter: ModelPurchaseOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchaseOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        supplierId
        supplierName
        supplierAddress {
          street1
          street2
          city
          state
          postalCode
          country
        }
        supplierPhoneNumber
        status
        lineItems {
          id
          item
          lineNumber
          quantity
          status
          unitCost
        }
        number
        shipToContactName
        shipToPhoneNumber
        shipToAddress {
          street1
          street2
          city
          state
          postalCode
          country
        }
        paymentTerms
        shippingCost
        tax
        total
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const purchaseOrdersByTenantId = /* GraphQL */ `
  query PurchaseOrdersByTenantId(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPurchaseOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    purchaseOrdersByTenantId(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        supplierId
        supplierName
        supplierAddress {
          street1
          street2
          city
          state
          postalCode
          country
        }
        supplierPhoneNumber
        status
        lineItems {
          id
          item
          lineNumber
          quantity
          status
          unitCost
        }
        number
        shipToContactName
        shipToPhoneNumber
        shipToAddress {
          street1
          street2
          city
          state
          postalCode
          country
        }
        paymentTerms
        shippingCost
        tax
        total
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSupplier = /* GraphQL */ `
  query GetSupplier($id: ID!) {
    getSupplier(id: $id) {
      id
      tenantId
      name
      billingAddress {
        street1
        street2
        city
        state
        postalCode
        country
      }
      isActive
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSuppliers = /* GraphQL */ `
  query ListSuppliers(
    $filter: ModelSupplierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuppliers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        name
        billingAddress {
          street1
          street2
          city
          state
          postalCode
          country
        }
        isActive
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const suppliersByTenantId = /* GraphQL */ `
  query SuppliersByTenantId(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSupplierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    suppliersByTenantId(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        name
        billingAddress {
          street1
          street2
          city
          state
          postalCode
          country
        }
        isActive
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSystemFormTemplate = /* GraphQL */ `
  query GetSystemFormTemplate($id: ID!) {
    getSystemFormTemplate(id: $id) {
      id
      name
      type
      categoryId
      categoryName
      formElements {
        id
        type
        name
        choices
        position
        labelText
        text
        size
        required
        multiLine
        numbersOnly
        displayInRow
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSystemFormTemplates = /* GraphQL */ `
  query ListSystemFormTemplates(
    $filter: ModelSystemFormTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemFormTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        categoryId
        categoryName
        formElements {
          id
          type
          name
          choices
          position
          labelText
          text
          size
          required
          multiLine
          numbersOnly
          displayInRow
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSystemFormCategory = /* GraphQL */ `
  query GetSystemFormCategory($id: ID!) {
    getSystemFormCategory(id: $id) {
      id
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSystemFormCategorys = /* GraphQL */ `
  query ListSystemFormCategorys(
    $filter: ModelSystemFormCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemFormCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

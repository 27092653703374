import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: 100,
      color: '#fff',
    },
  }));

  
export default function LoadingSpinner(props) {
    const classes = useStyles();
    const { open } = props;

    return (
        <Backdrop className={classes.backdrop} open={open} >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}
import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    spinner: {
        textAlign: 'center',
        marginTop: 20,
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

export default function LoadingSpinner(props) {
    const classes = useStyles();

    return (
        <div className={classes.spinner}>
            <CircularProgress color="primary" />
        </div>
    )
}
import { createSlice } from '@reduxjs/toolkit';
import { programActionsAsync } from './ProgramActionsAsync';

const { getPrograms } = programActionsAsync;

export const ProgramSlice = createSlice({
  name: 'programs',
  initialState: {
    list: []
  },
  reducers: {},
  extraReducers: {
    [getPrograms.fulfilled]: (state, action) => {
      state.list = action.payload.map(program => {
        return {
          ...program,
          id: String(program.id)
        }
      })
    },
  }
});

export default ProgramSlice.reducer;
import { 
  FormControl, 
  InputLabel,
  MenuItem, 
  Select 
} from '@material-ui/core';
import AddressUtils from '../../../Utilities/addressUtils';

export default function StateSelector(props) {
  const { 
    label, 
    name, 
    value, 
    onChange, 
    onBlur 
  } = props;

  const choicesUS = AddressUtils.availableStatesUS;
  const choicesCA = AddressUtils.availableStatesCanada;

  return (
    <FormControl fullWidth>

    <InputLabel shrink >
      { label }
    </InputLabel>

    <Select
      name={name}
      value={value || ''}
      onChange={onChange}
      displayEmpty
      onBlur={onBlur}>
        <MenuItem value="">-Select-</MenuItem>
        <MenuItem disabled>United States</MenuItem>
        { choicesUS.map((choice) => (
          <MenuItem key={choice} value={choice}>{choice}</MenuItem>
        ))}
        <MenuItem disabled>Canada</MenuItem>
        { choicesCA.map((choice) => (
          <MenuItem key={choice} value={choice}>{choice}</MenuItem>
        ))}


    </Select>
  </FormControl>
  )
}
import { createSlice } from '@reduxjs/toolkit';
import { purchaseOrderActionsAsync } from './PurchaseOrderActionsAsync';
import { navigate } from '@reach/router';
import * as utils from '../../../Utilities/reducerUtils';
const {     
    createPurchaseOrderAction,
    deletePurchaseOrderAction,
    loadPurchaseOrdersAction,
    updatePurchaseOrderAction,
} = purchaseOrderActionsAsync;


export const PurchaseOrderSlice = createSlice({
    name: 'purchaseOrders',
    initialState: {
        list: [],
        selectedPurchaseOrderId: null,
    },
    reducers: {
        selectPurchaseOrder(state, action) {
            state.selectedPurchaseOrderId = action.payload;
        },
        selectPurchaseOrderAndNavigate(state, action) {
            if(!!action.payload) {
                navigate(action.payload.route);
                state.selectedPurchaseOrderId = action.payload.purchaseOrderId;
            }
        },
    },
    extraReducers: {
        [loadPurchaseOrdersAction.fulfilled]: (state, action) => {
            state.list = action.payload;
        },
        [createPurchaseOrderAction.fulfilled]: (state, action) => {
            const created = action.payload;
            state.list = [...state.list, created];
        },
        [deletePurchaseOrderAction.fulfilled]: (state, action) => {
            const deleted = action.payload;
            state.list = utils.deleteItemFromList(state.list, deleted);
        },
        [updatePurchaseOrderAction.fulfilled]: (state, action) => {
            const updated = action.payload;
            state.list = utils.updateItemInList(state.list, updated);
        },
    }
});

export const purchaseOrderActions = { 
    addLineItem: PurchaseOrderSlice.actions.addLineItem,  // ben - how/where do i implement this?
    selectPurchaseOrder:  PurchaseOrderSlice.actions.selectPurchaseOrder,
    selectPurchaseOrderAndNavigate: PurchaseOrderSlice.actions.selectPurchaseOrderAndNavigate,
}

export default PurchaseOrderSlice.reducer;

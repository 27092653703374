import DatePickerInstance from '../../Models/datePickerInstance';
import FormElementInstance from '../../Models/formElementInstance';
import CheckBoxInstance from '../../Models/checkBoxInstance';
import FormElementUtils from '../../Utilities/formElementUtils';
import UserEsignSelectorInstance from '../../Models/userEsignSelectorInstance';
import moment from 'moment';


export default class FormInstanceUtils {

    static getFormElementInstances(elements) {
        const results = [];
        const { elementTypes } = FormElementUtils;

        elements.forEach(element => {
            let elementInstance;
            let elementProps = {
                elementId: element.id,
                elementName: element.name,
                elementType: element.type,
                required: element.required,
                value: element.value,
                checked: element.checked,
                signDate: element.signDate
            }; 
            
            if(element.type === elementTypes.datePicker) {
                elementInstance = new DatePickerInstance(elementProps);
            } else if(element.type === elementTypes.checkBox) {
                elementInstance = new CheckBoxInstance(elementProps);
            }else if(element.type === elementTypes.userEsignSelector) {
              elementInstance = new UserEsignSelectorInstance(elementProps);
            } else {
              elementInstance = new FormElementInstance(elementProps);
            }
            results.push(elementInstance);
        });

        return results;
    }

    static mapValuesToFormElements(values, elements) {
        const updatedElements = [];
        const { elementTypes } = FormElementUtils;

        elements.forEach(element => {
          const name = element.elementName ? element.elementName : element.name;
          const type = element.elementType ? element.elementType : element.type;

          if(values.hasOwnProperty(name) && type === elementTypes.checkBox) {
            const updatedElement = {
              ...element, 
              checked: values[name],
            } 
            updatedElements.push(updatedElement);

          } else if(values.hasOwnProperty(name) && type !== elementTypes.checkBox) {
            const updatedElement = {
              ...element, 
              value: values[name],
            }
            updatedElements.push(updatedElement);
          }
          
          else {
            updatedElements.push(...element);
          }
        });
    
        return updatedElements;
    }

    static sortDateAscending(instances) {
      return instances.slice().sort((a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf());
    }

    static sortDateDescending(instances) {
      return instances.slice().sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf());
    }

    static sortNameAscending(instances) {
      return instances.slice().sort((a, b) => a.createdBy.toLowerCase().localeCompare(b.createdBy.toLowerCase()));
    }

    static sortNameDescending(instances) {
      return instances.slice().sort((a, b) => b.createdBy.toLowerCase().localeCompare(a.createdBy.toLowerCase()));
    }

}
import { KeyboardDatePicker } from '@material-ui/pickers';
import { FormControl } from '@material-ui/core';
import ElementLabel from './ElementLabel';


export default function DatePicker(props) {
    const { 
        error, 
        name, 
        onChange, 
        touched,
        value,
        formElement: {
            labelText = {},
            required = {},
        }, 
    } = props;

    const isError = Boolean(error && touched);

    const handleChange = newDate => {
        onChange({
            target: {
            name: name,
            value: newDate ? newDate.toDate() : null,
            }
        });
      }

    return (
            <FormControl>   

                <ElementLabel 
                labelText={labelText}
                component='div'
                required={required} />

                <KeyboardDatePicker
                    value={value}
                    name={name}
                    error={isError}
                    helperText={isError ? error : null}
                    format="MM/DD/yyyy"
                    onChange={handleChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />

            </FormControl>    
    )
}
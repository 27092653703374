import { 
    FormControl, 
    FormLabel,
    MenuItem, 
    Select 
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

export default function SupplierSelector(props) {
    const { value, name, fullWidth, onChange, onBlur } = props;
    const suppliers = useSelector(state => state.suppliers.list);
    const getSupplier = id => {
        return suppliers;
    }
    const [selectedSupplier, setSelectedSupplier] = useState(getSupplier(value));
    useEffect(() => {
        setSelectedSupplier(suppliers.find(supplier => supplier.id === value || ''));
      }, [value, suppliers]);

    return (
        <FormControl fullWidth>

            <FormLabel>Suppliers</FormLabel>

            <Select
                name={name}
                value={selectedSupplier || ''}
                fullWidth={fullWidth}
                onChange={onChange}
                onBlur={onBlur}>
                { suppliers.map((supplier) => (
                    <MenuItem key={supplier.id} value={supplier}>{supplier.name}</MenuItem>
                ))}
            </Select>

      </FormControl>
    )
}
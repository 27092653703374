import { FieldArray } from 'formik';
import { Checkbox, FormControlLabel, FormLabel, FormGroup } from '@material-ui/core';
import { Fragment, useEffect } from 'react';

export default function VesselMultiSelector(props) {
  const { 
    choices, 
    label, 
    name, 
    selectAll, 
    selectAllName,
    setFieldValue, 
    values 
  } = props;

  const { allLocations } = values;

  const locations = !!values.locations ? values.locations : [];
  const vessels = !!values.vessels ? values.vessels : [];
  
  useEffect(() => {
    const fullArr = choices.map(choice => choice.id);

    if(selectAll) {
      setFieldValue(name, fullArr, true);
    }
  }, [selectAll, setFieldValue, choices, name]);


  if(!!choices && choices.length) {
    return (
      <Fragment>
        <FormLabel>{label}</FormLabel>

        <FieldArray
          name={name}
          render={(arrayHelpers) => (
            <FormGroup>
              <FormControlLabel label="All"
                control={ 
                <Checkbox 
                  color="primary"
                  disabled={!allLocations} 
                  checked={selectAll} 
                  onChange={() => {
                  setFieldValue(selectAllName, !selectAll, false);
                  if(!selectAll) {
                    setFieldValue(name, [], false);
                  } 
                }}/>}
              />
              { choices.map((choice) => (
                <FormControlLabel key={choice.id} label={choice.vessel_name || choice.location}
                  control={ 
                  <Checkbox 
                    color="primary"
                    disabled={!locations.includes(choice.fleet_location_id)}
                    checked={vessels.includes(choice.id) && locations.includes(choice.fleet_location_id)} 
                    onChange={() =>  {
                      if(selectAll && choices.length !== vessels.length) {
                          setFieldValue(selectAllName, false, true);
                      }
                      if(vessels.includes(choice.id)) {
                        const found = vessels.findIndex(item => item === choice.id);
                        arrayHelpers.remove(found);
                      } else if(locations.includes(choice.fleet_location_id)) {
                        arrayHelpers.push(choice.id);
                      }
                    }} 
                  />} 
                />
              ))}
            </FormGroup>
          )}
        ></FieldArray>
      </Fragment>
    )
  } else {
    return null;
  }
}
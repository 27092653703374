import { 
  FormControl, 
  InputLabel,
  MenuItem, 
  Select 
} from '@material-ui/core';


export default function YesNoSelector(props) {

  const { 
    customOptions,
    label, 
    name, 
    value, 
    onChange, 
    onBlur 
  } = props;

  const getFalseOption = () => {
    return !!customOptions ? customOptions.falseOption : 'No';
  }

  const getTrueOption = () => {
    return !!customOptions ? customOptions.trueOption : 'Yes';
  }

  return (
    <FormControl fullWidth>

      <InputLabel shrink >
        { label }
      </InputLabel>

      <Select
        name={name}
        value={value || ''}
        onChange={onChange}
        displayEmpty
        onBlur={onBlur}>
          <MenuItem value="">{ getFalseOption() }</MenuItem>
          <MenuItem value="true">{ getTrueOption() }</MenuItem>
      </Select>
    </FormControl>
  )
}
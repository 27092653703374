import { createSelector } from 'reselect';

const categorySelectorById = createSelector(
    state => state.systemCategories.list,
    state => state.systemCategories.selectedCategoryId,
    (categories, selectedCategoryId) => {
        if (!!(categories && categories.length) && selectedCategoryId) {
            return categories.find(category => category.id === selectedCategoryId)
        }
        return null;
    }
)

const systemCategorySelectorAll = createSelector(
    state => state.systemCategories.list,
    (categories) => categories,
)

export const systemCategorySelectors = {
    categorySelectorById,
    systemCategorySelectorAll,
}
import { v4 as uuidv4 } from 'uuid';

export default class YesOrNo {
    constructor(obj) {
        if(!obj) {
            obj = {}
        }
        this.id = obj.id || uuidv4(); 

        this.type = 'yesOrNo';

        this.name = obj.name || null;

        this.position = obj.position || null;

        this.choices = obj.choices || [ 'Yes', 'No' ];

        this.required = obj.required || false;

        this.labelText = obj.labelText || 'Yes / No';

        this.displayInRow = obj.displayInRow || false;
    }
}
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    tosLink: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline !important'
        },
    },
    termsContainer: {
        color: '#FFF',
        fontWeight: 'bold',
        marginTop: 15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'cener',
        flexDirection: 'column',
        rowGap: 2,
        '& span, a': {
        textAlign: 'center',
        textDecoration: 'none',
        color: '#fff',
        }
    },
}));

export default function SSOLoginPage(props) {
    const classes = useStyles();
    const wheelhouseRoot = useSelector(state => state.core.wheelhouseRoot);
    const newDate = new Date().getFullYear();
    const termsOfUseLink = wheelhouseRoot + 'termsofuse.php';

    return (
        <div className={classes.termsContainer}>
            <span>
            Copyright ©{ newDate } Baxter Marine Group, LLC.  All Rights Reserved.
            </span>
            <span>
            <a className={classes.tosLink} onClick={() => window.open(termsOfUseLink,'newwindow','width=940,height=600')} href="/#">Terms of Use</a>
            </span>
        </div>
    );
}
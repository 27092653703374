import { Fragment } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { FieldArray } from "formik";

export default function FleetUserSettings(props) {
  const { fleetUserSettings, values } = props;

  if (!!fleetUserSettings && !!values) {
    // sort settings by featureId so that the collection always renders the same
    const allSettings = [ ...values.fleetUserSettings ].sort((a, b) => a.featureId > b.featureId ? 1 : -1);
    return (
      <Fragment>
        <Grid item xs={12}>
          <Typography variant="h3">Settings</Typography>
        </Grid>

        <FieldArray
          name="fleetUserSettings"
          render={(arrayHelpers) => (
            <Fragment>

              {allSettings.map((setting, index) => {

                // match on the fleet user setting in question
                const fus = fleetUserSettings.find(f => f.featureId === setting.featureId);

                // return out if not found
                if (!fus) {
                  return (null);
                }

                return (
                  <Grid item xs={2} key={setting.featureId}>
                    <FormControl fullWidth>
                      <InputLabel shrink>
                        {
                          fus.featureName
                        }
                      </InputLabel>

                      <Select
                        name={String(setting.featureId)}
                        value={setting.optionId}
                        onChange={(e) => {
                          const { target } = e;
                          arrayHelpers.replace(index, {
                            featureId: Number(target.name),
                            optionId: target.value,
                          });
                        }}
                        displayEmpty
                      >
                        {fus.options.map((option) => (
                          <MenuItem
                            key={option.optionId}
                            value={option.optionId}
                          >
                            {option.optionName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                );
              })}
            </Fragment>
          )}
        />
      </Fragment>
    );
  } else {
    return null;
  }
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import awsmobile from '../aws-exports';

const apiName = awsmobile.aws_cloud_logic_custom[0].name;

const getPrograms = createAsyncThunk(
  'programs/getPrograms',
  async () => {
    return await API.get(apiName, '/programs', {});
  }
);

export const programActionsAsync = {
  getPrograms
}
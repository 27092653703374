import { 
  Container, 
  Grid, 
  makeStyles, 
  Typography,
} from "@material-ui/core";
import FormElementProvider from "../../Core/FormElementProvider/FormElementProvider";

const useStyles = makeStyles(theme => ({
  formRender: theme.formRender,
  centerTitle: theme.centerTitle,
	printContainer: theme.printContainer,
  actionsRight: theme.actionsRight,
	actionButton: theme.actionButton,
}));

export default function PrintFormInstance(props) {
  const classes = useStyles();
  const { selectedFormInstance, selectedTemplate } = props;

  const getFormElement = (instance, elements) => {
    return elements.find(element => instance.elementId === element.id);
  }

  if(!!selectedTemplate && !!selectedFormInstance) {
    return (
      <Container maxWidth={false} className={classes.printContainer}>
          <Grid container style={{ display: 'block' }} spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.centerTitle}>{selectedTemplate.name}</Typography>
            </Grid>
            {selectedFormInstance.formElementInstances.map((instance) => (
              <Grid item xs={12} key={instance.elementId}>
                <FormElementProvider
                  formElement={getFormElement(instance, selectedTemplate.formElements)}
                  value={instance.elementType === 'checkBox' ? instance.checked : instance.value}
                  name={instance.elementName}
                  />
              </Grid>
            ))}     
          </Grid>
      </Container>
    )
  } else {
    return null;
  }
}
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import { 
    Container, 
    Grid, 
    makeStyles, 
} from '@material-ui/core';
import { systemTemplateSelectors } from '../SystemFormBuilder/SystemFormTemplates/SystemTemplateState/SystemTemplateSelectors';
import { systemTemplateActions } from '../SystemFormBuilder/SystemFormTemplates/SystemTemplateState/SystemTemplateSlice';
import { systemTemplateActionsAsync } from '../SystemFormBuilder/SystemFormTemplates/SystemTemplateState/SystemTemplateActionsAsync';
import FormBuilderCanvas from '../CustomFormBuilder/FormBuilderCanvas/FormBuilderCanvas';
import FormBuilderMenu from '../CustomFormBuilder/FormBuilderMenu/FormBuilderMenu';
import FormElementDetailDialog from '../CustomFormBuilder/FormElementDetailDialog/FormElementDetailDialog';
import FormBuilderPreviewDialog from '../CustomFormBuilder/FormBuilderPreviewDialog/FormBuilderPreviewDialog';
import LoadingSpinner from '../../Core/Spinners/LoadingSpinner';
import FormElementUtils from '../../Utilities/formElementUtils';
import FormBuilderCanvasTitle from '../CustomFormBuilder/FormBuilderCanvas/FormBuilderCanvasTitle/FormBuilderCanvasTitle';
import AppNav from '../../Core/AppNav';
import { Fragment } from 'react';
import { navigate } from '@reach/router';
import PageHeader from '../../Core/PageHeader';

const useStyles = makeStyles(theme => ({
  container: theme.container,
  formBuilderRoot: {
    minHeight: '90vh'
  },
  header: theme.pageHeader,
  button: {
    cursor: 'pointer',
  },
  previewWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& *': {
      margin: '0 5px',
    }
  },
}));

export default function SystemFormBuilderPage(props) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const { templateId } = props;

    const selectedTemplate = useSelector(systemTemplateSelectors.templateSelectorById);
    const selectedFormElement = useSelector(systemTemplateSelectors.formElementSelectorById);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
    const [localElements, setLocalElements] = useState(selectedTemplate?.formElements || null);

    if(!selectedTemplate) {
        dispatch(systemTemplateActions.selectTemplate(templateId));
    }

    const handleAddNewElement = type => {
        const params = {
            template: selectedTemplate,
            type: type,
        };

        dispatch(systemTemplateActionsAsync.addFormElementToSystemTemplate(params));
    }

    const handleDeleteFormElement = formElement => {
        const params = {
            template: selectedTemplate, 
            formElement: formElement,
        };

        dispatch(systemTemplateActionsAsync.removeFormElementFromSystemTemplate(params));
    }

    const handleDragFormElement = result => {
        const { source, destination } = result;

        if(!destination) {
            return
        }

        const reorderParams = {
            elementList: localElements,
            sourceIndex: source.index,
            destinationIndex: destination.index
        }
     
        setLocalElements(FormElementUtils.reorderElementlist(reorderParams))

        const params = {
            template: selectedTemplate,
            source: source,
            destination: destination,
        };

        dispatch(systemTemplateActionsAsync.updateFormElementPosition(params));
    }

    const handleEditClick = formElement => {
        setIsEditDialogOpen(true);
        dispatch(systemTemplateActions.selectFormElement(formElement.id));
    }

    const handleUpdateFormElement = formElement => {
        const params = {
            template: selectedTemplate,
            formElement: formElement,
        };

        dispatch(systemTemplateActionsAsync.updateFormElement(params));
    }

    useEffect(() => {
        if(!!selectedTemplate) {
            setLocalElements(selectedTemplate.formElements)
        }
    }, [selectedTemplate])

    const headerButtons = [
      {
        buttonText: 'Back',
        buttonAction: () => navigate('/system-templates'),
      },
      {
        buttonText: 'Preview',
        buttonAction: () => setIsPreviewDialogOpen(true),
      }
    ]


    if(!!localElements) {
        return (
            <Fragment>
                <AppNav />
                
                <Container maxWidth={false} className={classes.container}>

                <PageHeader 
      						headerText="System Form Builder"
      						headerButtons={headerButtons}
      					/>

                    <FormBuilderCanvasTitle 
                        template={selectedTemplate} />
                    <DragDropContext onDragEnd={handleDragFormElement}>
                        <Grid container className={classes.formBuilderRoot}>

                            <Grid item xs={10}>
                                <FormBuilderCanvas 
                                    template={selectedTemplate}
                                    localElements={localElements}
                                    onDelete={handleDeleteFormElement}
                                    onEditClick={handleEditClick}
                                    
                                    onDrop={handleAddNewElement} />
                            </Grid>
                            <Grid item xs={2}>
                                <FormBuilderMenu
                                    onAddClick={handleAddNewElement}
                                /> 
                            </Grid>
                        </Grid>
                    </DragDropContext>

                    <FormElementDetailDialog 
                    open={isEditDialogOpen}
                    formElement={selectedFormElement}
                    onSubmit={handleUpdateFormElement}
                    onClose={() => setIsEditDialogOpen(false)}
                    />

                    <FormBuilderPreviewDialog 
                    open={isPreviewDialogOpen}
                    template={selectedTemplate}
                    onClose={() => setIsPreviewDialogOpen(false)}
                    />
                </Container>
            </Fragment>
        )
    } else {
        return (
            <LoadingSpinner />
        )
    }

}
import { purchaseOrderActionsAsync } from './PurchaseOrderState/PurchaseOrderActionsAsync';
import { useDispatch, useSelector } from 'react-redux';
import RenderPurchaseOrder from './RenderPurchaseOrder';
import { navigate } from '@reach/router';
import { purchaseOrderSelectors } from './PurchaseOrderState/PurchaseOrderSelectors';
import { purchaseOrderActions } from './PurchaseOrderState/PurchaseOrderSlice';

export default function EditPurchaseOrderPage(props) {
  const dispatch = useDispatch();
  const { orderId } = props;


  const selectedPurchaseOrder = useSelector(purchaseOrderSelectors.purchaseOrderSelectorById);
  if(!selectedPurchaseOrder) {
    dispatch(purchaseOrderActions.selectPurchaseOrder(orderId));
  }

  const handleUpdatePurchaseOrderSubmit = purchaseOrder => {
    const params = {
      purchaseOrder,
    };

    dispatch(purchaseOrderActionsAsync.updatePurchaseOrderAction(params)).then(response => {      
      navigate('/purchase-orders');
    });
  };

  if(selectedPurchaseOrder) {
  return (
    <RenderPurchaseOrder 
      purchaseOrder={selectedPurchaseOrder}
      onSubmit={handleUpdatePurchaseOrderSubmit}
      title="Update Purchase Order"
    />
  )
  } else {
    return null;
  }
}
import { makeStyles, Typography } from '@material-ui/core';
import FormBuilderMenuItem from './FormBuilderMenuItem';
import FormElementUtils from '../../../Utilities/formElementUtils';

const useStyles = makeStyles(theme => ({
    menu: {
        height: '100%',
        borderRadius: `0 0 ${theme.borderRadius}px 0`,
        border: '1px solid #000',
        borderTop: 'none',
        boxSizing: 'border-box'
    },
    menuTitle: {
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
    },
    menuBody: {
        padding: '4px 16px',
    }
}));

export default function FormBuilderMenu(props) {
    const classes = useStyles();
    const { onAddClick } = props;

    const dynamicFormKeys = ['vesselSelector', 'userSelector', 'userEsignSelector'];
    const standardFormElementTypes = Object.keys(FormElementUtils.elementTypes).filter(item => dynamicFormKeys.indexOf(item) === -1);
    const dynamicFormElementTypes = Object.keys(FormElementUtils.elementTypes).filter(item => dynamicFormKeys.indexOf(item) !== -1);

    if(!!standardFormElementTypes) {
        return (
            <div className={classes.menu}>
                <div>
                    <Typography className={classes.menuTitle} component="h3">Standard Inputs</Typography>
                </div>
                
                <div className={classes.menuBody}>
                { standardFormElementTypes.map(type => (
                   
                    <FormBuilderMenuItem 
                        key={type}
                        type={type}
                        onAddClick={onAddClick} />
                   
                ))}
                </div>

                <div>
                    <Typography className={classes.menuTitle} component="h3">Dynamic Inputs</Typography>
                </div>
                
                <div className={classes.menuBody}>
                { dynamicFormElementTypes.map(type => (
                   
                    <FormBuilderMenuItem 
                        key={type}
                        type={type}
                        onAddClick={onAddClick} />
                   
                ))}
                </div>
            </div>
    )} else {
        return null;
    }
}
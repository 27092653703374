import { Checkbox, Tooltip } from "@material-ui/core";
import { useState } from "react";

export default function StatusToggle(props) {
  const { onChange, user } = props;

  const [checked, setChecked] = useState(user.userStatus === 'active');

  const handleChange = () => {
    setChecked(!checked);
    onChange(user);
  }
  return (
		<Tooltip title="Toggle Status">
    	<Checkbox checked={checked} color="primary" onChange={handleChange} />
		</Tooltip>
  )
}
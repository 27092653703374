import { templateActionsAsync } from '../../Admin/CustomFormBuilder/CustomFormTemplates/TemplateState/TemplateActionsAsync'; 
import { categoryActionsAsync } from '../../Admin/CustomFormBuilder/CustomFormCategories/CategoryState/CategoryActionsAsync'; 
import { formInstanceActionsAsync } from '../../FormInstances/FormInstanceState/FormInstanceActionsAsync';
import { vesselActionsAsync } from '../../Vessels/VesselActionsAsync';
import { fleetUserActionsAsync } from '../../FleetUsers/FleetUserState/FleetUserActionsAsync';
import { purchaseOrderActionsAsync } from '../../Purchasing/PurchaseOrders/PurchaseOrderState/PurchaseOrderActionsAsync';
import { currentUserActions } from '../../CurrentUser/CurrentUserState/CurrentUserSlice';
import { supplierActionsAsync } from '../../Purchasing/Suppliers/SupplierState/SupplierActionsAsync';
import { locationActionsAsync } from '../../Locations/LocationActionsAsync';
import { systemCategoryActionsAsync } from '../../Admin/SystemFormBuilder/SystemFormCategories/SystemCategoryState/SystemCategoryActionsAsync';
import { systemTemplateActionsAsync } from '../../Admin/SystemFormBuilder/SystemFormTemplates/SystemTemplateState/SystemTemplateActionsAsync';
import { userActionsAsync } from '../../Admin/Users/UserState/UserActionsAsync';
import { programActionsAsync } from '../../Programs/ProgramActionsAsync';
import { currentUserActionsAsync } from '../../CurrentUser/CurrentUserState/CurrentUserActionsAsync';

export default function appStart(params) {
  return function(dispatch) {
    if(params.group === 'FleetUser') {
      dispatch(currentUserActionsAsync.getCurrentUserSettings(params));
    }

    dispatch(currentUserActionsAsync.checkIfUserHasPIN());
    
    dispatch(currentUserActions.setUser(params));

    dispatch(loadData());     
	}
}


export const loadData = () => {
  return function (dispatch) {
    dispatch(templateActionsAsync.loadTemplates());
    dispatch(categoryActionsAsync.loadCategories());
    dispatch(formInstanceActionsAsync.loadInstances());
    dispatch(vesselActionsAsync.getVesselsByTenantId());
    dispatch(supplierActionsAsync.getSuppliersByTenantId());
    dispatch(fleetUserActionsAsync.getFleetUsersByTenantId());
    dispatch(fleetUserActionsAsync.getFleetOptionsByTenantId());
    dispatch(fleetUserActionsAsync.getFleetUserSettings());
    dispatch(purchaseOrderActionsAsync.loadPurchaseOrdersAction());
    dispatch(locationActionsAsync.getLocationsByTenantId());
    dispatch(systemTemplateActionsAsync.loadSystemTemplates());
    dispatch(systemCategoryActionsAsync.loadSystemCategories());
    dispatch(userActionsAsync.getUsers());
    dispatch(programActionsAsync.getPrograms());
  }
}

export const handleError = (error) => {
  return function (dispatch) {
    dispatch({
      type: 'SHOW_ERROR',
      payload: error
    });

    console.error(error);
  }
}

import { Container, makeStyles } from '@material-ui/core';
import FormInstanceTable from './FormInstanceTable/FormInstanceCategoryTable';
import SystemFormInstanceCategoryTable from './FormInstanceTable/SystemFormInstanceCategoryTable';
import { useDispatch, useSelector } from 'react-redux';
import { templateSelectors } from '../Admin/CustomFormBuilder/CustomFormTemplates/TemplateState/TemplateSelectors';
import { systemTemplateSelectors } from '../Admin/SystemFormBuilder/SystemFormTemplates/SystemTemplateState/SystemTemplateSelectors';
import { templateActions } from '../Admin/CustomFormBuilder/CustomFormTemplates/TemplateState/TemplateSlice';
import AppNav from '../Core/AppNav';
import { Fragment } from 'react';
import PageHeader from '../Core/PageHeader';

const useStyles = makeStyles(theme => ({
	container: theme.container,
}));

export default function ManageFormsPage(props) {
  const dispatch = useDispatch();
	const classes = useStyles();

  const templatesPerCategory = useSelector(templateSelectors.templateSelectorPerCategory);
  const systemTemplatesPerCategory = useSelector(systemTemplateSelectors.systemTemplateSelectorPerCategory);

  const handleSelect = (template) => {
    const params = {
      templateId: template.id,
      route: '/form-instances/' + template.id,
    }

    dispatch(templateActions.selectTemplateAndNavigate(params));
  }

  return (
    <Fragment>
			<AppNav />
      <Container  maxWidth={false} className={classes.container}>
				<PageHeader  headerText='Forms And Checklists' />

        <FormInstanceTable
          categories={templatesPerCategory}
          onSelect={handleSelect}
        />

        <SystemFormInstanceCategoryTable
          categories={systemTemplatesPerCategory}
          onSelect={handleSelect}
        />
        
      </Container>
    </Fragment>
  )
}
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { purchaseOrdersByTenantId } from '../../../graphql/queries';
import {
    createPurchaseOrder,
    deletePurchaseOrder,
    updatePurchaseOrder,
} from '../../../graphql/mutations';
import PurchaseOrder from '../../Models/purchaseOrder';
import PurchaseOrderUtils from '../PurchaseOrderUtils/PurchaseOrderUtils';
import { getCurrentTenant } from '../../../CurrentUser/CurrentUserState/CurrentUserSelector';


const createPurchaseOrderAction = createAsyncThunk(
    'purchaseOrders/createPurchaseOrder',
    async (params, { getState }) => {
        const { purchaseOrder } = params; 
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;
        
        purchaseOrder.tenantId = currentUser.tenantId;
        purchaseOrder.number = PurchaseOrderUtils.getNextNumber(getState);

        const response = await API.graphql({
            query: createPurchaseOrder,
            variables: { input: purchaseOrder },
            authMode: authMode,
        });

        return response.data.createPurchaseOrder;
    });

const deletePurchaseOrderAction = createAsyncThunk(
    'purchaseOrders/deletePurchaseOrder',
    async (params, { getState }) => {
        const { purchaseOrder } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const response = await API.graphql({
            query: deletePurchaseOrder,
            variables: { input: { id: purchaseOrder.id } },
            authMode: authMode,
        });

        return response.data.deletePurchaseOrder;
    });

const loadPurchaseOrdersAction = createAsyncThunk(
    'purchaseOrders/loadPurchaseOrders',
    async (params, { getState }) => {
      const tenantId = getCurrentTenant(getState().currentUser);
      const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const response = await API.graphql({
            query: purchaseOrdersByTenantId,
            variables: { tenantId: tenantId },
            authMode: authMode,
        });

        return response.data.purchaseOrdersByTenantId.items;
    });

const updatePurchaseOrderAction = createAsyncThunk(
    'purchaseOrders/updatePurchaseOrder',
    async (params, { getState }) => {
        const { purchaseOrder } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const updatedPurchaseOrder = new PurchaseOrder({
            ...purchaseOrder,
            lastUpdatedBy: getState().currentUser.userId,
        });

        const response = await API.graphql({
            query: updatePurchaseOrder,
            variables: { input: updatedPurchaseOrder },
            authMode: authMode,
        });

        return response.data.updatePurchaseOrder;
    });

export const purchaseOrderActionsAsync = {
    createPurchaseOrderAction,
    deletePurchaseOrderAction,
    loadPurchaseOrdersAction,
    updatePurchaseOrderAction,
};
import { createSlice } from '@reduxjs/toolkit';
import { vesselActionsAsync } from './VesselActionsAsync';

const { getVesselsByTenantId } = vesselActionsAsync;

export const VesselSlice = createSlice({
    name: 'vessels',
    initialState: {
        list: [],
    },
    reducers: {},
    extraReducers: {
        [getVesselsByTenantId.fulfilled]: (state, action) => {
            state.list = action.payload;
        },
    }
});

export default VesselSlice.reducer;


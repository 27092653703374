import { Tooltip, IconButton } from "@material-ui/core";
import FileCopyIcon from '@material-ui/icons/FileCopy';

export default function CloneIconButton(props) {
	const { onClick } = props;

	return (
		<Tooltip title="Clone">
			<IconButton
				size="small" 
				onClick={onClick}>
				<FileCopyIcon />
			</IconButton>
		</Tooltip>
	)
}
import { 
  FormControl, 
  FormHelperText, 
  makeStyles,
  MenuItem, 
  Select 
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ElementLabel from '../ElementLabel';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useState } from 'react';
import UserEsignDialog from './UserEsignDialog';
import { fleetUserActionsAsync } from '../../../FleetUsers/FleetUserState/FleetUserActionsAsync';
import { fleetUserSelectors } from '../../../FleetUsers/FleetUserState/FleetUserSelector';

const useStyles = makeStyles(theme => ({
  dropDownWidth: theme.minInputWidth,
  selectIconRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '& svg': {
      margin: 'auto',
      padding: 5
    }
  }
}));

export default function UserEsignSelector(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { 
    error,  
    name, 
    onBlur, 
    setFieldValue,
    setFieldError,
    touched, 
    value,
    formElement: {
      labelText = {},
      required = {},
    },
  } = props;

  const users = useSelector (fleetUserSelectors.fleetUsersIdSelector);
  const [esignDialogOpen, setEsignDialogOpen] = useState(false);
  const [localSelectedUser, setLocalSelectedUser] = useState(value || '');
  const isError = Boolean(error && touched);

  const handlePINSubmit = (values) => {
    dispatch(fleetUserActionsAsync.authenticatePIN(values))
    .unwrap()
    .then(response => { 
      if(response.PINAuthenticated) {
        setFieldValue(name, localSelectedUser, false);
        setEsignDialogOpen(false);
      } else {
        setFieldError(name, 'Unable to verify PIN');
        setEsignDialogOpen(false);
      }
    });
  }

  return (
    <Fragment>
      <FormControl error={isError}>

        <ElementLabel 
          labelText={labelText}
          component='div'
          required={required}/>

        <div className={[classes.selectIconRow, classes.dropDownWidth].join(' ')}>
          <Select
            name={name}
            value={value || ''}
            onChange={e => {
              setLocalSelectedUser(e.target.value);
              setEsignDialogOpen(true);
            }}
            onBlur={onBlur}
            fullWidth>
            { users.map((choice, index) => (
              <MenuItem key={choice + index} value={choice}>{choice}</MenuItem>
            ))}
          </Select>

          { !!value && 
            <CheckCircleOutlineIcon />
          }

        </div>
        

        <FormHelperText id="dropdown-text">{isError ? error : null}</FormHelperText>

      </FormControl>

      <UserEsignDialog
        open={esignDialogOpen}
        user={localSelectedUser}
        onClose={() => setEsignDialogOpen(false)}
        onSubmit={handlePINSubmit}
      />

    </Fragment>
  )
}
import AppNav from "./AppNav";
import { Container, makeStyles, Typography } from "@material-ui/core";
import { Fragment } from "react";

const useStyles = makeStyles(theme => ({
	container: theme.container
}));

export default function AccessDenied(props) {
	const classes = useStyles();

  return (
    <Fragment>
      <AppNav />
      <Container  maxWidth={false}  className={classes.container}>
        <Typography>Access Denied!</Typography>
      </Container>
    </Fragment>
  )
}
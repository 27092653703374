import {
  makeStyles,
  TableCell,
  TableRow,
} from '@material-ui/core';
import DeleteIconButton from '../../../../../Core/DeleteIconButton';
import EditIconButton from '../../../../../Core/EditIconButton';
import CloneIconButton from '../../../../../Core/CloneIconButton';
import PreviewIconButton from '../../../../../Core/PreviewIconButton';

const useStyles = makeStyles(theme => ({
  tableHeader: theme.tableHeader,
	stripedTableRow: theme.stripedTableRow,
	tableRow: theme.tableRow,
}));

export default function NestedTemplateTableRow(props) {
  const classes = useStyles();
  const { template, onClone, onDelete, onSelect, onPreview } = props;


  return (
    <TableRow className={[classes.stripedTableRow, classes.tableRow].join(' ')}>

      <TableCell scope="row">
        {template.name}
      </TableCell>

      { !!onPreview && 
        <TableCell width="3%" align="right">
  				<PreviewIconButton onClick={() => onPreview(template)} />
        </TableCell>
      }

      { !! onClone && 
        <TableCell width="3%" align="right">
  				<CloneIconButton onClick={() => onClone(template)} />
        </TableCell>
      }

      { !!onSelect && 
  			<TableCell width="3%" align="right">
  				<EditIconButton onClick={() => onSelect(template)} />
        </TableCell>
      }

      { !!onDelete && 
        <TableCell width="3%" align="right">
  				<DeleteIconButton onClick={() => onDelete(template)} />
        </TableCell>
      }
				
    </TableRow>
  )
}
import { createSelector } from 'reselect';
import FormElementUtils from '../../../../Utilities/formElementUtils';

const templateSelectorAll = createSelector(
    state => state.templates.list,
    state => state.systemTemplates.list,
    (templates, systemTemplates) => {
        return [...templates, ...systemTemplates];
    }
)

const templateSelectorById = createSelector(
    templateSelectorAll,
    state => state.systemTemplates.selectedTemplateId,
    (templates, selectedTemplateId) => {
        if (!!(templates && templates.length) && selectedTemplateId) {
            const foundTemplate = templates.find(template => template.id === selectedTemplateId);
            if(!!foundTemplate) {
                return {
                    ...foundTemplate,
                    formElements: foundTemplate?.formElements.slice().sort((a, b) => a.position - b.position),
                }
            } else {
                return null;
            }
        }
        return null;
    }
)

const formElementSelectorById = createSelector(
    templateSelectorById,
    state => state.systemTemplates.selectedFormElementId,
    (template, selectedFormElementId) => {
        if (!!template && selectedFormElementId) {
            const { formElements } = template;
            const selectedFormElement = formElements.find(formElement => formElement.id === selectedFormElementId );
            if(!!selectedFormElement) {
                return FormElementUtils.castToRelevantClass(selectedFormElement);
            } 
            return null;
            
        } 
        return null
    }
)

const systemTemplateSelectorPerCategory = createSelector(
    state => state.systemTemplates.list,
    state => state.systemCategories.list,
    (templates, categories) => {
        if((!!templates) && (!!categories && categories.length)) {
            const foundTemplates = [];

            categories.forEach(category => {

                const matchedTemplates = templates.filter(template => template.categoryId === category.id);

                const categoryObj = {
                    name: category.name,
                    id: category.id,
                    templates: matchedTemplates,
                }

                foundTemplates.push(categoryObj);
            });
            return foundTemplates;
        } 
        return null;
    }
)

export const systemTemplateSelectors = {
    templateSelectorAll,
    formElementSelectorById,
    templateSelectorById,
    systemTemplateSelectorPerCategory,
}
export default class PurchaseOrderUtils {
    static getNextNumber(getState) {
        const orderNumbers = getState().purchaseOrders.list.map(item => item.number);
        
       if(orderNumbers.length === 0) {
           return 1;
       } else if(orderNumbers.length === 1) {
           return 2;
       } else return Math.max(...orderNumbers) + 1;
    }
}

const paymentTerms = Object.freeze({
    "PIA": "PIA",
    "NET7": "NET7",
    "NET10": "NET10",
    "NET30": "NET30",
    "NET60": "NET60",
    "NET90": "NET90",
    "EOM": "EOM",
    "TWENTYONE_MFI": "TWENTYONE_MFI",
    "ONE_PCT_TEN_NET30": "ONE_PCT_TEN_NET30",
    "COD": "COD",
    "CASH": "CASH",
    "LETTER_OF_CREDIT": "LETTER_OF_CREDIT",
    "BILL_OF_EXCHANGE": "BILL_OF_EXCHANGE",
    "CND": "CND",
    "CBS": "CBS",
    "CIA": "CIA",
    "CWO": "CWO",
    "ONE_MD": "ONE_MD",
    "TWO_MD": "TWO_MD",
    "CONTRA": "CONTRA",
    "STAGE_PAYMENT": "STAGE_PAYMENT",
});

export default paymentTerms;
import { 
  Button,   
  makeStyles, 
  TextField 
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  actionButton: theme.actionButton,
  search: {
    display: 'flex',
    alignContent: 'center',
    marginBottom: 10,
    '& button': {
      marginTop: 5,
      marginBottom: 5,
      marginLeft: 5,
    }
  }
}));

export default function UserSearch(props) {
  const classes = useStyles();

  const {
    onSearch,
    onShowAll,
    onSetSearch,
    search
  } = props;

  const handleSubmit = e => {
    e.preventDefault();
    onSearch(search);
  }

  const handleShowAll = () => {
    onSetSearch('');
    onShowAll();
  }

  return (
    <div className={classes.search}>
      <form onSubmit={handleSubmit}>
        <TextField 
          size="small" 
          label="Search" 
          value={search}
          onChange={(e) => onSetSearch(e.target.value)}
          type="search" 
          variant="outlined"
           />
        <Button onClick={() => onSearch(search)} className={classes.actionButton}>Search</Button>
      </form>
      <Button onClick={handleShowAll} className={classes.actionButton}>Show All</Button>
    </div>
  )
}
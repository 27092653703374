import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
 } from '@material-ui/core';

 
export default function CloneInstanceDialog(props) {
    const { open, onClose, originalInstance, onSubmit } = props;
    
    if(!!originalInstance) {
        return (
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Clone Form or Checklist</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Clone this instance of "{originalInstance.templateName}"?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button onClick={onSubmit} color="primary">
                Clone
              </Button>
            </DialogActions>
          </Dialog>
        )
    } else {
        return null;
    }    
}
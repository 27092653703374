import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import {
    listSystemFormTemplates,
} from '../../../../graphql/queries';
import {
    createSystemFormTemplate,
    updateSystemFormTemplate,
    deleteSystemFormTemplate,
} from '../../../../graphql/mutations';
import SystemFormTemplate from '../../../../Models/systemFormTemplate';
import FormElementUtils from '../../../../Utilities/formElementUtils';
import * as utils from '../../../../Utilities/reducerUtils';
import { v4 as uuidv4 } from 'uuid';

const loadSystemTemplates = createAsyncThunk(
    'systemTemplates/loadSystemTemplates',
    async (params, { getState }) => {
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const response = await API.graphql({
            query: listSystemFormTemplates,
            authMode: authMode,
        });

        return response.data.listSystemFormTemplates.items;
    });

const createSystemTemplate = createAsyncThunk(
    'systemTemplates/createSystemFormTemplate',
    async (params, { getState }) => {
        const { newTemplate } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const input = {
            ...newTemplate, 
        }

        const response = await API.graphql({
            query: createSystemFormTemplate,
            variables: { input: input },
            authMode: authMode,
        });
        
        return response.data.createSystemFormTemplate;
    });

const deleteSystemTemplate = createAsyncThunk(
    'systemTemplates/deleteSystemTemplate',
    async (params, { getState }) => {
        const { template } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const response = await API.graphql({
            query: deleteSystemFormTemplate,
            variables: { input: { id: template.id } },
            authMode: authMode,
        });
        
        return response.data.deleteSystemFormTemplate;
    });

const cloneSystemTemplate = createAsyncThunk(
    'systemTemplates/cloneSystemTemplate',
    async (params, { getState }) => {
        const { 
          originalTemplate, 
          templateName, 
          categoryId,
          categoryName,
        } = params;

        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const clonedFormElements = originalTemplate.formElements.map(element => {
            return {
                ...element,
                id: uuidv4()
            }
        });
        const input = new SystemFormTemplate({
            name: templateName,
            type: originalTemplate.type,
            categoryId: categoryId,
            categoryName: categoryName,
            formElements: clonedFormElements,
        });

        const response = await API.graphql({
            query: createSystemFormTemplate,
            variables: { input: input },
            authMode: authMode,
        });
        
        return response.data.createSystemFormTemplate;
    });

const updateSystemTemplate = createAsyncThunk(
    'systemTemplates/updateSystemTemplate',
    async (params, { getState }) => {
        const { updatedTemplate } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const input = {
            ...updatedTemplate,
        }
        const response = await API.graphql({
            query: updateSystemFormTemplate,
            variables: { input: input },
            authMode: authMode,
        });

        return response.data.updateSystemFormTemplate;
    });

const addFormElementToSystemTemplate = createAsyncThunk(
    'systemTemplates/addFormElementToSystemTemplate',
    async (params, { getState }) => {
        const { template, type } = params;
        
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;
        
        const newElementPosition = FormElementUtils.getNewElementPositionOnAdd({getState: getState})
        
        const newElementParams = {
            type: type, 
            position: newElementPosition,
            name: type + template.formElements.length,
        }
        
        const newFormElement = FormElementUtils.castToRelevantClass(newElementParams);

        const updatedTemplate = new SystemFormTemplate({
            ...template,
            formElements: [...template.formElements, newFormElement],
        });
        const response = await API.graphql({
            query: updateSystemFormTemplate,
            variables: { input: updatedTemplate },
            authMode: authMode,
        });
        return response.data.updateSystemFormTemplate;
    });

const removeFormElementFromSystemTemplate = createAsyncThunk(
    'systemTemplates/removeFormElementFromSystemTemplate',
    async (params, { getState }) => {
        const { template, formElement } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const updatedTemplate = new SystemFormTemplate({
            ...template, 
            formElements: utils.deleteItemFromList(template.formElements, formElement),
        });

        const response = await API.graphql({
            query: updateSystemFormTemplate,
            variables: { input: updatedTemplate },
            authMode: authMode,
        });

        return response.data.updateSystemFormTemplate;
    });

const updateFormElementPosition = createAsyncThunk(
    'systemTemplates/updateFormElementPosition',
    async (params, { getState }) => {
        const { template, source } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const newElementPosition = FormElementUtils.getNewElementPositionOnDrag(params);

        const draggedElement = template.formElements.find(element => element.id === source.droppableId);

        const updatedFormElement = FormElementUtils.castToRelevantClass({
            ...draggedElement,
            position: newElementPosition,
        });

        const updatedTemplate = new SystemFormTemplate({
            ...template,
            formElements: utils.updateItemInList(template.formElements, updatedFormElement),
        });

        const response = await API.graphql({
            query: updateSystemFormTemplate,
            variables: { input: updatedTemplate },
            authMode: authMode,
        });

        return response.data.updateSystemFormTemplate;
    });

const updateFormElement = createAsyncThunk(
    'systemTemplates/updateFormElement',
    async (params, { getState }) => {
        const { template, formElement } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const updatedTemplate = new SystemFormTemplate({
            ...template,
            formElements: utils.updateItemInList(template.formElements, formElement),
        });

        const response = await API.graphql({
            query: updateSystemFormTemplate,
            variables: { input: updatedTemplate },
            authMode: authMode,
        });

        return response.data.updateSystemFormTemplate;
    });

    export const systemTemplateActionsAsync = {
        loadSystemTemplates,
        createSystemTemplate,
        deleteSystemTemplate,
        cloneSystemTemplate,
        updateSystemTemplate,
        addFormElementToSystemTemplate,
        removeFormElementFromSystemTemplate,
        updateFormElementPosition,
        updateFormElement,
    };
import { createSlice } from '@reduxjs/toolkit';
import { supplierActionsAsync } from './SupplierActionsAsync';

const { getSuppliersByTenantId } = supplierActionsAsync;
export const SupplierSlice = createSlice({
    name: 'suppliers',
    initialState: {
        list: [],
    },
    reducers: {},
    extraReducers: {
        [getSuppliersByTenantId.fulfilled]: (state, action) => {
            state.list = action.payload.map( supplier => {
                return {
                    id: supplier.id,
                    name: supplier.name,
                    address: {
                        street1: supplier.address_1,
                        street2: supplier.address_2,
                        city: supplier.city,
                        state: supplier.state,
                        postalCode: supplier.zip_code,
                        country: supplier.country
                    }
                }
            })
        },
    }
});

export default SupplierSlice.reducer;
import { 
    makeStyles, 
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    report: {
        width: '100%',
        height: 1000
    }
}));

export default function EmbeddedDashboard(props) {
    const { url } = props;
    const classes = useStyles();

    if (!!url) {
        return (
            <iframe title="Report" src={url} className={classes.report}></iframe>
        )
    } else {
        return <p>No report found.</p>;
    }
}
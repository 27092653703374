import { Fragment, useEffect, useState } from 'react';
import AppNav from '../../Core/AppNav';
import UsersTable from './UsersTable/UsersTable';
import UserSearch from './UserSearch/UserSearch';
import { Container, Grid, makeStyles } from '@material-ui/core';
import PageHeader from '../../Core/PageHeader';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDeleteDialog from '../../Core/ConfirmDeleteDialog';
import { navigate } from '@reach/router';
import { userActions } from './UserState/UserSlice';
import { userActionsAsync } from './UserState/UserActionsAsync';
import { userSelectors } from './UserState/UserSelectors';
import { Pagination } from '@material-ui/lab';
import { currentUserSelectors } from '../../CurrentUser/CurrentUserState/CurrentUserSelector';
import UserFilter from './UserFilter/UserFilter';
import User from '../../Models/user';
import AccessDenied from '../../Core/AccessDenied';

const useStyles = makeStyles(theme => ({
	container: theme.container,
  paginationWrapper: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    margin: '16px 0 32px',
  } 
}));

export default function ManageUsersPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const users = useSelector(userSelectors.orderedUsersSelector);
  const userLevelFilter = useSelector(userSelectors.userLevelFilterSelector);
  const programFilter = useSelector(userSelectors.programFilterSelector);
  const searchTerm = useSelector(userSelectors.searchTermSelector);
  const selectedUser = useSelector(userSelectors.userSelectorById);
	const loadingUsers = useSelector(state => state.users.isLoading);
  const currentPage = useSelector(state => state.users.pageNumber);
  const totalPages = useSelector(userSelectors.totalPagesSelector);
  const currentUserGroup = useSelector(currentUserSelectors.userGroupSelector);
  const programs = useSelector(state => state.programs.list);

  const [orderAsc, setOrderAsc] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [search, setSearch] = useState('');

  const setOrderByParams = (orderByParam) => {
    setOrderAsc(!orderAsc); 
    dispatch(userActions.setOrderBy({ orderByParam, orderAsc }));
  }

  const handleSelectUser = user => {
    const params = { 
      userId: user.id, 
      route: 'users/' + user.id 
    }
    dispatch(userActions.selectUserAndNavigate(params));
  }

  const handleDeleteUserClick = (user) => {
    dispatch(userActions.selectUser(user.id));
    setDeleteDialogOpen(true);
  }

  const handleDeleteUserCancel = () => {
    dispatch(userActions.unselectUser());
    setDeleteDialogOpen(false);
  }

  const handleDeleteUserConfirm = () => {
    dispatch(userActionsAsync.deleteUser({ user: selectedUser })).then(() => {
      dispatch(userActions.unselectUser());
    });
    setDeleteDialogOpen(false);
  }

  const handlePageNumberChange = (page) => {
    dispatch(userActions.setPageNumber(page));
  }

  const handleUserLevelFilterChange = (value) => {
    setSearch('');
    dispatch(userActions.setUserLevelFilter(value))
  }

  const handleProgramFilterChange = (value) => {
    setSearch('');
    dispatch(userActions.setProgramFilter(value))
  }

  useEffect(() => {
    dispatch(userActionsAsync.getUsers());
  }, [
    currentPage, 
    userLevelFilter, 
    programFilter, 
    searchTerm, 
    dispatch
  ]);

	const headerButtons = [
		{
			buttonText: 'Add User',
			buttonAction: () => navigate("/users/new"),
		}
	]  

  if(currentUserGroup === "Admin") {
    return (
      <Fragment>
        <AppNav />
        <Container  maxWidth={false}  className={classes.container}>
          <PageHeader 
            headerText="User Administration"
            headerButtons={headerButtons}
          />

          <Grid container spacing={1}>
            <Grid item>
              <UserSearch
                search={search}
                onSearch={() => dispatch(userActions.setSearchTerm(search))}
                onSetSearch={(value) => setSearch(value)}
                onShowAll={() => dispatch(userActions.showAll())}
              />
            </Grid>
            <Grid item xs={3}>
              <UserFilter
                choices={User.userLevels.filter(level => level.userLevel !== '10')}
                defaultValueText="All User Levels"
                onChange={handleUserLevelFilterChange}
                value={userLevelFilter}
              />
            </Grid>
            <Grid item xs={3}>
              <UserFilter
                choices={programs}
                defaultValueText="All Programs"
                onChange={handleProgramFilterChange}
                value={programFilter}
              />
            </Grid>
          </Grid>

          <UsersTable 
            users={users} 
            loadingUsers={loadingUsers}
            onSetOrderByParams={setOrderByParams}
            onSelect={handleSelectUser}
            onDelete={handleDeleteUserClick}
            />

          { !!users?.length && 
            <div className={classes.paginationWrapper}>
              <Pagination 
                count={totalPages} 
                page={currentPage}
                onChange={(e, page) => handlePageNumberChange(page)}
                variant="outlined" 
                shape="rounded" 
                />
            </div>
          }
        </Container>

        <ConfirmDeleteDialog
          open={deleteDialogOpen}
          item={selectedUser?.name}
          itemType={'User'}
          onClose={handleDeleteUserCancel}
          onConfirm={handleDeleteUserConfirm}
         />

      </Fragment>
    )
  } else {
    return (
      <AccessDenied />
    )
  }
}

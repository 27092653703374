import { v4 as uuidv4 } from 'uuid';

export default class FormInstance {

    constructor(obj) {
        if (!obj) {
            obj = {};
        }

        this.id = obj.id || uuidv4();

        this.tenantId = obj.tenantId || null;

        this.type = obj.type || 'form';

        this.categoryId = obj.categoryId || null;

        this.categoryName = obj.categoryName || null;

        this.templateId = obj.templateId || null;

        this.templateName = obj.templateName || null;

        this.createdBy = obj.createdBy || null;

        this.lastUpdatedBy = obj.lastUpdatedBy || null;

        this.formElementInstances = obj.formElementInstances || [];
    }
}
import { 
  Button, 
  Collapse,
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Grid,
  makeStyles,
} from '@material-ui/core';
import { useState } from 'react';
import NewCustomFormCategory from '../../../../Core/NewCategoryDialog/NewCategory/NewCategory';
import CustomFormCategoryList from '../CustomFormCategoryList/CustomFormCategoryList';

const useStyles = makeStyles(theme => ({
	actionButton: theme.actionButton, 
  actionsRight: theme.actionsRight,
  manageDialogDelete: {
    '&:hover': {
      cursor: 'pointer',
    }
  },
  manageDialogList: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.borderRadius,
  },
  manageDialogAddNew: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.borderRadius,
    margin: '5px 0',
    padding: '10px 5px',
  },
}));


export default function ManageCustomFormCategoryDialog(props) {
  const { 
    categories, 
    open, 
    onClose, 
    onDelete,
    onSubmit,
  } = props;

  const classes = useStyles();

  const [isDeleteOptionIn, setIsDeleteOptionIn] = useState(false);
  const [localSelectedCategory, setLocalSelectedCategory] = useState(null);
  const [isAddNewOptionIn, setIsAddNewOptionIn] = useState(false);

  const handleDeleteClick = category => {
    setLocalSelectedCategory(category);
    setIsDeleteOptionIn(true);
    setIsAddNewOptionIn(false);
  }

  const handleDeleteConfirm = () => {
    onDelete(localSelectedCategory);
    setIsDeleteOptionIn(false);
  }

  const handleSubmit = newCategory => {
    onSubmit(newCategory);
    setIsAddNewOptionIn(false);
  }

  const handleAddNewClick = () => {
    setIsAddNewOptionIn(true);
    setIsDeleteOptionIn(false);
  }

  const handleClose = () => {
    setIsDeleteOptionIn(false);
    setLocalSelectedCategory(null);
    setIsAddNewOptionIn(false);
    onClose();
  }

  if(!!categories) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        scroll='paper'
        fullWidth={true}
        maxWidth={'sm'}
        aria-labelledby="Form Template Preview" 
        aria-describedby="Preview Form Template">
        <DialogTitle> 
          Manage Categories
        </DialogTitle>
        <DialogContent>
          <CustomFormCategoryList
            categories={categories}
            onDeleteClick={handleDeleteClick} />

          <Collapse className={classes.manageDialogAddNew} in={isAddNewOptionIn}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={10}>
                <NewCustomFormCategory onSubmit={handleSubmit} />
              </Grid>
              <Grid item xs={2}>
                <Button className={classes.actionButton} type="submit" form="add-category-form">
									Submit
								</Button>
              </Grid>
           	</Grid>
          </Collapse>
            
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Grid item xs={6}>
              { isDeleteOptionIn && 
                <Button
									className={classes.actionButton}
                	onClick={handleDeleteConfirm}>
									Delete Forever?
                </Button> 
							}
              </Grid>
              <Grid item xs={6} className={classes.actionsRight}>
                <Button className={classes.actionButton} onClick={handleAddNewClick}>Add New</Button>
                <Button className={classes.actionButton} onClick={handleClose}>Close</Button>    
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
    )
  } else {
    return null;
  }
}
import { 
  Container, 
  Paper,
  makeStyles, 
  Grid, 
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { templateSelectors } from '../Admin/CustomFormBuilder/CustomFormTemplates/TemplateState/TemplateSelectors';
import { templateActions } from '../Admin/CustomFormBuilder/CustomFormTemplates/TemplateState/TemplateSlice';
import { formInstanceActionsAsync } from './FormInstanceState/FormInstanceActionsAsync';
import FormBuilderElementProvider from '../Core/FormElementProvider/FormElementProvider';
import { Formik, Form } from 'formik';
import ValidationUtils from '../Utilities/validationUtils';
import FormInstanceUtils from './FormInstanceUtils/formInstanceUtils';
import { navigate } from '@reach/router';
import AppNav from '../Core/AppNav';
import { Fragment } from 'react';
import PageHeader from '../Core/PageHeader';
import FormInstanceActionButtons from './FormInstanceActionButtons/FormInstanceActionButtons';

const useStyles = makeStyles(theme => ({
  formRender: theme.formRender,
  centerTitle: theme.centerTitle,
	container: theme.container,
}));

export default function NewFormInstancePage(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { templateId } = props;

  let customFormSchema = {};
  let customFormInitialValues = {};

  const selectedTemplate = useSelector(templateSelectors.templateSelectorById);
  if(!selectedTemplate) {
    dispatch(templateActions.selectTemplate(templateId));
  }


  if(!!selectedTemplate) {
    customFormSchema = ValidationUtils.getyupSchema(selectedTemplate.formElements)

    customFormInitialValues = ValidationUtils.getInitialValues(selectedTemplate.formElements);
  }

  const handleSubmit = values => {
    const updatedElementInstances = FormInstanceUtils.mapValuesToFormElements(values, selectedTemplate.formElements);
    
    const params = {
      template: selectedTemplate,
      formElementInstances: updatedElementInstances,
    }

    dispatch(formInstanceActionsAsync.createInstance(params)).then(response => {
      const { templateId } = response.payload;
      navigate("/form-instances/" + templateId);
    });
  }

  const handlePrint = () => {
    window.print();
  }

	const handleCancel = () => {
		navigate('/form-instances/' + selectedTemplate.id);
	}
  
  if(!!selectedTemplate) {
  	return (
      <Fragment>
				<AppNav />
        <Container   maxWidth={false} className={classes.container}>
					<PageHeader headerText='New Form' />

          <Paper elevation={4} className={classes.formRender}>
            <Formik 
              initialValues={customFormInitialValues}
              validationSchema={customFormSchema}
              onSubmit={ async (values) => {
                handleSubmit(values);
              }}>{({
                isValid, 
                dirty,
                values,
                errors,
                touched,
                handleChange, 
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldError
              }) => {
                return (
                  <Form id="form-instance" noValidate autoComplete="off" onSubmit={handleSubmit}>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography variant="h3" className={classes.centerTitle}>{selectedTemplate.name}</Typography>
                        </Grid>
                      {selectedTemplate.formElements.map((element) => (
                        <Grid item xs={12} key={element.id}>
                          <FormBuilderElementProvider
                            formElement={element}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            setFieldError={setFieldError}
                            touched={touched[element.name]}
                            value={values[element.name]}
                            error={errors[element.name]}
                            name={element.name}
                            />
                        </Grid>
                      ))}
                      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

										<FormInstanceActionButtons
											disabled={!(isValid && dirty)}
											onPrint={handlePrint}
											onCancel={handleCancel}
										/>

                  </Grid>
                </Form>
              )}}
            </Formik>
          </Paper>
        </Container>
      </Fragment>
    )
  } else {
    return null;
  }
}
import {
  makeStyles, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import SortIcon from '../../../Core/SortIcon';
import LoadingSpinner from '../../../Core/Spinners/LoadingSpinner';
import DeleteIconButton from '../../../Core/DeleteIconButton';
import EditIconButton from '../../../Core/EditIconButton';
import DateUtils from '../../../Utilities/dateUtils';

const useStyles = makeStyles(theme => ({
  stripedTableRow: theme.stripedTableRow,
  tableHeader: theme.tableHeader,
	tableRow: theme.tableRow,
  actionButton: theme.actionButton,
  search: {
    display: 'flex',
    alignContent: 'center',
    marginBottom: 10,
    '& button': {
      marginTop: 5,
      marginBottom: 5,
      marginLeft: 5,
    }
  }
}));

export default function UsersTable(props) {
  const classes = useStyles();

  const { 
    users, 
		loadingUsers,
    onDelete,  
    onSelect, 
    onSetOrderByParams, 
  } = props;


  if (!!users && users.length > 0) {
    return (
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell width="20%">
              	Name
								<SortIcon
                  onSetOrderByParams={onSetOrderByParams}
                  sortParam='name'/>
              </TableCell>
              <TableCell width="30%">
                User Id
              	<SortIcon
                  onSetOrderByParams={onSetOrderByParams}
                  sortParam='userId'/>
              </TableCell>
              <TableCell>Program</TableCell>
              <TableCell>Created</TableCell>
              <TableCell align="center">Edit</TableCell>
              <TableCell align="center">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id} className={[classes.stripedTableRow, classes.tableRow].join(' ')}>
                <TableCell scope="row">
                    {user.name}
                </TableCell>
                <TableCell scope="row">
                    {user.userId}
                </TableCell>
                <TableCell scope="row">
                    {user.program}
                </TableCell>
                <TableCell scope="row">
                    {DateUtils.getDateString(user.addDt)}
                </TableCell>
                <TableCell align="center"> 
									<EditIconButton onClick={() => onSelect(user)} />
                </TableCell>
                <TableCell align="center">    
									<DeleteIconButton onClick={() => onDelete(user)} />        
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
	}	else if(loadingUsers) {
		return <LoadingSpinner />
	} else if(!loadingUsers && !!users && !users.length) {
    return <p>No Users found!</p>
  }
}
import { Fragment } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	actionsRight: theme.actionsRight,
	actionButton: theme.actionButton,
}));

export default function UserActionButtons(props) {
	const classes = useStyles();
	const { disabled, onCancel } = props;

	return (
		<Fragment>
      <Grid item xs={12} className={classes.actionsRight}>
		    <Button 
					size="small"
					className={classes.actionButton} 
					onClick={onCancel}
					type="button">
						Cancel
				</Button>
		    <Button 
					size="small"
					className={classes.actionButton} 
					disabled={disabled} 
					type="submit">
						Submit
				</Button>
		  </Grid>
		</Fragment>
	)
}
import { Fragment } from 'react';
import { purchaseOrderActionsAsync } from './PurchaseOrderState/PurchaseOrderActionsAsync';
import PurchaseOrder from '../Models/purchaseOrder';
import PurchaseOrderLineItem from '../Models/purchaseOrderLineItem';
import { useDispatch } from 'react-redux';
import RenderPurchaseOrder from './RenderPurchaseOrder';
import { navigate } from '@reach/router';
import AppNav from '../../Core/AppNav';


export default function NewPurchaseOrderPage(props) {
  const dispatch = useDispatch();

  const newPurchaseOrder = new PurchaseOrder({
    lineItems: [new PurchaseOrderLineItem()],
  });

  const handleNewPurchaseOrderSubmit = purchaseOrder => {
    const params = {
      purchaseOrder,
    };

    dispatch(purchaseOrderActionsAsync.createPurchaseOrderAction(params)).then(response => {  
      navigate('/purchase-orders');
    });
  };

  if(!!newPurchaseOrder) {
    return (
			<Fragment>
				<AppNav />
	      <RenderPurchaseOrder 
	        purchaseOrder={newPurchaseOrder}
	        onSubmit={handleNewPurchaseOrderSubmit}
	        title="Create Purchase Order"
	      />
			</Fragment>
    )
  } else {
    return null;
  }
}
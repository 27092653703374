import { Grid, makeStyles, Typography, } from '@material-ui/core';
import PropUtils from '../../../../Utilities/propUtils';

const useStyles = makeStyles(theme => ({
    canvasTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: 16,
        borderRadius: `${theme.borderRadius}px ${theme.borderRadius}px 0 0`,
    },
}));


export default function FormBuilderCanvasTitle(props) {
    const { template } = props;
    const classes = useStyles();

    if(!!template) {
        return (
            <Grid container alignItems="center" className={classes.canvasTitle}>
                <Grid item xs={6}>
                    <Typography component="p">Title: {template.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography component="p">Category: {template.categoryName}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography component="p">Type: {PropUtils.getDisplayFormat(template.type)}</Typography> 
                </Grid>

             </Grid>
        )
    } else {
        return null;
    }
 
}
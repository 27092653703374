import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { 
    templatesByTenantId, 
} from '../../../../graphql/queries';
import {
    createCustomFormTemplate,
    deleteCustomFormTemplate,
    updateCustomFormTemplate,
} from '../../../../graphql/mutations';
import CustomFormTemplate from '../../../../Models/customFormTemplate';
import FormElementUtils from '../../../../Utilities/formElementUtils';
import * as utils from '../../../../Utilities/reducerUtils';
import { v4 as uuidv4 } from 'uuid';
import { getCurrentTenant } from '../../../../CurrentUser/CurrentUserState/CurrentUserSelector';


const addFormElementToTemplate = createAsyncThunk(
    'templates/addFormElementToTemplate',
    async (params, { getState }) => {
        const { template, type } = params;

        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const newElementPosition = FormElementUtils.getNewElementPositionOnAdd({getState: getState})
        
        const newElementParams = {
            type: type, 
            position: newElementPosition,
            name: type + template.formElements.length,
        }

        const newFormElement = FormElementUtils.castToRelevantClass(newElementParams);

        const updatedTemplate = new CustomFormTemplate({
            ...template,
            lastUpdatedBy: getState().currentUser.userId,
            formElements: [...template.formElements, newFormElement],
        });

        const response = await API.graphql({
            query: updateCustomFormTemplate,
            variables: { input: updatedTemplate },
            authMode: authMode,
        });
        return response.data.updateCustomFormTemplate;
    });

const cloneTemplate = createAsyncThunk(
    'templates/cloneTemplate',
    async (params, { getState }) => {
        const { 
          originalTemplate, 
          templateName, 
          categoryId,
          categoryName,
        } = params; 
        
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;
        const tenantId = getCurrentTenant(currentUser);

        const clonedFormElements = originalTemplate.formElements.map(element => {
            return {
                ...element,
                id: uuidv4()
            }
        });

        const input = new CustomFormTemplate({
            name: templateName,
            type: originalTemplate.type,
            categoryId: categoryId,
            categoryName: categoryName,
            tenantId: tenantId,
            createdBy: currentUser.userId,
            formElements: clonedFormElements,
        });

        const response = await API.graphql({
            query: createCustomFormTemplate,
            variables: { input: input },
            authMode: authMode,
        });
        
        return response.data.createCustomFormTemplate;
    });

const createTemplate = createAsyncThunk(
    'templates/createTemplate',
    async (params, { getState }) => {
        const { newTemplate } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;
        const tenantId = getCurrentTenant(currentUser);


        const input = {
            ...newTemplate, 
            tenantId: tenantId,
            createdBy: currentUser.userId,
        }

        const response = await API.graphql({
            query: createCustomFormTemplate,
            variables: { input: input },
            authMode: authMode,
        });
        
        return response.data.createCustomFormTemplate;
    });

const deleteTemplate = createAsyncThunk(
    'templates/deleteTemplate',
    async (params, { getState }) => {
        const { template } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const response = await API.graphql({
            query: deleteCustomFormTemplate,
            variables: { input: { id: template.id } },
            authMode: authMode,
        });
        
        return response.data.deleteCustomFormTemplate;
    });

const loadTemplates = createAsyncThunk(
    'templates/loadTemplates',
    async (params, { getState }) => {
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;
        const tenantId = getCurrentTenant(currentUser);

        const response = await API.graphql({
            query: templatesByTenantId,
            variables: { tenantId: tenantId },
            authMode: authMode,
        });

        return response.data.templatesByTenantId.items;
    });


const removeFormElementFromTemplate = createAsyncThunk(
    'templates/removeFormElementFromTemplate',
    async (params, { getState }) => {
        const { template, formElement } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const updatedTemplate = new CustomFormTemplate({
            ...template, 
            lastUpdatedBy: getState().currentUser.userId,
            formElements: utils.deleteItemFromList(template.formElements, formElement),
        });

        const response = await API.graphql({
            query: updateCustomFormTemplate,
            variables: { input: updatedTemplate },
            authMode: authMode,
        });

        return response.data.updateCustomFormTemplate;
    });
    
const updateFormElement = createAsyncThunk(
    'templates/updateFormElement',
    async (params, { getState }) => {
        const { template, formElement } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const updatedTemplate = new CustomFormTemplate({
            ...template,
            lastUpdatedBy: getState().currentUser.userId,
            formElements: utils.updateItemInList(template.formElements, formElement),
        });

        const response = await API.graphql({
            query: updateCustomFormTemplate,
            variables: { input: updatedTemplate },
            authMode: authMode,
        });

        return response.data.updateCustomFormTemplate;
    });

const updateFormElementPosition = createAsyncThunk(
    'templates/updateFormElementPosition',
    async (params, { getState }) => {
        const { template, source } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const newElementPosition = FormElementUtils.getNewElementPositionOnDrag(params);

        const draggedElement = template.formElements.find(element => element.id === source.droppableId);

        const updatedFormElement = FormElementUtils.castToRelevantClass({
            ...draggedElement,
            position: newElementPosition,
        });

        const updatedTemplate = new CustomFormTemplate({
            ...template,
            lastUpdatedBy: getState().currentUser.userId,
            formElements: utils.updateItemInList(template.formElements, updatedFormElement),
        });

        const response = await API.graphql({
            query: updateCustomFormTemplate,
            variables: { input: updatedTemplate },
            authMode: authMode,
        });

        return response.data.updateCustomFormTemplate;
    });

const updateTemplate = createAsyncThunk(
    'templates/updateTemplate',
    async (params, { getState }) => {
        const { updatedTemplate } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const input = {
            ...updatedTemplate,
            lastUpdatedBy: getState().currentUser.userId,
        }
        const response = await API.graphql({
            query: updateCustomFormTemplate,
            variables: { input: input },
            authMode: authMode,
        });

        return response.data.updateCustomFormTemplate;
    });


export const templateActionsAsync = {
    addFormElementToTemplate,
    loadTemplates,
    cloneTemplate,
    createTemplate,
    deleteTemplate,
    removeFormElementFromTemplate,
    updateFormElement,
    updateFormElementPosition,
    updateTemplate,
};
import {
    Box, 
    Table,
    TableBody,
} from '@material-ui/core';
import NestedFormInstanceTableRow from './NestedFormInstanceTableRow/NestedFormInstanceTableRow';



export default function NestedFormInstanceTable(props) {
  const { category } = props;
  
  if(category.templates.length > 0) {
    return (
      <Box margin={1}>
        <Table size="small" aria-label="templates">
        <TableBody>
          {category.templates.map((template) => (
            <NestedFormInstanceTableRow
              key={template.id}
              template={template}
              {...props}
             />
          ))}
        </TableBody>
        </Table>
      </Box>
    )
  } else {
    return null;
  }
}
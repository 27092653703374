import { FormControl, makeStyles, TextField } from '@material-ui/core';
import ElementLabel from './ElementLabel';

const useStyles = makeStyles(theme => ({
    textInputWidth: theme.minInputWidth,
}));


export default function TextInput(props) {
    const classes = useStyles();
    const { 
        error, 
        name, 
        onChange, 
        onBlur, 
        touched,
        value,
        formElement: {
            labelText = {},
            multiLine = {},
            numbersOnly = {},
            required = {},
        },
    } = props;

    const isError = Boolean(error && touched);

    return (
        <FormControl className={classes.textInputWidth} fullWidth={multiLine}>

            <ElementLabel 
                labelText={labelText}
                component='div'
                required={required}/>

            <TextField 
                multiline={multiLine}
                fullWidth
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={isError}
                helperText={isError ? error : null}
                color="secondary"
                InputProps={
                    {type: numbersOnly ? 'number' : 'text'}
                }
            /> 
        </FormControl>
    )
}
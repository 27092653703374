import { 
  FormControl, 
  InputLabel,
  MenuItem, 
  Select 
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { programSelectors } from '../../../Programs/ProgramSelectors';

export default function ProgramSelector(props) {
  const {     
    label, 
    name, 
    value, 
    onChange, 
    onBlur,
    selectMultiple,
  } = props;

  const choices = useSelector(programSelectors.programSelectorAll);

  if(selectMultiple) {
    return (
      <FormControl fullWidth>

      <InputLabel shrink>
        { label }
      </InputLabel>

      <Select
        name={name}
        value={value ? String(value).split(',').filter(value => value !== "") : []}
        onChange={onChange}
        multiple={selectMultiple}
        onBlur={onBlur}>
          <MenuItem value="">-Select-</MenuItem>
          { choices.map((choice) => (
            <MenuItem key={choice.id} value={choice.id}>{choice.program}</MenuItem>
          ))}
      </Select>
    </FormControl>
    )
  } else {
    return (
      <FormControl fullWidth>

      <InputLabel shrink>
        { label }
      </InputLabel>

      <Select
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}>
          <MenuItem value="">-Select-</MenuItem>
          { choices.map((choice) => (
            <MenuItem key={choice.id} value={choice.id}>{choice.program}</MenuItem>
          ))}
      </Select>
    </FormControl>
    )
  }
}
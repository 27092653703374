import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import awsmobile from '../../../aws-exports';
import { getCurrentTenant } from '../../../CurrentUser/CurrentUserState/CurrentUserSelector';

const apiName = awsmobile.aws_cloud_logic_custom[0].name;

const getSuppliersByTenantId = createAsyncThunk(
  'suppliers/getSuppliersByTenantId',
  async (params, { getState }) => {
    const tenantId = getCurrentTenant(getState().currentUser);

    return await API.get(apiName, '/suppliers/' + tenantId, {});
  }
);

export const supplierActionsAsync = {
  getSuppliersByTenantId
}
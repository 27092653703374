import { createSlice } from '@reduxjs/toolkit';
import { templateActionsAsync } from './TemplateActionsAsync';
import { navigate } from '@reach/router';
import * as utils from '../../../../Utilities/reducerUtils';
const {
    addFormElementToTemplate,
    loadTemplates,
    cloneTemplate, 
    createTemplate,
    deleteTemplate,
    removeFormElementFromTemplate,
    updateFormElement,
    updateFormElementPosition,
    updateTemplate,
} = templateActionsAsync;


export const TemplateSlice = createSlice({
    name: 'templates',
    initialState: {
        list: [],
        selectedFormElementId: null,
        selectedTemplateId: null,
    },
    reducers: {
        selectTemplate(state, action) {
            state.selectedTemplateId = action.payload;
        },
        selectTemplateAndNavigate(state, action) {
            if(!!action.payload) {
                navigate(action.payload.route);
                state.selectedTemplateId = action.payload.templateId;
            }
        },
        selectFormElement(state, action) {
            state.selectedFormElementId = action.payload;
        },
        unselectTemplate(state, action) {
            state.selectedTemplateId = null;
        }
    },
    extraReducers: {
        [addFormElementToTemplate.fulfilled]: (state, action) => {
            const updated = action.payload;
            state.list = utils.updateItemInList(state.list, updated);
        },
        [loadTemplates.fulfilled]: (state, action) => {
            state.list = action.payload;
        },
        [cloneTemplate.fulfilled]: (state, action) => {
            const cloned = action.payload;
            state.list = [...state.list, cloned]
        },
        [createTemplate.fulfilled]: (state, action) => {
            const created = action.payload;
            state.list = [...state.list, created]
        },
        [deleteTemplate.fulfilled]: (state, action) => {
            const deleted = action.payload;
            state.list = utils.deleteItemFromList(state.list, deleted);
        },
        [removeFormElementFromTemplate.fulfilled]: (state, action) => {
            const updated = action.payload;
            state.list = utils.updateItemInList(state.list, updated);
        },
        [updateFormElement.fulfilled]: (state, action) => {
            const updated = action.payload;
            state.list = utils.updateItemInList(state.list, updated);
        },
        [updateFormElementPosition.fulfilled]: (state, action) => {
            const updated = action.payload;
            state.list = utils.updateItemInList(state.list, updated);
        },
        [updateTemplate.fulfilled]: (state, action) => {
            const updated = action.payload;
            state.list = utils.updateItemInList(state.list, updated);
        },
    }
});

export const templateActions = { 
    selectFormElement: TemplateSlice.actions.selectFormElement,
    selectTemplate:  TemplateSlice.actions.selectTemplate,
    selectTemplateAndNavigate: TemplateSlice.actions.selectTemplateAndNavigate,
    unselectTemplate: TemplateSlice.actions.unselectTemplate,
}

export default TemplateSlice.reducer;
import { TextField } from "@material-ui/core";
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogTitle, 
  DialogContent, 
  DialogContentText,
	makeStyles,
} from "@material-ui/core";
import { Formik, Form } from 'formik';
import * as yup from 'yup';


const useStyles = makeStyles(theme => ({
	actionButton: theme.actionButton,
}));


export default function ChangePasswordDialog(props) {
	const classes = useStyles();

  const { open, onClose, onSubmit } = props;

  const changePasswordSchema = yup.object().shape({
    oldPassword: yup.string()
    .required('Required'),
    newPassword: yup.string().required('Required')
      .min(6, 'Password must be at least 6 characters'),
    newPasswordConfirm: yup.string().required('Required')
      .test('passwordsMatch', 'Passwords must match', function(value){
        return this.parent.newPassword === value
      }),
  });

  return (
    <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="change-password-dialog-title"
    aria-describedby="change-password-dialog-description"
    >
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter your current password and then choose a new password.
        </DialogContentText>
        <Formik
          initialValues={{ 
            oldPassword: '',
            newPassword: '',
            newPasswordConfirm: ''
          }}
          validationSchema={changePasswordSchema}
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >{({
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          values,
        }) => {
        return (
          <Form noValidate id="change-password-form" autoComplete="off" onSubmit={handleSubmit}>

            {/* <pre>{JSON.stringify(values, null, 5)}</pre>
            <pre>{JSON.stringify(errors, null, 5)}</pre> */}
            
            <TextField
              name="oldPassword"
              id="oldPassword"
              label="Old Password"
              value={values.oldPassword || ''}
              error={Boolean(errors.oldPassword && touched.oldPassword)}
              helperText={errors.oldPassword && touched.oldPassword ? errors.oldPassword : ''}
              type="password"
              fullWidth
              required
              onBlur={handleBlur}
              onChange={handleChange}
              />

            <TextField
              name="newPassword"
              id="newPassword"
              label="New Password"
              value={values.newPassword || ''}
              error={Boolean(errors.newPassword && touched.newPassword)}
              helperText={errors.newPassword && touched.newPassword ? errors.newPassword : ''}
              type="password"
              fullWidth
              required
              onBlur={handleBlur}
              onChange={handleChange}
              />

            <TextField
              name="newPasswordConfirm"
              id="newPasswordConfirm"
              label="Confirm New Password"
              value={values.newPasswordConfirm || ''}
              error={Boolean(errors.newPasswordConfirm && touched.newPasswordConfirm)}
              helperText={errors.newPasswordConfirm && touched.newPasswordConfirm ? errors.newPasswordConfirm : ''}
              type="password"
              fullWidth
              required
              onBlur={handleBlur}
              onChange={handleChange}
              />

          </Form>)}}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button 
          className={classes.actionButton} 
          onClick={onClose} 
          color="primary">
          Cancel
        </Button>
        <Button 
          type="submit" 
          form="change-password-form"
          className={classes.actionButton} 
          onSubmit={onSubmit} 
          color="primary" 
          autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
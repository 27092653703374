import {
    makeStyles, 
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import DateUtils from '../../Utilities/dateUtils';
import EditIconButton from '../../Core/EditIconButton';
import DeleteIconButton from '../../Core/DeleteIconButton';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    marginBottom: 16,
  },
  tableRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableHeader: theme.tableHeader,
}));


export default function PurchaseOrderTable(props) {
    const classes = useStyles();

    const { purchaseOrders, onDelete, onSelect } = props;

    if (!!purchaseOrders && purchaseOrders.length > 0) {
    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell>Number</TableCell>
              <TableCell>Supplier</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>LastUpdated</TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {purchaseOrders.map((purchaseOrder) => (
            <TableRow key={purchaseOrder.id} className={classes.tableRow} >
              <TableCell width="10%" component="th" scope="row">
                <Typography component="div">
                  {purchaseOrder.number}
                </Typography>
              </TableCell>       
              <TableCell width="10%" component="th" scope="row">
                <Typography component="div">
                  {purchaseOrder.supplierName}
                </Typography>
              </TableCell>       
              <TableCell width="10%" component="th" scope="row">
                <Typography component="div">
                  {purchaseOrder.status}
                </Typography>
              </TableCell>       
              <TableCell width="10%" component="th" scope="row">
                <Typography component="div">
                  {purchaseOrder.total}
                </Typography>
              </TableCell>       
              <TableCell width="10%" component="th" scope="row">
                <Typography component="div">
                  {DateUtils.getDateString(purchaseOrder.createdAt)}
                </Typography>
              </TableCell>       
              <TableCell width="10%" component="th" scope="row">
                <Typography component="div">
                  {DateUtils.getDateString(purchaseOrder.updatedAt)}
                </Typography>
              </TableCell>
                <TableCell width="70%" component="th" scope="row">
                <Typography component="div">
                  {purchaseOrder.createdBy}
                </Typography>
              </TableCell>      
              <TableCell align="right">   
								<EditIconButton onClick={() => onSelect(purchaseOrder)} />       
              </TableCell>               
              <TableCell align="right">       
								<DeleteIconButton  onClick={() => onDelete(purchaseOrder)} />
              </TableCell>
            </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  } else return (
    <i>No purchase orders have been created yet.</i>
  );
}
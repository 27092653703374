import { FieldArray } from 'formik';
import { Checkbox, FormControlLabel, FormLabel, FormGroup } from '@material-ui/core';
import { Fragment, useEffect } from 'react';

export default function LocationMultiSelector(props) {
  const { 
    choices, 
    error, 
    label, 
    name, 
    selectAll, 
    selectAllName,
    setFieldValue, 
    values 
  } = props;

  const locations = !!values.locations ? values.locations : [];

  useEffect(() => {
    const fullArr = choices.map(choice => choice.id);

    if(selectAll) {
      setFieldValue(name, fullArr, true);
    }
    
  }, [selectAll, setFieldValue, choices, name]);


  if(!!choices && choices.length) {
    return (
      <Fragment>
        <FormLabel required error={error}>{label}</FormLabel>

        <FieldArray
          name={name}
          render={(arrayHelpers) => (
            <FormGroup>
              <FormControlLabel label="All"
              control={ <Checkbox 
                color="primary" 
                checked={selectAll} 
                onChange={() => {
                  setFieldValue(selectAllName, !selectAll, false);
                  if(selectAll) {
                    setFieldValue(name, [], true);
                  }
              }}/>}
              />
              { choices.map((choice) => (
                <FormControlLabel key={choice.id} label={choice.vessel_name || choice.location}
                  control={ 
                  <Checkbox 
                    color="primary"
                    checked={locations.includes(choice.id)} 
                    onChange={() =>  {
                      if(selectAll && choices.length !== locations.length) {
                        setFieldValue(selectAllName, false, true);
                      }

                      if(locations.includes(choice.id)) {
                        const found = locations.findIndex(item => item === choice.id);
                        arrayHelpers.remove(found);
                      } else {
                        arrayHelpers.push(choice.id);
                      }
                    }} 
                  />} 
                />
              ))}
            </FormGroup>
          )}
        ></FieldArray>
      </Fragment>
    )
  } else {
    return null;
  }
}
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
	makeStyles,
} from '@material-ui/core';
import NewCategory from './NewCategory/NewCategory';

const useStyles = makeStyles(theme => ({
	actionButton: theme.actionButton,
}));

export default function NewCategoryDialog(props) {
	const classes = useStyles();
  const { categoryType, open, onClose, onSubmit } = props;

  const handleSubmit = newCategory => {
    onSubmit(newCategory);
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll='paper'
      fullWidth={true}
      maxWidth = {'sm'}
      aria-labelledby={`New ${ categoryType } Form Category`}
      aria-describedby={`Add ${ categoryType } Custom Form`}>
      <DialogTitle>Add New { categoryType } Form Category</DialogTitle>
      <DialogContent>
        <NewCategory categoryType={categoryType} onSubmit={handleSubmit} />
      </DialogContent>
      <DialogActions>
        <Button className={classes.actionButton} onClick={onClose}>Cancel</Button>
        <Button className={classes.actionButton} type="submit"  form="add-category-form">Add</Button>
      </DialogActions>
    </Dialog>
  )
}
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import CustomFormCategory from '../../../Models/customFormCategory';
import SystemFormCategory from '../../../Models/systemFormCategory';
import { useRef } from 'react';
import { TextField } from '@material-ui/core';


export default function NewCustomFormCategory(props) {
    const { categoryType, onSubmit } = props;

    const getCategoryConstructor = () => {
      return categoryType === "Custom" ? new CustomFormCategory() : new SystemFormCategory();
    }

    const newCategory = getCategoryConstructor()

    const formikRef = useRef(null)

    const categorySchema = yup.object().shape({
      name: yup.string()
        .required('Required'),
    });

    return (
        <Formik
            innerRef={formikRef}
            initialValues={newCategory}
            validationSchema={categorySchema}
            onSubmit={ async (values) => {
                onSubmit(values);
                formikRef.current.resetForm({
                    values: {...getCategoryConstructor()}
                });
            }}
            >{({
                values,
                errors,
                touched,
                handleChange, 
                handleBlur,
                handleSubmit
            }) => {
            return (
              <Form id="add-category-form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <TextField 
                      name="name" 
                      label="Name" 
                      error={Boolean(errors.name && touched.name)}
                      helperText={errors.name}
                      value={values.name} 
                      onChange={handleChange} 
                      onBlur={handleBlur}
                      fullWidth
                      autoFocus
                      required />
                </Form>
            )}}
        </Formik>
    )
}
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { 
    listSystemFormCategorys,
} from '../../../../graphql/queries';
import {
    createSystemFormCategory,   
    deleteSystemFormCategory, 
} from '../../../../graphql/mutations';

export const loadSystemCategories = createAsyncThunk(
    'systemCategories/loadSystemCategories',
    async (params, { getState }) => {
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const response = await API.graphql({
            query: listSystemFormCategorys,
            authMode: authMode,
        });
        return response.data.listSystemFormCategorys.items;
    });

export const createSystemCategory = createAsyncThunk(
    'systemCategories/createSystemCategory',
    async (params, { getState }) => {
        const { newCategory } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const input = {
            ...newCategory,
        }

        const response = await API.graphql({
            query: createSystemFormCategory,
            variables: { input: input },
            authMode: authMode,
        });

        return response.data.createSystemFormCategory;
    });

export const deleteSystemCategory = createAsyncThunk(
    'systemCategories/deleteSystemCategory',
    async (params, { getState }) => {
        const { category } = params;
        const currentUser = getState().currentUser;
        const { authMode } = currentUser;

        const response = await API.graphql({
            query: deleteSystemFormCategory,
            variables: { input: { id: category.id } },
            authMode: authMode,
        });

        return response.data.deleteSystemFormCategory;
    });
    
export const systemCategoryActionsAsync = {
    loadSystemCategories,
    createSystemCategory,
    deleteSystemCategory,
}
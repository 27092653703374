import { 
  FormControl, 
  InputLabel,
  MenuItem, 
  Select 
} from '@material-ui/core';
import AddressUtils from '../../../Utilities/addressUtils';

export default function CountrySelector(props) {
  const { 
    label, 
    name, 
    value, 
    onChange, 
    onBlur 
  } = props;

  const choices = AddressUtils.availableCountries;

  return (
    <FormControl fullWidth>

    <InputLabel shrink >
      { label }
    </InputLabel>

    <Select
      name={name}
      value={value || ''}
      onChange={onChange}
      displayEmpty
      onBlur={onBlur}>
        <MenuItem value="">-Select-</MenuItem>
        { choices.map(choice => (
          <MenuItem key={choice.country} value={choice.country}>{choice.country}</MenuItem>
        ))}

    </Select>
  </FormControl>
  )
}
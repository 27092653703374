import { 
    Container, 
    Typography, 
    makeStyles, 
    Grid, 
    TextField,
    Button,
  } from '@material-ui/core';
  import { Formik, Form, ErrorMessage } from 'formik';
  import * as yup from 'yup';
  import { Fragment } from 'react';
  import PurchaseOrderStatusSelector from './PurchaseOrderStatusSelector';
  import Address from '../../Core/FormInputs/Address';
  import PaymentTermsSelector from './PaymentTermsSelector';
  import LineItemSelector from './LineItemSelector';
  import { navigate } from '@reach/router';
  import SupplierSelector from './../Suppliers/SupplierSelector/SupplierSelector';
  
  const useStyles = makeStyles(theme => ({
    error: theme.error,
    actionsContainer: {
      textAlign: 'right',
    },
		container: theme.container,
    header: theme.pageHeader,
  }));
  
  
  export default function RenderPurchaseOrder(props) {
    const classes = useStyles();
    const { purchaseOrder, onSubmit, title } = props;
    
    const lineItemSchema = yup.object().shape({
      item: yup.string().required('Required'),
      quantity: yup.number().required('Required'),
      unitCost: yup.number().required('Required'),
    });

    const purchaseOrderSchema = yup.object().shape({
      shippingCost: yup.string()
        .required('Required'),
      tax: yup.string()
        .required('Required'),
      lineItems: yup.array().of(lineItemSchema),
    });

    return (
      <Fragment>
        <Container  maxWidth={false} className={classes.container}>
          <Grid container className={classes.header} alignItems="center" justifyContent="center">
            <Grid item xs={9}>
              <Typography variant="h2">{title}</Typography>
            </Grid>
            <Grid item xs={3} className={classes.actionsContainer}>
            <Button className={classes.button} onClick={() => navigate(-1)}>Back</Button> 
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12}>
              <Formik
                initialValues={purchaseOrder}
                validationSchema={purchaseOrderSchema}
                onSubmit={ async (values) => {
                  onSubmit(values);
                }}>{({
                  isValid, 
                  dirty,
                  values,
                  errors,
                  touched,
                  handleChange, 
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <Form id="new-template-form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                      <Grid container alignItems="flex-start" spacing={3}>
                        <Grid item md={4}>
                          <Typography variant="h3">Supplier Information</Typography>
                          <SupplierSelector 
                            name="supplier" 
                            value = {values.supplierId || ''}
                            onChange={(e) => {
                              const supplier = e.target.value;
                              if(!!supplier) {
                                setFieldValue('supplierId', supplier.id, false);
                                setFieldValue('supplierName', supplier.name, false);
                                setFieldValue('supplierPhoneNumber', supplier.phoneNumber, false);
                                setFieldValue('supplierAddress', supplier.address, false);
                              }
                            }}
                            onBlur={handleBlur}
                            fullWidth />
                        </Grid>
  
                        <Grid item md={4}>
                          <Typography variant="h3">Ship-To Information</Typography>
                          <TextField 
                            name="shipToContactName" 
                            label="Contact Name" 
                            error={Boolean(errors.shipToContactName && touched.shipToContactName)}
                            value={values.shipToContactName || ''} 
                            onChange={handleChange} 
                            onBlur={handleBlur}
                            fullWidth
                            required />
                          <ErrorMessage
                            className={classes.error}
                            name="shipToContactName"
                            component="p" />
  
                          <TextField 
                            name="shipToPhoneNumber" 
                            label="Contact Phone" 
                            error={Boolean(errors.shipToPhoneNumber && touched.shipToPhoneNumber)}
                            value={values.shipToPhoneNumber || ''} 
                            onChange={handleChange} 
                            onBlur={handleBlur}
                            fullWidth
                            required />
                          <ErrorMessage
                            className={classes.error}
                            name="shipToPhoneNumber"
                            component="p" />
  
                          <Address 
                            name="shipToAddress"
                            value={values.shipToAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            classes={classes} />
                        </Grid>
  
                        <Grid item md={4}>
                          <Typography variant="h3">Status &amp; Terms</Typography>
  
                          <PurchaseOrderStatusSelector 
                            name="status" 
                            value={values.status}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth />
  
                          <ErrorMessage
                            className={classes.error}
                            name="status"
                            component="p" />

                            <br></br><br></br>
                          <PaymentTermsSelector 
                            name="paymentTerms" 
                            value={values.paymentTerms || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth />
                          
                          <ErrorMessage
                            className={classes.error}
                            name="paymentTerms"
                            component="p" />
                        </Grid>

                        {/* <pre>{JSON.stringify(values, null, 5)}</pre>
                        <pre>{JSON.stringify(errors, null, 5)}</pre> */}
  
                        <Grid item xs={12}>
                        <Typography variant="h3">Line Items</Typography>
                          <LineItemSelector
                            lineItems={values.lineItems}
                            errors={errors}
                            touched={touched}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
  
                        </Grid>
  
                        <Grid item xs={4}>
                          <TextField 
                            name="shippingCost" 
                            label="Shipping" 
                            error={Boolean(errors.shippingCost && touched.shippingCost)}
                            value={values.shippingCost } 
                            onChange={handleChange} 
                            onBlur={handleBlur}
                            fullWidth
                            required />
                          <ErrorMessage
                            className={classes.error}
                            name="shippingCost"
                            component="p" />
                        </Grid>
  
                        <Grid item xs={4}>
                          <TextField 
                            name="tax" 
                            label="Tax" 
                            error={Boolean(errors.tax && touched.tax)}
                            value={values.tax} 
                            onChange={handleChange} 
                            onBlur={handleBlur}
                            fullWidth
                            required />
                          <ErrorMessage
                            className={classes.error}
                            name="tax"
                            component="p" />
                        </Grid>
                        
                        <Grid item xs={4}>
                          <TextField 
                            name="total" 
                            label="Total" 
                            value={values.total} 
                            onChange={handleChange} 
                            onBlur={handleBlur}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }} />
                        </Grid>
  
                        <Grid item xs={12} className={classes.actionsContainer}>
                          <Button disabled={!(isValid && dirty)} type="submit">Save</Button>
                        </Grid>
                      </Grid>
                    </Form>)}}
              </Formik>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    )
  }
import { Fragment } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	actionsRight: theme.actionsRight,
	actionButton: theme.actionButton,
}));

export default function FormInstanceActionButtons(props) {
	const classes = useStyles();
	const { disabled, onPrint, onCancel } = props;
	
	return (
		<Fragment>
	    <Grid item xs={6}>
				  <Button
					className={classes.actionButton}  onClick={onCancel}>
						Cancel
					</Button>
			</Grid>
			<Grid item xs={6} className={classes.actionsRight}>
				<Button 
					className={classes.actionButton}
					disabled={disabled} 
					type="submit">
						Save
				</Button>
				{' '}
				<Button 
					className={classes.actionButton} 
					onClick={onPrint}
					disabled={disabled}
					type="submit">
						Save And Print
				</Button>
			</Grid>
		</Fragment>
	)
}
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    alert: {
        marginBottom: 10,
    }
}));

export default function AlertMessage(props) {
    const classes = useStyles();
    const { message, onClose, severity } = props;

    return (
        <Alert 
          className={classes.alert}
          onClose={onClose} 
          severity={severity}
          >{message}</Alert>
    )
}
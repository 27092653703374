import { Tooltip, IconButton } from "@material-ui/core";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

export default function DeleteIconButton(props) {
	const { onClick } = props;

	return (
		<Tooltip title="Delete">
			<IconButton
				size="small" 
				onClick={onClick}>
				<DeleteForeverIcon />
			</IconButton>
		</Tooltip>
	)
}
import { createSelector } from 'reselect';
import FormInstanceUtils from '../FormInstanceUtils/formInstanceUtils';

const instanceSelectorAll = createSelector(
    state => state.formInstances.list,
    (formInstances) => formInstances,
)

const instanceSelectorById = createSelector(
    state => state.formInstances.list,
    state => state.formInstances.selectedFormInstanceId,
    (formInstances, selectedFormInstanceId) => {
        if (!!(formInstances && formInstances.length) && !!selectedFormInstanceId) {
            return formInstances.find(instance => instance.id === selectedFormInstanceId);
        }
        return null;
    }
)

const instanceSelectorByTemplate = createSelector(
    state => state.formInstances.list,
    state => state.templates.selectedTemplateId,
    (formInstances, selectedTemplateId) => {
        if(!!(formInstances && formInstances.length) && !!selectedTemplateId) {
            return formInstances.filter(instance => instance.templateId === selectedTemplateId );
        } 
        return null;
    }
)

const instancesPerTemplate = createSelector(
    state => state.formInstances.list,
    state => state.templates.list,
    (instances, templates) => {
        if((!!instances) && (!!templates && templates.length)) {
            const foundInstances = [];

            templates.forEach(template => {

                const matchedInstances = instances.filter(instance => instance.templateId === template.id);

                const templateObj = {
                    name: template.name,
                    id: template.id,
                    instances: matchedInstances,
                }

                foundInstances.push(templateObj);
            });
            return foundInstances;
        }
        return null;
    }
)

const orderedInstanceSelector = createSelector(
    instanceSelectorByTemplate,
    state => state.formInstances.orderBy,
    (instances, orderBy) => {
        if(!!instances && !!orderBy) {
            if (orderBy.orderByParam === 'date') {
                return orderBy.orderAsc ? FormInstanceUtils.sortDateAscending(instances) : FormInstanceUtils.sortDateDescending(instances);              
            } else if (orderBy.orderByParam === 'loggedBy') {
                return orderBy.orderAsc ? FormInstanceUtils.sortNameAscending(instances) : FormInstanceUtils.sortNameDescending(instances);
            } else
            return instances;
        }
        return instances;
    }
)


export const formInstanceSelectors = {
    instancesPerTemplate,
    instanceSelectorAll,
    instanceSelectorByTemplate,
    instanceSelectorById,
    orderedInstanceSelector,
}
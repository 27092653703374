import { useDispatch, useSelector } from 'react-redux';
import { userActionsAsync } from './UserState/UserActionsAsync';
import { userActions } from './UserState/UserSlice';
import RenderUser from './RenderUser';
import PageHeader from '../../Core/PageHeader';
import AppNav from '../../Core/AppNav';
import { Fragment, useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { navigate } from '@reach/router';
import AlertMessage from '../../Core/AlertMessage';
import LoadingSpinnerGreyBackgroud from '../../Core/Spinners/LoadingSpinnerGreyBackground';
import { currentUserSelectors } from '../../CurrentUser/CurrentUserState/CurrentUserSelector';
import { currentUserActions } from '../../CurrentUser/CurrentUserState/CurrentUserSlice';
import { userSelectors } from './UserState/UserSelectors';
import { vesselActionsAsync } from '../../Vessels/VesselActionsAsync';
import { locationActionsAsync } from '../../Locations/LocationActionsAsync';
import { fleetUserActionsAsync } from '../../FleetUsers/FleetUserState/FleetUserActionsAsync';
import { programActionsAsync } from '../../Programs/ProgramActionsAsync';
import AccessDenied from '../../Core/AccessDenied';

const useStyles = makeStyles(theme => ({
	container: theme.container,
}));

export default function EditUserPage(props) {
  const dispatch = useDispatch();
	const classes = useStyles();

  const { userId } = props;

  const selectedUser = useSelector(userSelectors.userSelectorById);
  const currentUserGroup = useSelector(currentUserSelectors.userGroupSelector)

  if(!selectedUser) {
    dispatch(userActions.selectUser(userId));
  }

  const currentUserId = useSelector(currentUserSelectors.currentUserIdSelector);

  const [apiError, setApiError] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const handleCancel = () => {
    navigate('/users');
  }

  const handleSubmit = user => {
    setShowSpinner(true);
    dispatch(userActionsAsync.updateUser({ updatedUser: user })).then(response => {
      setShowSpinner(false);

      if(response.error && response.error.message.includes("409")) {
        setApiError('UserId is already in use. Try another.');
      } else if(response.error) {
        console.log(response);
        setApiError('Failed to add user.')
      } else {

        if(user.userLevel === '5') {
          dispatch(programActionsAsync.getPrograms());
        }
        setApiError(null);
        navigate('/users');
      } 
    });
  }

  const headerButtons = [
		{
			buttonText: 'Manage Fleet Users',
			buttonAction: () => {
        dispatch(currentUserActions.selectTenantAndNavigate({ 
          tenantId: selectedUser.tenantId.join(), 
          route: '/fleet-users' 
        }));
        dispatch(fleetUserActionsAsync.getFleetUsersByTenantId());
        dispatch(locationActionsAsync.getLocationsByTenantId());
        dispatch(vesselActionsAsync.getVesselsByTenantId());
      }
		}
	]  

  if(currentUserGroup === "Admin") {
    return (
      <Fragment>
        <AppNav />
        <Container maxWidth={false} className={classes.container}>
          {!!apiError && 
            <AlertMessage 
              message={apiError}
              onClose={() => setApiError(null)}
              severity="error"
            />
          } 

          <PageHeader 
            headerText="Edit User"
            headerButtons={selectedUser?.userLevel === '5' ? headerButtons : null}
          />


          <RenderUser 
            user={selectedUser}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            isExistingUser={true}
            changedByUser={currentUserId}
          />
        </Container>
        <LoadingSpinnerGreyBackgroud open={showSpinner} />
      </Fragment>
    )
  } else {
    return (
      <AccessDenied />
    )
  }
}
import { createSelector } from 'reselect';
import SelectorUtils from '../../Utilities/selectorUtils';

const fleetUsersIdSelector = createSelector(
  state => state.fleetUsers.list,
  (fleetUsers) => {
    if (!!fleetUsers) {
      return fleetUsers.map(user => user.userId);
    } else {
      return null;
    }
  }
);

const fleetUserOnlySelector = createSelector(
  state => state.fleetUsers.list,
  (fleetUsers) => {
    if (!!fleetUsers) {
      return fleetUsers.filter(user => user.userLevel === "10");
    } else {
      return null;
    }
  }
);

const orderedUsersSelector = createSelector(
  fleetUserOnlySelector,
  state => state.fleetUsers.orderBy,
  (fleetUsers, orderBy) => {
    if(!!fleetUsers && !!orderBy) {
      if(orderBy.orderByParam) {
        return orderBy.orderAsc ? SelectorUtils.sortAscending(fleetUsers, orderBy.orderByParam) : SelectorUtils.sortDescending(fleetUsers, orderBy.orderByParam)
      } else return fleetUsers;
    } else if(!!fleetUsers) {
			return fleetUsers;
		} else return null;
  }
);

const selectedUserSelector = createSelector(
  state => state.fleetUsers.list,
  state => state.fleetUsers.selectedUserId,
  (fleetUsers, selectedUserId) => {
    if(!!fleetUsers && fleetUsers.length && !!selectedUserId) {
      return fleetUsers.find(user => user.id === Number(selectedUserId));
    } else {
      return null;
    }
  }
);


export const fleetUserSelectors = {
  fleetUserOnlySelector,
  fleetUsersIdSelector,
  orderedUsersSelector,
  selectedUserSelector
}
import AppRouter from './AppRouter';
import { useDispatch } from 'react-redux';
import appStart from './Core/CoreState/CoreActions';
import { useEffect } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { ThemeProvider } from '@material-ui/core';
import theme from './Core/theme';
import { CookiesProvider, useCookies } from 'react-cookie';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { coreActions } from './Core/CoreState/CoreSlice';


function App() {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['whInfo']);
  Amplify.configure(awsconfig);

  const getUserParamsFromUrl = () => {
    let encodedStr = window.location.search ? window.location.search : cookies.whInfo;
    
    if(!!encodedStr) {
      setCookie('whInfo', encodedStr);
      
      if (encodedStr.indexOf('?user=') !== -1) {
        let encodedUser = encodedStr.split("?user=");
        let userArr = atob(encodedUser[1]).split('|');
        
        return {
          tenantIds: String(userArr[0]).split(','),
          isAdmin: userArr[1] >= 5,
          userId: userArr[2],
          group: null,
          authMode: 'API_KEY',
        }
      }
      return null;
    } else {
      return null;
    }
  }

  useEffect(() => {
    const hostName = window.location.hostname;
    let wheelhouseRoot;

    if(hostName === 'localhost') {
      wheelhouseRoot = 'http://localhost/wheelhouse-mms-maintenance/';
  } else if(hostName === 'main.windward.wheelhousetech.com') {
      wheelhouseRoot = 'https://mms.wheelhousetech.com/';
  } else if(hostName === 'beta.windward.wheelhousetech.com') {
      wheelhouseRoot = 'https://beta.wheelhousetech.com/';
  } else if(hostName === 'dev.windward.wheelhousetech.com') {
    wheelhouseRoot = 'https://dev.wheelhousetech.com/';
  } 
		dispatch(coreActions.setWheelhouseRoot(wheelhouseRoot));

  }, [dispatch])

  useEffect(() => {
    if(window.location.pathname !== '/logout') {
        Auth.currentAuthenticatedUser()
          .then(cognitoUser => {
            if(cognitoUser) {
              const params = {
                tenantIds: cognitoUser.signInUserSession.idToken.payload['custom:tenant']?.split(',') ?? [],
                userId: cognitoUser.username,
                group: cognitoUser.signInUserSession.idToken.payload['cognito:groups']?.[0] ?? "",
                authMode: null,
              }
              dispatch(appStart(params));
          }
          })  
          .catch(error =>  {
            const params = getUserParamsFromUrl();
            if(params) {
              dispatch(appStart(params));    
            }
          });
      }
  });
  
  return (
    <ThemeProvider theme={theme}>
      <CookiesProvider>
        <DndProvider backend={HTML5Backend}>
          <div className="App">
            <AppRouter />
          </div>
        </DndProvider>
      </CookiesProvider>
    </ThemeProvider>
  );
}

export default App;

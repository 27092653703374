import { createSelector } from 'reselect';
import SelectorUtils from '../../../Utilities/selectorUtils';

export const getTotalPages = (availableUsers, recordLimit) => {
  return Math.floor((availableUsers / recordLimit)) + 1;
}

const userSelectorById = createSelector(
  state => state.users.list,
  state => state.users.selectedUserId,
  (users, selectedUserId) => {
    if(!!users && users.length && selectedUserId) {
      return users.find(user => String(user.id) === String(selectedUserId));
    }
    return null;
  }
)

const orderedUsersSelector = createSelector(
  state => state.users.list,
  state => state.users.orderBy,
  (users, orderBy) => {
    if(!!users && !!orderBy) {
      if(orderBy.orderByParam) {
        return orderBy.orderAsc ? SelectorUtils.sortAscending(users, orderBy.orderByParam) : SelectorUtils.sortDescending(users, orderBy.orderByParam)
      } else return users;
    } else if(!!users) {
			return users;
		} else return null;
  }
)

const userLevelFilterSelector = createSelector(
  state => state.users.userLevelFilter,
  (filter) => {
    return filter;
  }
)

const searchTermSelector = createSelector(
  state => state.users.searchTerm,
  (searchTerm) => {
    return searchTerm;
  }
)


const programFilterSelector = createSelector(
  state => state.users.programFilter,
  (filter) => {
    return filter;
  }
)

const totalPagesSelector = createSelector(
  state => state.users.availableUsers,
  state => state.users.recordLimit,
  (availableUsers, recordLimit) => {
    if(!!availableUsers && !!recordLimit) {
      return getTotalPages(availableUsers, recordLimit)
    }
    return null;
  }
)

export const userSelectors = {
  userSelectorById,
  orderedUsersSelector,
  totalPagesSelector,
  userLevelFilterSelector,
  programFilterSelector,
  searchTermSelector,
}
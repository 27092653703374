import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogTitle, 
  DialogContent, 
  DialogContentText,
	makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	actionButton: theme.actionButton,
}));

export default function ConfirmDeleteDialog(props) {
	const classes = useStyles();
  const { open, onClose, onConfirm, item, itemType } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle>Confirm Delete {itemType}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {item}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={classes.actionButton} onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button className={classes.actionButton} onClick={onConfirm} color="primary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
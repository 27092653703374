import { v4 as uuidv4 } from 'uuid';

export default class Header {
    constructor(obj) {
        if(!obj) {
            obj = {}
        }

        this.id = obj.id || uuidv4(); 

        this.type = 'header';

        this.name = obj.name || null;

        this.position = obj.position || null;

        this.size = obj.size || 'h1';

        this.text = obj.text || 'Header';
    }
}
import CheckBox from '../FormElements/CheckBox';
import DatePicker from '../FormElements/DatePicker';
import DropDown from '../FormElements/DropDown';
import Header from '../FormElements/Header';
import MultipleChoice from '../FormElements/MultipleChoice';
import YesOrNo from "../FormElements/YesOrNo";
import Paragraph from '../FormElements/Paragraph';
import TextInput from '../FormElements/TextInput';
import VesselSelector from '../FormElements/VesselSelector';
import UserSelector from '../FormElements/UserSelector';
import UserEsignSelector from '../FormElements/UserEsignSelector/UserEsignSelector';
import { Fragment } from 'react';
import FormElementUtils from '../../Utilities/formElementUtils';

export default function FormElementProvider(props) {
    const { formElement, } = props;
    const { elementTypes } = FormElementUtils;

    const getFormElement = element => {
        switch (element.type) {
            case elementTypes.checkBox: 
                return <CheckBox {...props} />
            case elementTypes.datePicker: 
                return <DatePicker {...props} />
            case elementTypes.dropDown: 
                return <DropDown {...props} />
            case elementTypes.header:
                return <Header {...props} />
            case elementTypes.paragraph: 
                return <Paragraph {...props} />
            case elementTypes.multipleChoice: 
                return <MultipleChoice {...props} />
            case elementTypes.yesOrNo:
                return <YesOrNo {...props} />
            case elementTypes.textInput:
                return <TextInput {...props} />
            case elementTypes.vesselSelector:
                return <VesselSelector {...props} />
            case elementTypes.userSelector:
                return <UserSelector {...props} />
            case elementTypes.userEsignSelector:
                return <UserEsignSelector {...props} />
            default: 
                return null;
        }
    }

    return (
        <Fragment>
            {!!formElement && getFormElement(formElement)}
        </Fragment>
    )
}
import { createSelector } from 'reselect';

const purchaseOrderSelectorAll = createSelector(
    state => state.purchaseOrders.list,
    (purchaseOrders) => purchaseOrders,
)

const purchaseOrderSelectorById = createSelector(
    state => state.purchaseOrders.list,
    state => state.purchaseOrders.selectedPurchaseOrderId,
    (purchaseOrders, selectedPurchaseOrderId) => {
        if (!!(purchaseOrders && purchaseOrders.length) && !!selectedPurchaseOrderId) {
            return purchaseOrders.find(purchaseOrder => purchaseOrder.id === selectedPurchaseOrderId);
        }
        return null;
    }
)

export const purchaseOrderSelectors = {
    purchaseOrderSelectorAll,
    purchaseOrderSelectorById,
}
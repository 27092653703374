import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fleetUserSelectors } from './FleetUserState/FleetUserSelector';
import { fleetUserActions } from './FleetUserState/FleetUserSlice';
import { fleetUserActionsAsync } from './FleetUserState/FleetUserActionsAsync';
import { currentUserSelectors } from '../CurrentUser/CurrentUserState/CurrentUserSelector';
import { Container, makeStyles } from '@material-ui/core';
import AppNav from '../Core/AppNav';
import AlertMessage from '../Core/AlertMessage';
import PageHeader from '../Core/PageHeader';
import RenderFleetUser from './RenderFleetUser';
import LoadingSpinner from '../Core/Spinners/LoadingSpinner';
import { navigate } from '@reach/router';
import LoadingSpinnerGreyBackgroud from '../Core/Spinners/LoadingSpinnerGreyBackground';
import AccessDenied from '../Core/AccessDenied';

const useStyles = makeStyles(theme => ({
	container: theme.container,
}));


export default function EditFleetUserPage(props) {
  const dispatch = useDispatch();
	const classes = useStyles();
  const { userId } = props;

  const [apiError, setApiError] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [verifiedCognitoUser, setVerifiedCognitoUser] = useState(null);


  const selectedUser = useSelector(fleetUserSelectors.selectedUserSelector);
  const currentUserGroup = useSelector(currentUserSelectors.userGroupSelector);

  //set selectedUser 
  useEffect(() => {
    if(!selectedUser) {
      dispatch(fleetUserActions.selectUser(userId));
    }
  }, [selectedUser, dispatch, userId]);

  //verify is cognito user, call api if verified
  useEffect(() => {
    if(!verifiedCognitoUser && selectedUser) {
      const username = selectedUser.userId;
      dispatch(fleetUserActionsAsync.verifyCognitoUser({ username }))
      .unwrap()
      .then(response => {
        if(response) {
          setVerifiedCognitoUser(response);
        }
      })
      .catch(err => navigate('/fleet-users/'))
    }
  }, [verifiedCognitoUser, selectedUser, dispatch, userId]);




  const handleSubmit = (user) => {
    setShowSpinner(true);

    dispatch(fleetUserActionsAsync.updateFleetUser({ updatedUser: user })).then(response => {
      setShowSpinner(false);

      if(response.error) {
        setApiError('Failed to update user.')
      } else {
        setApiError(null);
        navigate('/fleet-users');
      }
    });
  }

  const handleChangeUserLevel = (userLevel, setFieldValue) => {
    dispatch(fleetUserActionsAsync.getFleetUserSettingsSelectionsByUserLevel({ userLevel })).then(response => {
      if(response.payload) {
        setFieldValue('fleetUserSettings', response.payload.userSettings, false);
      }
    });
  }

  const handleCancel = () => {
    dispatch(fleetUserActions.unselectUserAndNavigate({ route: '/fleet-users'}))
  }

  const handleGetFleetUserSettingsSelections = (user, formikRef) => {
    dispatch(fleetUserActionsAsync.getFleetUserSettingsSelectionsByUserId({ userId: user.id })).then(response => {
      if(response.payload) {
        formikRef.current.setFieldValue('fleetUserSettings', response.payload.userSettings, false);
      }
    })
  }

  const handleGetFleetUserLocations = (user, formikRef) => {
    dispatch(fleetUserActionsAsync.getFleetUserLocations({ userId: user.id })).then(response => {
      if(response.payload) {
        formikRef.current.setFieldValue('locations', response.payload.locations, false);
        formikRef.current.setFieldValue('vessels', response.payload.vessels, false);
      }
    })
  }

  const showRenderUser = () => {
    return (!!selectedUser && verifiedCognitoUser && currentUserGroup !== 'FleetUser');
  }

  if(showRenderUser()) {
    return (
        <Fragment>
        <AppNav />
        <Container  maxWidth={false} className={classes.container}>
          {!!apiError && 
            <AlertMessage 
              message={apiError}
              onClose={() => setApiError(null)}
              severity="error"
            />
          } 

          <PageHeader headerText="Edit User" />

          <RenderFleetUser 
            user={selectedUser}
            isExistingUser={true}
            getFleetUserSettingsSelections={handleGetFleetUserSettingsSelections}
            getFleetUserLocations={handleGetFleetUserLocations}
            onCancel={handleCancel}
            onChangeUserLevel={handleChangeUserLevel}
            onSubmit={handleSubmit}
          />
        </Container>
        <LoadingSpinnerGreyBackgroud open={showSpinner} />

      </Fragment>
    )
  } else if(currentUserGroup === 'FleetUser') {
    return (
      <AccessDenied />
    )
  } else {
    return <LoadingSpinner />
  }
}
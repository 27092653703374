import { 
  FormControl, 
  makeStyles,
  MenuItem,
  Select
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  filter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& p': {
      whiteSpace: 'nowrap',
      marginRight: 5,
    }
  }
}));

export default function UserFilter(props) {
  const classes = useStyles();

  const { choices, defaultValueText, value, onChange } = props;

  return (
    <div className={classes.filter}>
      <FormControl size="small" fullWidth>

        <Select
          name="user-filter"
          value={value}
          variant="outlined"
          displayEmpty
          onChange={(e) => onChange(e.target.value)}>
            <MenuItem value="">{defaultValueText}</MenuItem>
            { choices.map((choice) => (
              <MenuItem key={choice.userLevel || choice.id} value={choice.userLevel || choice.id}>{choice.userGroupName || choice.program}</MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  )
}
import { 
  Container,
	makeStyles, 
} from '@material-ui/core';
import { templateActionsAsync } from './TemplateState/TemplateActionsAsync';
import { templateActions } from './TemplateState/TemplateSlice';
import { templateSelectors } from './TemplateState/TemplateSelectors';
import { categoryActionsAsync } from '../CustomFormCategories/CategoryState/CategoryActionsAsync';
import { categorySelectors } from '../CustomFormCategories/CategoryState/CategorySelectors';
import { systemTemplateSelectors } from '../../SystemFormBuilder/SystemFormTemplates/SystemTemplateState/SystemTemplateSelectors';
import SystemCategoryTable from '../../../Core/SystemCategoryTable';
import TemplateTable from './TemplateTable/TemplateTable';
import { useDispatch, useSelector } from 'react-redux';
import AppNav from '../../../Core/AppNav';
import { Fragment, useState } from 'react';
import CloneTemplateDialog from '../../../Core/CloneTemplateDialog';
import ManageCustomFormCategoryDialog from '../CustomFormCategories/ManageCustomFormCategoryDialog/ManageCustomFormCategoryDialog';
import PageHeader from '../../../Core/PageHeader';
import ConfirmDeleteDialog from '../../../Core/ConfirmDeleteDialog';
import { navigate } from '@reach/router';
import FormBuilderPreviewDialog from '../FormBuilderPreviewDialog/FormBuilderPreviewDialog';

const useStyles = makeStyles(theme => ({
	container: theme.container
}));

export default function ManageCustomFormTemplatesPage(props) {
  const dispatch = useDispatch();
	const classes = useStyles();

  const availableCategories = useSelector(categorySelectors.categorySelectorAll);
  const templatesByCategory = useSelector(templateSelectors.templateSelectorPerCategory);
  const selectedTemplate = useSelector(templateSelectors.templateSelectorById);  
  const systemTemplatesPerCategory = useSelector(systemTemplateSelectors.systemTemplateSelectorPerCategory);


  const [cloneTemplateDialogOpen, setCloneTemplateDialogOpen] = useState(false);
  const [manageCategoryDialogOpen, setManageCategoryDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);

  const handleCloneClick = (template) => {
    dispatch(templateActions.selectTemplate(template.id));
    setCloneTemplateDialogOpen(true);
  }

  const handleClone = (values) => {
    const params = {
      originalTemplate: selectedTemplate,
      templateName: values.name,
      categoryId: values.categoryId,
      categoryName: values.categoryName,
    }

    dispatch(templateActionsAsync.cloneTemplate(params)).then(response => {
      const clonedTemplate = response.payload;

      const params = {
        templateId: clonedTemplate.id,
        route: '/form-templates/' + clonedTemplate.id,
      }

      dispatch(templateActions.selectTemplateAndNavigate(params));
    });
  }

  const handleCloneDialogClose = () => {
    setCloneTemplateDialogOpen(false);
    dispatch(templateActions.unselectTemplate());
  }

	const handleDeleteDialogClose = () => {
		setDeleteDialogOpen(false);
    dispatch(templateActions.unselectTemplate());
	}

  const handleDeletTemplateConfirm = () => {
    dispatch(templateActionsAsync.deleteTemplate({ template: selectedTemplate })).then(() => {
			dispatch(templateActions.unselectTemplate());
		});
		setDeleteDialogOpen(false);
  }

	const handleDeleteTemplateClick = template => {
    dispatch(templateActions.selectTemplate(template.id));
    setDeleteDialogOpen(true);
	}

  const handleSelectTemplate = (template) => {
    const params = {
      templateId: template.id,
      route: '/form-templates/' + template.id,
    }
    dispatch(templateActions.selectTemplateAndNavigate(params));
  }

  const handleDeleteCategory = category => {
    dispatch(categoryActionsAsync.deleteCategory({ category: category }));
  }

  const handleNewCategorySubmit = category => {
    dispatch(categoryActionsAsync.createCategory({ newCategory: category }));
  }

  const handlePreviewClick = (template) => {
    dispatch(templateActions.selectTemplate(template.id));
    setPreviewDialogOpen(true);
  }

  const handlePreviewClose = () => {
    setPreviewDialogOpen(false);
    dispatch(templateActions.unselectTemplate());
  }

	const headerButtons = [
		{
			buttonText: 'Create New Template',
			buttonAction: () => navigate("/form-templates/new"),
		},
		{
			buttonText: 'Manage Categories',
			buttonAction: () => setManageCategoryDialogOpen(true),
		}
	]

  return (
    <Fragment>
      <AppNav />
      <Container maxWidth={false} className={classes.container}>

        <PageHeader 
          headerText="Form Templates"
          headerButtons={headerButtons}
        />

        <TemplateTable
          categories={templatesByCategory}
          onClone={handleCloneClick}
          onDelete={handleDeleteTemplateClick}
          onSelect={handleSelectTemplate}
          onPreview={handlePreviewClick}
        />

        <SystemCategoryTable
          categories={systemTemplatesPerCategory}
          onClone={handleCloneClick}
          onPreview={handlePreviewClick}
        />

      </Container>

      <CloneTemplateDialog 
        availableCategories={availableCategories}
        createCategoryAction={categoryActionsAsync.createCategory}
        categoryType="Custom"
        open={cloneTemplateDialogOpen}
        onClose={handleCloneDialogClose}
        onSubmit={handleClone}
        originalTemplate={selectedTemplate}
      />

			<ConfirmDeleteDialog 
				open={deleteDialogOpen}
				item={selectedTemplate?.name}
				itemType={'Template'}
				onClose={handleDeleteDialogClose}
				onConfirm={handleDeletTemplateConfirm}
			/>

      <ManageCustomFormCategoryDialog
        open={manageCategoryDialogOpen}
        categories={availableCategories}
        onDelete={handleDeleteCategory}
        onSubmit={handleNewCategorySubmit}
        onClose={() => setManageCategoryDialogOpen(false)}
      />

      <FormBuilderPreviewDialog
        open={previewDialogOpen}
        template={selectedTemplate}
        onClose={handlePreviewClose}
      />
    </Fragment>
  )
}
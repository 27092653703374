/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://3kcuzk3zj5c6hkmafdo54ulz2q.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-tf4jwpu54zgnjaszlr6heutnkq",
    "aws_cloud_logic_custom": [
        {
            "name": "WindwardRESTApi",
            "endpoint": "https://bmthnmrj59.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:e5ca8019-5e69-4905-b154-77c859963b2f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_RJyw1bu4l",
    "aws_user_pools_web_client_id": "52797rrcqbjt6vganm7gto9e48",
    "oauth": {
        "domain": "windward-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://main.windward.wheelhousetech.com/sso_login_processing/",
        "redirectSignOut": "https://main.windward.wheelhousetech.com/logout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;

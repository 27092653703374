import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppNav from '../Core/AppNav';
import UsersTable from './UsersTable/UsersTable';
import { Container, makeStyles } from '@material-ui/core';
import PageHeader from '../Core/PageHeader';
import { fleetUserSelectors } from './FleetUserState/FleetUserSelector';
import { fleetUserActions } from './FleetUserState/FleetUserSlice';
import { fleetUserActionsAsync } from './FleetUserState/FleetUserActionsAsync';
import { currentUserSelectors } from '../CurrentUser/CurrentUserState/CurrentUserSelector';
import { userSelectors } from '../Admin/Users/UserState/UserSelectors';
import ConfirmDeleteDialog from '../Core/ConfirmDeleteDialog';
import { navigate } from '@reach/router';
import AccessDenied from '../Core/AccessDenied';

const useStyles = makeStyles(theme => ({
	container: theme.container
}));

export default function ManageFleetUsersPage(props) {
	const classes = useStyles();
  const dispatch = useDispatch();

  const [orderAsc, setOrderAsc] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const users = useSelector(fleetUserSelectors.orderedUsersSelector);
	const loadingUsers = useSelector(state => state.fleetUsers.isLoading);
  const selectedUser = useSelector(fleetUserSelectors.selectedUserSelector);
  const currentUserGroup = useSelector(currentUserSelectors.userGroupSelector);
  const selectedAdmin = useSelector(userSelectors.userSelectorById)


  const setOrderByParams = (orderByParam) => {
    setOrderAsc(!orderAsc); 
    dispatch(fleetUserActions.setOrderBy({ orderByParam, orderAsc }));
  }

  const handleSelectUser = user => {
    const params = { 
      userId: user.id, 
      route: '/fleet-users/' + user.id 
    }

    dispatch(fleetUserActions.selectUserAndNavigate(params));
  }

  const handleStatusChange = user => {
    dispatch(fleetUserActionsAsync.toggleFleetUserStatus({ user }));
  }

  const handleDeleteUserConfirm = () => {
    dispatch(fleetUserActionsAsync.deleteFleetUser({ user: selectedUser })).then(() => {
      dispatch(fleetUserActions.unselectUser());
    });
    setDeleteDialogOpen(false);
  }

  const handleDeleteUserCancel = () => {
    dispatch(fleetUserActions.unselectUser());
    setDeleteDialogOpen(false);
  }

  const handleDeleteUserClick = (user) => {
    dispatch(fleetUserActions.selectUser(user.id));
    setDeleteDialogOpen(true);
  }

	const headerButtons = [
		{
			buttonText: 'Add User',
			buttonAction: () => navigate(selectedAdmin ? `/fleet-users/new/${selectedAdmin.id}` : '/fleet-users/new'),
		}
	]

  if(currentUserGroup !== 'FleetUser') {
    return (
      <Fragment>
        <AppNav />
        <Container  maxWidth={false}  className={classes.container}>
          <PageHeader 
            headerText="User Administration"
  					headerButtons={headerButtons}
          />


          <UsersTable 
            users={users} 
  					loadingUsers={loadingUsers}
            onSetOrderByParams={setOrderByParams}
            onSelect={handleSelectUser}
            onStatusChange={handleStatusChange}
            onDelete={handleDeleteUserClick}
            />

        </Container>

        <ConfirmDeleteDialog
          open={deleteDialogOpen}
          item={selectedUser?.name}
          itemType={'User'}
          onClose={handleDeleteUserCancel}
          onConfirm={handleDeleteUserConfirm}
         />

      </Fragment>
    )
  } else {
    return (
      <AccessDenied />
    )
  }
}

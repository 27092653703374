import { 
    FormControl, 
    FormLabel,
    MenuItem, 
    Select 
} from '@material-ui/core';
import PaymentTerms from '../Models/paymentTerms';

export default function PaymentTermsSelector(props) {
    const { value, name, fullWidth, onChange, onBlur } = props;

    const paymentTerms = [
        {value: PaymentTerms.PIA, name: 'PIA'},
        {value: PaymentTerms.NET7, name: 'Net 7'},
        {value: PaymentTerms.NET10, name: 'Net 10'},
        {value: PaymentTerms.NET30, name: 'Net 30'},
        {value: PaymentTerms.NET60, name: 'Net 60'},
        {value: PaymentTerms.NET90, name: 'Net 90'},
        {value: PaymentTerms.EOM, name: 'EOM'},
        {value: PaymentTerms.TWENTYONE_MFI, name: '21 MFI'},
        {value: PaymentTerms.ONE_PCT_TEN_NET30, name: '1 % 10 Net 30'},
        {value: PaymentTerms.COD, name: 'COD'},
        {value: PaymentTerms.CASH, name: 'Cash'},
        {value: PaymentTerms.LETTER_OF_CREDIT, name: 'Letter of Credit'},
        {value: PaymentTerms.BILL_OF_EXCHANGE, name: 'Bill of Exchange'},
        {value: PaymentTerms.CND, name: 'CND'},
        {value: PaymentTerms.CBS, name: 'CBS'},
        {value: PaymentTerms.CIA, name: 'CIA'},
        {value: PaymentTerms.CWO, name: 'CWO'},
        {value: PaymentTerms.ONE_MD, name: '1MD'},
        {value: PaymentTerms.TWO_MD, name: '2MD'},
        {value: PaymentTerms.CONTRA, name: 'Contra'},
        {value: PaymentTerms.STAGE_PAYMENT, name: 'Stage Payment'},
    ];

    return (
        <FormControl fullWidth>

            <FormLabel>Payment Terms</FormLabel>

            <Select
                name={name}
                value={value || ''}
                fullWidth={fullWidth}
                onChange={onChange}
                onBlur={onBlur}>
                { paymentTerms.map((paymentTerm) => (
                    <MenuItem key={paymentTerm.value} value={paymentTerm.value}>{paymentTerm.name}</MenuItem>
                ))}
            </Select>

      </FormControl>
    )
}
import { Fragment } from "react"
import { Grid, TextField, Typography  } from '@material-ui/core';
import YesNoSelector from "../YesNoSelector/YesNoSelector";

export default function FleetSettings(props) {
  const { values, onChange, onBlur } = props;

  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography variant="h3">
          Fleet Settings
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <YesNoSelector
          name="admin"
          label="Admin"
          customOptions={{
            trueOption: 'Add/Edit/Delete',
            falseOption: 'View Only'
          }}
          value={values.admin} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>

      <Grid item xs={2}>
        <YesNoSelector
          name="dealer"
          label="Dealer"
          value={values.dealer} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>

      <Grid item xs={2}>
        <YesNoSelector
          name="spo"
          label="SPO capable"
          value={values.spo} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>


    <Grid item xs={2}>
        <YesNoSelector
          name="fleet"
          label="Fleet"
          value={values.fleet} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>

      <Grid item xs={2}>
        <YesNoSelector
          name="inventory"
          label="Inventory"
          value={values.inventory} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>

      <Grid item xs={2}>
        <YesNoSelector
          name="management"
          label="Work Management"
          value={values.management} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>

      <Grid item xs={2}>
        <YesNoSelector
          name="settings"
          label="Settings"
          value={values.settings} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>

      <Grid item xs={2}>
        <YesNoSelector
          name="equipmentTemplates"
          label="Equipment Templates"
          value={values.equipmentTemplates} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>

      <Grid item xs={2}>
        <YesNoSelector
          name="partsList"
          label="Parts List"
          value={values.partsList} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>

      <Grid item xs={2}>
        <YesNoSelector
          name="manufacturers"
          label="Manufacturers"
          value={values.manufacturers} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>


      <Grid item xs={2}>
        <YesNoSelector
          name="suppliers"
          label="Suppliers"
          value={values.suppliers} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>

      <Grid item xs={2}>
        <YesNoSelector
          name="vesselTemplates"
          label="Vessel Templates"
          value={values.vesselTemplates} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>

      <Grid item xs={2}>
        <YesNoSelector
          name="openItems"
          label="Open Items"
          value={values.openItems} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>

      <Grid item xs={2}>
        <YesNoSelector
          name="fleetNews"
          label="Fleet News"
          value={values.fleetNews} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>

      <Grid item xs={2}>
        <YesNoSelector
          name="fleetInventory"
          label="Fleet Inventory"
          value={values.fleetInventory} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>

      <Grid item xs={2}>
        <YesNoSelector
          name="vesselSpares"
          label="Vessel Spares"
          value={values.vesselSpares} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>

      <Grid item xs={2}>
        <YesNoSelector
          name="fleetManagement"
          label="Fleet Management"
          value={values.fleetManagement} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>

      <Grid item xs={2}>
        <YesNoSelector
          name="fleetUsers"
          label="Fleet Users"
          value={values.fleetUsers} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>

      <Grid item xs={2}>
        <YesNoSelector
          name="homepage"
          label="Homepage Edit"
          value={values.homepage} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>

      <Grid item xs={2}>
        <YesNoSelector
          name="deleteHistoryLog"
          label="Delete History/Log"
          value={values.deleteHistoryLog} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>

      <Grid item xs={2}>
        <YesNoSelector
          name="taskReasonRequired"
          label="Task Edit Reason Required"
          value={values.taskReasonRequired} 
          onChange={onChange} 
          onBlur={onBlur} 
        />
      </Grid>

      { !!values.spo && 
        <Fragment>
          <Grid item xs={6}>
            <TextField 
              name="spoEmail" 
              label="SPO Contact Emails" 
              helperText="Multiple e-mail addresses should be separated with a comma."
              value={values.spoEmail || ''} 
              onChange={onChange} 
              onBlur={onBlur}
              fullWidth />
          </Grid>

          <Grid item xs={6}>
            <TextField 
              name="spoName" 
              label="SPO Name" 
              helperText="If left blank, SPO name will be set to the program name."
              value={values.spoName || ''} 
              onChange={onChange} 
              onBlur={onBlur}
              fullWidth />
          </Grid>
        </Fragment>
      }

    </Fragment>
  )
}
import { v4 as uuidv4 } from 'uuid';

export default class SystemFormCategory {

    constructor(obj) {
        if (!obj) {
            obj = {};
        }

        this.id = obj.id || uuidv4();

        this.name = obj.name || '';

    }
}
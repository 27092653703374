import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    paragraph: {
        'white-space': 'pre-wrap',
    },
}));

export default function Paragraph(props) {
    const classes = useStyles();
    const { formElement } = props;

    return (
        <Typography component="p" className={classes.paragraph}>{formElement.text}</Typography>
    )
}
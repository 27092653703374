import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    Grid,
    makeStyles,
    TextField,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import ChoiceSelector from './ChoiceSelector/ChoiceSelector';
import FormElementDetailCheckboxes from './FormElementDetailCheckboxes/FormElementDetailCheckboxes';
import FormElementUtils from '../../../Utilities/formElementUtils';

const useStyles = makeStyles(theme => ({
    dialogCheckboxes: {
        marginTop: 10,
    }
}));

export default function FormElementDetailDialog(props) {
    const classes = useStyles();
    const { 
        formElement, 
        open, 
        onClose, 
        onSubmit,
    } = props;

    const formElementSchema = yup.object().shape({
        labelText: yup.string(),
        required: yup.boolean(),
        size: yup.string(),
        multiLine: yup.boolean(),
        numbersOnly: yup.boolean(),
        choices: yup.array().of(yup.string()),
    });

    if(!!formElement) {

        const { 
            labelText, 
            choices, 
            text,
            type,
        } = formElement;

        return (
            <Dialog
            open={open}
            onClose={onClose}
            scroll='paper'
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="Form Element Detail" 
            aria-describedby="Update form element details">
                <DialogTitle>Edit</DialogTitle>
                <DialogContent>
                <Formik
                    initialValues={formElement}
                    validationSchema={formElementSchema}
                    onSubmit={ async (values) => {
                        onSubmit(values);
                        onClose();
                    }}
                >{({
                    values,
                    errors,
                    touched,
                    handleChange, 
                    handleBlur,
                    handleSubmit
                }) => {
                    return (
                        <Form id="form-element-detail-form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <Grid container>

                            <Grid item xs={6}>
                                { !!labelText && 
                                    <TextField 
                                        name="labelText" 
                                        label="Label Text" 
                                        autoFocus={true}
                                        error={Boolean(errors.labelText && touched.labelText)}
                                        helperText={errors.labelText}
                                        value={values.labelText} 
                                        onChange={handleChange} 
                                        onBlur={handleBlur}
                                        fullWidth />
                                }
                            </Grid>

                            <Grid item xs={12}>
                                { !!text && 
                                    <TextField 
                                        name="text" 
                                        label="Text" 
                                        error={Boolean(errors.text && touched.text)}
                                        helperText={errors.text}
                                        value={values.text} 
                                        onChange={handleChange} 
                                        onBlur={handleBlur}
                                        multiline={ type === 'paragraph' ? true : false}
                                        fullWidth />
                                }
                            </Grid>

                            <Grid className={classes.dialogCheckboxes} item xs={12}>
                                <FormElementDetailCheckboxes 
                                    formElement={formElement}
                                    onChange={handleChange}
                                    values={values} />
                            </Grid>

                            <Grid item xs={12}>
                                { type !== FormElementUtils.elementTypes.vesselSelector && type !== FormElementUtils.elementTypes.userSelector
                                && type !== FormElementUtils.elementTypes.yesOrNo && type !== FormElementUtils.elementTypes.userEsignSelector 
                                && !!choices && 
                                    <ChoiceSelector 
                                    choices={values.choices}
                                    onChange={handleChange}
                                    onBlur={handleBlur}/>
                                }
                            </Grid>
                            
                        </Grid>        
                    </Form>
                    )}}
                </Formik>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button 
                        type="submit" form="form-element-detail-form">Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    } else {
        return null;
    }

}
import { createSlice } from '@reduxjs/toolkit';
import { currentUserActionsAsync } from './CurrentUserActionsAsync';
import { navigate } from '@reach/router';
const { 
  getCurrentUserSettings,
  checkIfUserHasPIN,
  setPIN
} = currentUserActionsAsync;


export const CurrentUserSlice = createSlice({
  name: 'currentUser',
  initialState: {
    userId: null,
    tenantIds: [],
    selectedTenantId: null,
    group: null,
    authMode: null,
    fleetSettings: null,
    hasPIN: null,
    loginError: null,
  },
  reducers: {
    setUser(state, action) {
      const { 
        authMode,
        group,
        tenantIds, 
        userId, 
      } = action.payload;

      if(tenantIds?.length === 1) {
        state.selectedTenantId = tenantIds[0];
      }

      state.userId = userId;
      state.tenantIds = tenantIds;
      state.group = group;
      state.authMode = authMode;
    },
    selectTenantAndNavigate(state, action) {
      if(!!action.payload) {
        navigate(action.payload.route);
        state.selectedTenantId = action.payload.tenantId;
      }
    },
    unselectTenantAndNavigate(state, action) {
      if(!!action.payload) {
        navigate(action.payload.route);
        state.selectedTenantId = null;
      }
    },
    setLoginErrorAndNavigate(state, action) {
      if(!!action.payload) {
        navigate(action.payload.route);
        state.loginError = action.payload.error
      }
    },
    setLoginError(state, action) {
      state.loginError = action.payload;
    },
    clearLoginError(state, action) {
      state.loginError = null;
    }
  },
  extraReducers: {
    [getCurrentUserSettings.fulfilled]: (state, action) => {
      state.fleetSettings = action.payload;
    },
    [checkIfUserHasPIN.fulfilled]: (state, action) => {
      state.hasPIN = action.payload.PINExists;
    },
    [setPIN.fulfilled]: (state, action) => {
      state.hasPIN = action.payload.newPINSet;
    },
  }
});

export const currentUserActions = {
  setUser: CurrentUserSlice.actions.setUser,
  selectTenantAndNavigate: CurrentUserSlice.actions.selectTenantAndNavigate,
  unselectTenantAndNavigate: CurrentUserSlice.actions.unselectTenantAndNavigate,
  setLoginErrorAndNavigate: CurrentUserSlice.actions.setLoginErrorAndNavigate,
  setLoginError: CurrentUserSlice.actions.setLoginError,
  clearLoginError: CurrentUserSlice.actions.clearLoginError,
}

export default CurrentUserSlice.reducer;

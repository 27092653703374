import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import WheelhouseLogo from '../Assets/Logos/logo-dark.svg';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import * as yup from 'yup';
import { currentUserActionsAsync } from '../CurrentUser/CurrentUserState/CurrentUserActionsAsync';
import LoadingSpinnerGreyBackground from './Spinners/LoadingSpinnerGreyBackground';
import { useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { Auth } from 'aws-amplify';
import TermsAndConditions from './TermsAndConditions';
import ContactSupportText from './ContactSupportText';

const useStyles = makeStyles(theme => ({
  alert: theme.alert,
  error: theme.error,
  loginButton: theme.noNavButton,
  loginInput: theme.noNavInput,
  loginlabel: theme.noNavLabel,
  loginRow: theme.noNavRow,
  loginButtonRow: theme.loginButtonRow,
  loginTile: theme.noNavTile,
  loginTileLogoContainer: theme.loginTileLogoContainer,
  loginTileLogo: theme.loginTileLogo,
  pageBackground: theme.noNavBackground,
}));

export default function SSOLoginPage(props) {
  const classes = useStyles();
  const { provider } = props;

  const [signInError, setSignInError] = useState(null);
  const [loading, setLoading] = useState(null);
  const wheelhouseRoot = useSelector(state => state.core.wheelhouseRoot);

  const newCredentials = {
    emailAddress: '',
    formError: ''
  }

  const performSignIn = (provider) => {
    // check provider that was looked up
    if (!!provider) {
      Auth.federatedSignIn({ provider: provider });
    } else {
      setSignInError('Your organization is not setup for SSO.');
    }
  }

  useEffect(() => {
    if (provider) {
      performSignIn(provider.toLowerCase());
    }
  }, [provider]);

  const handleSignIn = (values) => {
    // todo: call api to determine what provider to use based on email address

    // strip domain name out of email address
    let emailAddressParts = values.emailAddress.split('@');
    if (emailAddressParts.length > 0) {
      let domain = emailAddressParts[1];
      let provider;

      // todo: this is temporary
      if (domain === 'cityexperiences.com' || domain === 'hornblower.com' || domain === 'madelabs.io') {
        provider = 'Okta';
      }

      performSignIn(provider);
    } else {
      setSignInError('Please enter an email address in the following format: me@company.com');
    }
 
    // stop loading spinner
    setLoading(false);
  }

  const handleErrorClose = () => {
    setSignInError(null);
  }

  const loginSchema = yup.object().shape({
    emailAddress: yup.string()
      .required('Email Address Required'),
  });

  useEffect(() => {
    if (wheelhouseRoot){
      try {
        Auth.currentAuthenticatedUser().then((cognitoUser) => {
          if (cognitoUser) {
            currentUserActionsAsync.redirectToWheelhouse(wheelhouseRoot);
          }
        })
      } catch (err) {
        console.log(err);
      }
    }
  }, [wheelhouseRoot]);

  return (
    <div className={classes.pageBackground}>
      { !!signInError && 
        <Alert 
        className={classes.alert}
        onClose={handleErrorClose} 
        severity="error">
          An error occurred while determining your organization's SSO account.  Please contact support to setup.
        </Alert>
      }

      <Formik
        initialValues={newCredentials}
        validationSchema={loginSchema}
        onSubmit={(values) => {
          handleErrorClose();
          setLoading(true);
          handleSignIn(values);
        }}
    >{({
      isValid, 
      dirty,
      handleSubmit,
    }) => {
      return (
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <div className={classes.loginTile}>
            <div className={classes.loginTileLogoContainer}>
              <img src={WheelhouseLogo} alt="Vessel Vanguard Pro Logo" className={classes.loginTileLogo} />
            </div>

            <div className={classes.loginlabel}>
              <span>Email Address</span>
            </div>
            <div className={classes.loginRow}>
              <Field id="emailAddress" name="emailAddress" className={classes.loginInput} />
              <ErrorMessage className={classes.error} component="p" name="emailAddress" />
            </div>

            <div className={classes.loginButtonRow}>
              <button disabled={!isValid || !dirty} type="submit" className={classes.loginButton}>Continue</button>
            </div>

            <ContactSupportText></ContactSupportText>
          </div>
        </Form>)}}
      </Formik>

      <TermsAndConditions></TermsAndConditions>

      { loading && 
         <LoadingSpinnerGreyBackground open={true} />
      }
    </div>
  ) 
}

import User from '../../Models/user';
import { useDispatch, useSelector } from 'react-redux';
import { userActionsAsync } from './UserState/UserActionsAsync';
import RenderUser from './RenderUser';
import PageHeader from '../../Core/PageHeader';
import AppNav from '../../Core/AppNav';
import { Fragment, useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { navigate } from '@reach/router';
import AlertMessage from '../../Core/AlertMessage';
import LoadingSpinnerGreyBackgroud from '../../Core/Spinners/LoadingSpinnerGreyBackground';
import { currentUserSelectors } from '../../CurrentUser/CurrentUserState/CurrentUserSelector';
import { programActionsAsync } from '../../Programs/ProgramActionsAsync';
import AccessDenied from '../../Core/AccessDenied';

const useStyles = makeStyles(theme => ({
	container: theme.container,
}));

export default function NewUserPage(props) {
  const dispatch = useDispatch();
	const classes = useStyles();

  const currentUserId = useSelector(currentUserSelectors.currentUserIdSelector);
  const currentUserGroup = useSelector(currentUserSelectors.userGroupSelector);
  const [apiError, setApiError] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const newUser = new User({ userChangeId: currentUserId });

  const handleCancel = () => {
    navigate('/users');
  }

  const handleSubmit = user => {
    setShowSpinner(true);
    dispatch(userActionsAsync.createUser({ newUser: user })).then(response => {
      setShowSpinner(false);

      if(response.error && response.error.message.includes("409")) {
        setApiError('UserId is already in use. Try another.');
      } else if(response.error) {
        setApiError('Failed to add user.')
      } else {

        if(user.userLevel === '5') {
          dispatch(programActionsAsync.getPrograms());
        }

        setApiError(null);
        navigate('/users');
      }
    });
  }

  if(currentUserGroup === "Admin") {
    return ( 
      <Fragment>
        <AppNav />
        <Container  maxWidth={false} className={classes.container}>
          {!!apiError && 
            <AlertMessage 
              message={apiError}
              onClose={() => setApiError(null)}
              severity="error"
            />
          } 

          <PageHeader headerText="Add User" />

          <RenderUser 
            user={newUser}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
          />
          
        </Container>
        <LoadingSpinnerGreyBackgroud open={showSpinner} />
      </Fragment>
    )
  } else {
    return (
      <AccessDenied />
    )
  }
}